import React, { Component } from "react";

class SuccessNotif extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div id="success-notification-content" className="toastify-content hidden flex">
        <i className="text-theme-9" data-feather="check"></i>
        <div className="ml-4 mr-4">
          <div className="font-medium">Success!</div>
          <div className="text-gray-600 mt-1"> {this.props.pesan ? this.props.pesan : "Data Berhasil Dihapus"} </div>
        </div>
      </div>
    );
  }
}

export default SuccessNotif;
