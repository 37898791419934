import React, { Component } from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";

class Lastfile extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="col-span-12 md:col-span-6 xl:col-span-8 2xl:col-span-12 mt-3">
        <div className="intro-x flex items-center h-10">
          <h2 className="text-lg font-medium truncate mr-5">File Terbaru</h2>
          <a href="" className="ml-auto text-theme-1 truncate">
            See all
          </a>
        </div>
        <div className="report-timeline mt-5 relative">
          {this.props.lastfile.map((e, index) => {
            return (
              <div className="intro-x relative flex items-center mb-3" key={index}>
                <div className="report-timeline__image">
                  <div className="w-10 h-10 flex-none image-fit rounded-full overflow-hidden">
                    <img
                      alt={e.user_nama}
                      src={`${process.env.REACT_APP_APIURL}uploads/${Cookies.get("level") == "4" ? (e.user_jk == "L" ? "laki.png" : "female2.png") : e.user_image}`}
                    />
                  </div>
                </div>
                <div className="box px-5 py-3 ml-4 flex-1 zoom-in">
                  <Link to={"/paket/detail/" + e.dl_paket}>
                    <div className="flex items-center">
                      <div className="font-medium">{Cookies.get("level") == "4" ? (e.dl_panitia == "5" ? "Pokja" : "UKPBJ") : e.dl_user}</div>
                      <div className="text-xs text-gray-500 ml-auto">{moment(e.dl_created).fromNow()}</div>
                    </div>
                    <div className="text-gray-600">
                      <div className="mt-1">Menambahkan file baru pada </div>
                      <div className="flex mt-2">{e.paket_nama.substring(0, 30)}...</div>
                    </div>
                  </Link>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

export default Lastfile;
