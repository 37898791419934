import React, { Component } from "react";
import Cookies from "js-cookie";
import axios from "axios";
import { Link, withRouter } from "react-router-dom";
import Pristine from "pristinejs";
import cash from "cash-dom";

class Edit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      posisi: "",
      urutan: "",
      nama: "",
      aktif: "",
      proses: false,
    };
  }

  onChange(e) {
    const name = e.target.name;
    let value = e.target.value;

    if (e.target.name == "aktif") {
      value = value == "1" ? "0" : "1";
    }

    this.setState({
      [name]: value,
    });
  }

  componentDidMount() {
    let url = process.env.REACT_APP_APIURL + "dasar/detail/" + this.props.match.params.id;

    axios
      .get(`${url}`, {
        headers: { Authorization: "Bearer " + Cookies.get("auth-token") },
      })
      .then((response) => {
        this.setState({
          id: response.data.dasar.dasar_id,
          posisi: response.data.dasar.dasar_posisi,
          nama: response.data.dasar.dasar_nama,
          urutan: response.data.dasar.dasar_urutan,
          aktif: response.data.dasar.dasar_aktif,
        });
      });
  }

  onSubmit() {
    let pris;
    cash(".validate-form").each(function () {
      pris = new Pristine(this, {
        classTo: "input-form",
        errorClass: "has-error",
        errorTextParent: "input-form",
        errorTextClass: "text-primary-3 mt-2",
      });
    });
    if (pris.validate()) {
      this.setState({ proses: true });
      let formData = {
        id: this.state.id,
        posisi: this.state.posisi,
        urutan: this.state.urutan,
        nama: this.state.nama,
        aktif: this.state.aktif,
      };
      let url = process.env.REACT_APP_APIURL + "dasar/update";

      axios
        .put(`${url}`, formData, {
          headers: { Authorization: "Bearer " + Cookies.get("auth-token") },
        })
        .then((response) => {
          this.setState({ proses: false });
          this.props.history.push({
            pathname: "/setting/dasar",
            changedRows: response.data.dasar.changedRows,
          });
        });
    }
  }

  render() {
    return (
      <div>
        <div className="intro-y flex flex-col sm:flex-row items-center mt-8">
          <h2 className="text-lg font-medium mr-auto">Edit Dasar</h2>
        </div>
        <div className="grid grid-cols-12 gap-6 mt-5">
          <div className="intro-y col-span-12 lg:col-span-6">
            <form method="post" name="dasar" className="validate-form">
              <div className="intro-y box">
                <div className="p-5">
                  <div className="">
                    <div className="input-form">
                      <label>Dasar Aturan</label>
                      <input
                        type="text"
                        className="form-control mt-2"
                        placeholder="Dasar Aturan"
                        name="nama"
                        value={this.state.nama}
                        onChange={(e) => this.onChange(e)}
                        required
                      />
                    </div>
                  </div>
                  <div className="mt-3">
                    <div className="sm:grid grid-cols-3 gap-2">
                      <div className="input-form">
                        <label>Posisi</label>
                        <input
                          type="text"
                          className="form-control mt-2"
                          placeholder="Posisi Aturan"
                          name="posisi"
                          value={this.state.posisi}
                          onChange={(e) => this.onChange(e)}
                          required
                        />
                      </div>
                      <div className="input-form">
                        <label>Urutan</label>
                        <input
                          type="text"
                          className="form-control mt-2"
                          placeholder="Dasar urutan"
                          name="urutan"
                          value={this.state.urutan}
                          onChange={(e) => this.onChange(e)}
                          required
                        />
                      </div>
                      <div className="mt-2 input-form text-center">
                        <label>Aktif</label>
                        <div className="mt-2">
                          <input
                            type="checkbox"
                            name="aktif"
                            onChange={(e) => this.onChange(e)}
                            value={this.state.aktif}
                            checked={this.state.aktif == "1" ? true : false}
                            className="form-check-input"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="text-right mt-5">
                    <Link to="/setting/dasar" className="btn w-24 btn-secondary mr-1">
                      Cancel
                    </Link>
                    <button
                      type="button"
                      className={`btn w-24 ${!this.state.proses ? "btn-primary" : "btn-secondary"}`}
                      onClick={() => this.onSubmit()}
                      disabled={this.state.proses}
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Edit);
