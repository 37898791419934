import React, { Component } from "react";
import Cookies from "js-cookie";
import { pembulatan, status } from "../../Definisi";
import moment from "moment";
import feather from "feather-icons";
import { Link, withRouter } from "react-router-dom";

class Datapaket extends Component {
  constructor(props) {
    super(props);
    this.state = { paket: [], page: 1, limit: Cookies.get("perpage") };
  }

  componentDidMount() {
    this.tableRender(1, Cookies.get("perpage"));
    feather.replace({
      "stroke-width": 1.5,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.paket !== this.props.paket) {
      this.tableRender(1, Cookies.get("perpage"));
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return true;
  }

  paging() {
    let halaman = [],
      showPage = 5;
    let totalPage = Math.ceil(this.props.paket.length / Cookies.get("perpage"));
    let endPage = this.state.page % showPage === 0 ? this.state.page + Math.floor(showPage / 2) : Math.ceil(this.state.page / showPage) * showPage;
    let startPage = this.state.page % showPage === 0 ? this.state.page - Math.floor(showPage / 2) : endPage - showPage + 1;

    halaman.push(
      <li key="first">
        <button className="pagination__link" onClick={() => this.tableRender(1, Cookies.get("perpage") * 1)}>
          &lt;&lt;
        </button>
      </li>
    );

    if (this.state.page != 1) {
      halaman.push(
        <li key="prev">
          <button className="pagination__link" onClick={() => this.tableRender(this.state.page - 1, Cookies.get("perpage") * (this.state.page - 1))}>
            &lt;
          </button>
        </li>
      );
    }

    // if (this.state.page > 4) {
    //   halaman.push(
    //     <li key="prevpage">
    //       <button className="pagination__link">...</button>
    //     </li>
    //   );
    // }

    for (let i = startPage; i <= endPage; i++) {
      if (i > totalPage) break;

      halaman.push(
        <li key={i}>
          <button className={`pagination__link ${this.state.page === i ? "pagination__link--active" : ""}`} onClick={() => this.tableRender(i, Cookies.get("perpage") * i)}>
            {i}
          </button>
        </li>
      );
    }

    // if (this.state.page < totalPage - 4) {
    //   halaman.push(
    //     <li key="nextpage">
    //       <button className="pagination__link">...</button>
    //     </li>
    //   );
    // }
    if (endPage < totalPage) {
      halaman.push(
        <li key="nextpage">
          <button className="pagination__link">...</button>
        </li>
      );
      halaman.push(
        <li key="akhir">
          <button
            className={`pagination__link ${this.state.page === totalPage ? "pagination__link--active" : ""}`}
            onClick={() => this.tableRender(totalPage, Cookies.get("perpage") * totalPage)}
          >
            {totalPage}
          </button>
        </li>
      );
    }

    if (this.state.page != totalPage) {
      halaman.push(
        <li key="next">
          <button className="pagination__link" onClick={() => this.tableRender(this.state.page + 1, Cookies.get("perpage") * (this.state.page + 1))}>
            &gt;
          </button>
        </li>
      );
    }
    halaman.push(
      <li key="last">
        <button className="pagination__link" onClick={() => this.tableRender(totalPage, Cookies.get("perpage") * totalPage)}>
          &gt;&gt;
        </button>
      </li>
    );

    halaman.push(
      <li key="hal">
        <div className="pagination__link">
          Halaman {this.state.page} dari {totalPage}
        </div>
      </li>
    );
    return halaman;
  }

  tableRender(hal, limit) {
    // console.log(hal, limit);
    this.setState({
      page: hal,
      limit: limit,
    });
  }

  onChange(e) {
    const name = e.target.name;
    const value = e.target.value;

    if (e.target.type === "select-one") {
      Cookies.set("perpage", value);
      this.tableRender(1, value);
    }
    this.setState({
      [name]: value,
    });
  }

  render() {
    let label = {};
    return (
      <div className="col-span-12 mt-6">
        <div className="intro-y block sm:flex items-center h-10">
          <h2 className="text-lg font-medium truncate mr-5">Data Paket</h2>
        </div>
        <div className="intro-y overflow-auto lg:overflow-visible mt-8 sm:mt-0">
          <table className="table table-report sm:mt-2">
            <thead>
              <tr>
                <th className="whitespace-nowrap" width="5%">
                  ID
                </th>
                <th className="whitespace-nowrap">NAMA PAKET</th>
                <th className="text-center whitespace-nowrap">HPS</th>
                <th className="text-center whitespace-nowrap">STATUS</th>
                <th className="text-center whitespace-nowrap">LAST UPDATE</th>
              </tr>
            </thead>
            <tbody>
              {this.props.paket.slice(this.state.page * Cookies.get("perpage") - Cookies.get("perpage"), this.state.limit).map((e, index) => {
                label = status.find((o) => {
                  return o.id === e.paket_status;
                });
                return (
                  <tr className="intro-x" key={index}>
                    <td className="w-40">{e.paket_id}</td>
                    <td>
                      <Link to={"/paket/detail/" + e.paket_id} className="font-medium">
                        {e.paket_nama}
                      </Link>
                      <div className="text-gray-600 text-xs whitespace-nowrap">
                        {
                          this.props.jenis_pekerjaan.find((obj) => {
                            return obj.id === e.paket_jenispekerjaan;
                          }).name
                        }
                      </div>
                    </td>
                    <td className="text-center">{pembulatan(e.paket_hps)}</td>
                    <td className="text-center text-xs whitespace-nowrap">
                      <span className={`text-xs px-2 py-1 rounded-full ${label.color} cursor-pointer font-medium`}>{label.label}</span>
                    </td>
                    <td className="w-40">{moment(e.paket_updated).fromNow()}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div className="intro-y flex flex-wrap sm:flex-row sm:flex-no-wrap items-center mt-3">
          <ul className="pagination">{this.paging()}</ul>
          <select className="w-20 form-select box mt-3 sm:mt-0" onChange={(e) => this.onChange(e)} value={Cookies.get("perpage")}>
            <option value="5">5</option>
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
          </select>
        </div>
      </div>
    );
  }
}

export default withRouter(Datapaket);
