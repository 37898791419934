import React, { Component } from "react";

class FailedNotification extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div id="failed-notification-content" className="toastify-content hidden flex">
        <i className="text-theme-6" data-feather="x-circle"></i>
        <div className="ml-4 mr-4">
          <div className="font-medium">{this.props.error_title ? this.props.error_title : "Data Belum Lengkap!"}</div>
          <div className="text-gray-600 mt-1"> {this.props.pesan ? this.props.pesan : "Please check the fileld form."} </div>
        </div>
      </div>
    );
  }
}

export default FailedNotification;
