import React, { Component } from "react";
import { ReactTabulator } from "react-tabulator";
import feather from "feather-icons";
import Cookies from "js-cookie";
import { withRouter } from "react-router-dom";
import moment from "moment";

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    feather.replace({
      "stroke-width": 1.5,
    });
  }

  tableRender() {
    const options = {
      // movableRows: true,
      responsiveLayout: true,
      ajaxFiltering: true,
      // ajaxProgressiveLoad: "scroll",
      // ajaxProgressiveLoadDelay: 200,
      // ajaxProgressiveLoadScrollMargin: 100,
      ajaxURL: `${process.env.REACT_APP_APIURL}log/data/${Cookies.get("tahun")}`,
      ajaxConfig: {
        headers: {
          Authorization: "Bearer " + Cookies.get("auth-token"),
        },
      },
      paginationDataSent: {
        page: "page",
        size: "per_page", // change 'size' param to 'per_page'
      },
      paginationDataReceived: {
        last_page: "total_pages",
      },
      current_page: 1,
      paginationSize: 10,
      paginationSizeSelector: [5, 10, 50, 100],
      pagination: "remote",
      ajaxResponse: function (url, params, response) {
        return response; //return the response data to tabulator
      },
      ajaxError: function (error) {
        console.log("ajaxError", error);
      },
    };
    const columns = [
      { title: "User", field: "log_user", width: 150, headerFilter: "input" },
      { title: "IP", field: "log_ip", headerFilter: "input" },
      {
        title: "Waktu",
        field: "log_time",
        headerFilter: "input",
        formatter: function (cell, formatterParams, onRendered) {
          return moment(cell.getValue()).format("DD MMM Y HH:mm:ss");
        },
      },
    ];
    return <ReactTabulator ref={(ref) => (this.ref = ref)} columns={columns} data={[]} options={options} />;
  }

  render() {
    return (
      <div>
        <div className="intro-y flex flex-col sm:flex-row items-center mt-8">
          <h2 className="text-lg font-medium mr-auto">Data Login</h2>
        </div>
        <div className="intro-y datatable-wrapper box p-5 mt-5">{this.tableRender()}</div>
      </div>
    );
  }
}

export default withRouter(Index);
