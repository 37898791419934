import React, { Component } from "react";
import feather from "feather-icons";
import moment from "moment";
import Cookies from "js-cookie";
import axios from "axios";
import Toastify from "toastify-js";
import cash from "cash-dom";
import Modal from "../master/Modal";

class ModalUpload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      proses: false,
      jenis: "",
      file: "",
      progress: 0,
    };
  }

  onChange(e) {
    const name = e.target.name;
    const tipe = e.target.type;
    let value = e.target.value;
    if (tipe === "file") {
      value = e.target.files[0];
    }

    this.setState(
      {
        [name]: value,
      },
      () => {
        if (this.state.jenis !== "" && this.state.file != "") {
          this.setState({ proses: true });
        }
      }
    );
  }

  progressClass() {
    if (this.state.progress == 0) return "w-0";
    else if (this.state.progress < 8) return "w-1/12";
    else if (this.state.progress < 16) return "w-1/6";
    else if (this.state.progress < 21) return "w-1/5";
    else if (this.state.progress < 26) return "w-1/4";
    else if (this.state.progress < 33) return "w-1/3";
    else if (this.state.progress < 41) return "w-2/5";
    else if (this.state.progress < 42) return "w-5/12";
    else if (this.state.progress < 51) return "w-1/2";
    else if (this.state.progress < 59) return "w-7/12";
    else if (this.state.progress < 60) return "w-3/5";
    else if (this.state.progress < 67) return "w-2/3";
    else if (this.state.progress < 75) return "w-3/4";
    else if (this.state.progress < 80) return "w-4/5";
    else if (this.state.progress < 84) return "w-5/6";
    else if (this.state.progress < 92) return "w-11/12";
    else if (this.state.progress >= 100) return "w-full";
    else return "w-0";
  }

  componentDidMount() {
    feather.replace({
      "stroke-width": 1.5,
    });
  }

  kirimFile() {
    this.setState({
      proses: true,
    });
    const formData = new FormData();
    formData.append("paket_id", this.props.paket.paket_id);
    formData.append("tahun", this.props.paket.paket_tahun);
    formData.append("user", Cookies.get("username"));
    formData.append("panitia", Cookies.get("level"));
    formData.append("jenis", this.state.jenis);
    formData.append("file", this.state.file);
    axios
      .post(`${process.env.REACT_APP_APIURL}dokumen/kirim`, formData, {
        headers: { Authorization: "Bearer " + Cookies.get("auth-token") },
        onUploadProgress: (data) => {
          this.setState({
            progress: Math.round((100 * data.loaded) / data.total),
          });
        },
        timeout: 120000,
      })
      .then((response) => {
        let dokumen = this.props.dokumen;
        let cur = dokumen.findIndex((e) => e.dl_jenis === this.state.jenis);
        let baru = {
          dl_id: response.data.insertId,
          dl_jenis: this.state.jenis,
          dl_paket: this.props.paket.paket_id,
          dl_created: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
          dl_size: (this.state.file.size / 1024).toFixed(),
          dok_nama: this.props.jenis_dokumen.find((e) => e.dok_kode === this.state.jenis).dok_nama,
          dl_type: this.state.file.name.split(".").pop(),
          dl_user: Cookies.get("username"),
          revisi: cur === -1 ? 0 : dokumen[cur].revisi + 1,
        };
        dokumen.splice(cur === -1 ? dokumen.length : cur, 1, baru);
        this.props.updateDokumen(dokumen);
        this.setState({
          jenis: "",
          file: "",
          proses: false,
        });

        cash("#upload_file").modal("hide");
        Toastify({
          node: cash("#success-notification-content").clone().removeClass("hidden")[0],
          duration: 5000,
          newWindow: true,
          close: true,
          gravity: "top",
          position: "right",
          stopOnFocus: true,
        }).showToast();
      })
      .catch((err) => {
        cash("#upload_file").modal("hide");
        this.setState({
          jenis: "",
          file: "",
          proses: false,
        });
        console.log(err);
        if (err != undefined) alert("Error: " + err.response.data.message);
      });
  }

  render() {
    return (
      <Modal>
        <div className="modal" id="upload_file">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="flex items-center px-5 py-5 sm:py-3 border-b border-gray-200">
                <h2 className="font-medium text-base mr-auto">Upload File</h2>
              </div>
              <div className="p-5">
                <div className="col-span-12 ">
                  <label>Jenis Dokumen</label>
                  <select className="form-select" name="jenis" value={this.state.jenis} onChange={(e) => this.onChange(e)} required>
                    <option>Pilih Jenis</option>
                    {this.props.jenis_dokumen.map((e, idx) => {
                      return (
                        <option value={`${e.dok_kode}`} key={e.dok_id}>
                          {e.dok_nama}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="col-span-12 mt-2">
                  <label>File</label>
                  <input type="file" className="form-control" name="file" onChange={(e) => this.onChange(e)} required />
                </div>
                <div className="w-full h-4 bg-gray-400 rounded mt-1">
                  <div
                    className={`progress-bar ${this.progressClass()} ${this.state.progress > 0 ? "bg-theme-1" : ""} rounded text-white text-center`}
                    role="progressbar"
                    aria-valuenow={this.state.progress}
                    aria-valuemin="0"
                    aria-valuemax="100"
                  >
                    {this.state.progress}%
                  </div>
                </div>
              </div>
              <div className="px-5 py-3 text-right border-gray-200">
                <button type="button" className="btn btn-secondary w-20 bg-gray-200 mr-2" data-dismiss="modal">
                  Cancel
                </button>
                <button
                  type="button"
                  className={`btn  w-20 ${this.state.proses ? "btn-primary" : "btn-secondary"}`}
                  disabled={!this.state.proses}
                  onClick={() => this.kirimFile()}
                >
                  Upload
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

export default ModalUpload;
