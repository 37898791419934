import React, { Component } from "react";
// import cash from "cash-dom";
import feather from "feather-icons";
import Depan from "./_index";

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    document.title = process.env.REACT_APP_NAME;
    feather.replace({
      "stroke-width": 1.5,
    });
  }

  render() {
    return <Depan />;
  }
}

export default Index;
