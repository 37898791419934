import React, { Component } from "react";
import moment from "moment";
import { status } from "../../Definisi";
import Cookies from "js-cookie";

class Files extends Component {
  constructor(props) {
    super(props);
    this.state = { history: [], tinggi: 707 };
  }

  // static getDerivedStateFromProps(nextProps, prevState) {
  //   return {
  //     history: nextProps.history,
  //   };
  // }

  componentDidMount() {
    this.setState({
      history: this.props.history,
    });
    let kiri = document.querySelector("#kiri");
    let kiri_isi = document.querySelector("#kiri_isi");
    this.setState({ tinggi: kiri.offsetHeight - kiri_isi.offsetHeight - 20 });
  }

  render() {
    return (
      <div className="tab-pane" id="history" role="tabpanel" aria-labelledby="chats-tab">
        <div className="pr-1">
          <div className="mt-5 overflow-x-auto scrollbar-hidden" style={{ height: this.state.tinggi + "px" }}>
            {this.state.history.map((e, index) => {
              return (
                <div className={`flex p-5 box items-center ${index > 0 ? "mt-5" : ""}`} key={index}>
                  <div className="border-l-2 border-theme-1 pl-4">
                    <span className={`text-xs px-2 py-1 rounded-full ${status.find((o) => o.id === e.history_status).color}`}>
                      {status.find((o) => o.id === e.history_status).label}
                    </span>
                    <div className="text-gray-600 text-xs mt-2">{moment(e.history_date).format("D MMM Y HH:mm")}</div>
                  </div>
                  <span className="ml-auto">
                    {Cookies.get("level") == 4 && e.history_user != Cookies.get("username") ? (e.history_panitia == "5" ? "POKJA/PP" : "UKPBJ") : e.history_user}
                  </span>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}

export default Files;
