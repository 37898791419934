import React, { Component } from "react";

import Step1 from "./_step1";
import Step2 from "./_step2";
import Step3 from "./_step3";

class Pengajuan extends Component {
  constructor(props) {
    super(props);
    // this.pilihRUP.bind(this);
    this.state = {
      step: 1,
      paket_id: undefined,
      jenispekerjaan: undefined,
    };
  }

  componentDidMount() {}

  updateStep = (newstep, paket_id = undefined, jenispekerjaan = undefined) => {
    this.setState({
      step: newstep,
      paket_id: paket_id,
      jenispekerjaan: jenispekerjaan,
    });

    if (paket_id !== undefined) {
      this.props.history.push("/paket/pengajuan/" + this.props.match.params.rup_id + "/" + paket_id);
    }
  };

  renderStep() {
    if (this.state.step === 1) {
      return <Step1 sirup_id={this.props.match.params.rup_id} paket_id={this.props.match.params.paket_id} updateStep={this.updateStep} />;
    } else if (this.state.step === 2) {
      return (
        <Step2 updateStep={this.updateStep} jenis_paket={this.state.jenis_paket} paket_id={this.props.match.params.paket_id} jenispekerjaan={this.state.jenispekerjaan} />
      );
    } else if (this.state.step === 3) {
      return <Step3 paket_id={this.props.match.params.paket_id} />;
    } else {
      console.log("wrong step");
    }
  }

  render() {
    return (
      <div>
        <div className="intro-y flex flex-col sm:flex-row items-center mt-8">
          <h2 className="text-lg font-medium mr-auto">Pengajuan Paket Baru</h2>
        </div>
        <div className="intro-y box py-10 sm:py-20 mt-5">
          <div className="wizard flex flex-col lg:flex-row justify-center px-5 sm:px-20">
            <div className="intro-x lg:text-center flex items-center lg:block flex-1 z-10">
              <button className={`w-10 h-10 rounded-full button ${this.state.step === 1 ? "text-white bg-theme-1" : "text-gray-600 bg-gray-200"}`}>1</button>
              <div className="lg:w-32 font-medium text-base lg:mt-3 ml-3 lg:mx-auto">Pilih RUP</div>
            </div>
            <div className="intro-x lg:text-center flex items-center mt-5 lg:mt-0 lg:block flex-1 z-10">
              <button className={`w-10 h-10 rounded-full button ${this.state.step === 2 ? "text-white bg-theme-1" : "text-gray-600 bg-gray-200"}`}>2</button>
              <div className="lg:w-32 text-base lg:mt-3 ml-3 lg:mx-auto text-gray-700">File Pendukung</div>
            </div>
            <div className="intro-x lg:text-center flex items-center mt-5 lg:mt-0 lg:block flex-1 z-10">
              <button className={`w-10 h-10 rounded-full button ${this.state.step === 3 ? "text-white bg-theme-1" : "text-gray-600 bg-gray-200"}`}>3</button>
              <div className="lg:w-32 text-base lg:mt-3 ml-3 lg:mx-auto text-gray-700">Selesai</div>
            </div>
            <div className="wizard__line hidden lg:block w-2/3 bg-gray-200 absolute mt-5"></div>
          </div>
          {this.renderStep()}
        </div>
      </div>
    );
  }
}

export default Pengajuan;
