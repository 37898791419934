import React, { Component } from "react";
import { withRouter, Switch, Route } from "react-router-dom";
import axios from "axios";

import Login from "./_Login";
import Register from "./_Register";

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      versi: "",
    };
  }

  componentDidMount() {
    axios.get(`${process.env.REACT_APP_APIURL}changelog/current`).then((response) => {
      this.setState(
        {
          versi: " v" + response.data.versi + "." + process.env.REACT_APP_VERSI,
        },
        () => {
          document.title = "Login | " + process.env.REACT_APP_NAME + " v" + response.data.versi + "." + process.env.REACT_APP_VERSI;
        }
      );
    });
  }

  render() {
    return (
      <div className="container sm:px-10" id="SignUp">
        <div className="block xl:grid grid-cols-2 gap-4">
          <div className="hidden xl:flex flex-col min-h-screen">
            <a href="#" className="-intro-x flex items-center pt-5">
              <img alt={process.env.REACT_APP_NAME} className="w-6" src="dist/images/logo.svg" />
              <span className="text-white text-lg ml-3">{process.env.REACT_APP_NAME + this.state.versi}</span>
            </a>
            <div className="my-auto">
              <img alt={process.env.REACT_APP_NAME} className="-intro-x w-1/2 -mt-16" src="dist/images/illustration.svg" />
              <div className="-intro-x text-white font-medium text-4xl leading-tight mt-10">
                A few more clicks to
                <br />
                sign in to your account.
              </div>
              <div className="-intro-x mt-5 text-lg text-white">Manage all your packets in one place</div>
            </div>
          </div>
          <div className="h-screen xl:h-auto flex py-5 xl:py-0 my-10 xl:my-0">
            <div className="my-auto mx-auto xl:ml-20 bg-white dark:bg-dark-1 xl:bg-transparent px-5 sm:px-8 py-8 xl:p-0 rounded-md shadow-md xl:shadow-none w-full sm:w-3/4 lg:w-2/4 xl:w-auto">
              <Switch>
                <Route path="/auth" component={Login} key="login" />
                <Route path="/register" component={Register} key="register" />
              </Switch>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Index);
