import React, { Component } from "react";
import Cookies from "js-cookie";
import axios from "axios";
import Modal from "../master/Modal";

class ModalPilihPokja extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pp_selected: "",
      proses: false,
    };
  }

  onChange(e) {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({
      [name]: value,
    });
  }

  componentDidMount() {
    this.setState({
      pp_selected: this.props.paket.paket_pp,
    });
  }

  setPP() {
    this.setState({ proses: true });
    let formData = {
      paket_id: this.props.paket.paket_id,
      pp: this.state.pp_selected,
      nama_ttd: Cookies.get("nama"),
      jabatan: Cookies.get("jabatan"),
      nip: Cookies.get("nip"),
      pangkat: Cookies.get("pangkat"),
      username: Cookies.get("username"),
      sudah: this.props.paket.paket_pp,
      tahun: Cookies.get("tahun"),
    };
    let url, mode;
    url = process.env.REACT_APP_APIURL + "pp/setpp/" + this.props.paket.paket_id;
    mode = this.props.paket.paket_pp ? "update" : "add";

    axios
      .put(`${url}`, formData, {
        headers: { Authorization: "Bearer " + Cookies.get("auth-token") },
      })
      .then((response) => {
        this.setState({ proses: false });
        this.props.pilihPP(mode);
      });
  }

  render() {
    return (
      <Modal>
        <div className="modal" id="pilih_pp">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="flex items-center px-5 py-5 sm:py-3 border-b border-gray-200">
                <h2 className="font-medium text-base mr-auto">Pilih Pejabat Pengadaan</h2>
              </div>
              <div className="p-5">
                <div className="col-span-12 mt-3">
                  <label>Pilih PP </label>
                  <select className="form-select" value={this.state.pp_selected} name="pp_selected" placeholder="Pilih PP" onChange={(e) => this.onChange(e)}>
                    <option value=""> Pilih PP</option>
                    {this.props.pp.map((e, idx) => {
                      return (
                        <option value={`${e.user_pp_id}`} key={e.user_pp_id}>
                          {e.user_nama} ({e.selesai}/{e.banyak})
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              <div className="px-5 py-3 text-right border-gray-200">
                <button type="button" className="btn w-20 bg-gray-200 mr-2" data-dismiss="modal">
                  Cancel
                </button>
                <button
                  type="button"
                  className={`btn w-20 ${this.state.proses ? "bg-gray-200" : "bg-theme-1 text-white"} simpan_pokja`}
                  disabled={this.state.proses}
                  onClick={() => this.setPP()}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

export default ModalPilihPokja;
