import React, { Component } from "react";
import NumberFormat from "react-number-format";

import { status } from "../../Definisi";

class Detail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // jenis_pekerjaan: [],
      stats: status.find((e) => e.id == this.props.paket.paket_status),
      paket: "",
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    return {
      paket: nextProps.paket,
      stats: status.find((e) => e.id == nextProps.paket.paket_status),
    };
  }

  componentDidMount() {
    this.setState({
      stats: status.find((e) => e.id == this.props.paket.paket_status),
      paket: this.props.paket,
    });
  }

  render() {
    let jenis_pekerjaan = "",
      kualifikasi = "";
    return (
      <div className="tab-pane active" id="detail" role="tabpanel" aria-labelledby="chats-tab">
        <div className="pr-1">
          <div className="box px-5 py-5 mt-5">
            <div className="w-20 h-20 flex-none image-fit rounded-full overflow-hidden mx-auto">
              <img alt="Foto PPK" src={`${process.env.REACT_APP_APIURL}uploads/${this.props.paket.user_image}`} />
            </div>
            <div className="text-center mt-3">
              <div className="font-medium text-lg">{this.props.paket.user_nama}</div>
              <div className="text-gray-600 mt-1">{this.props.paket.user_nohp}</div>
              <div className="mb-3">{this.props.paket.paket_nama}</div>
              {this.state.stats ? (
                <span key={this.props.paket.paket_status} className={`text-xs px-2 py-1 rounded-full ${this.state.stats.color}`}>
                  {this.state.stats.label}
                </span>
              ) : (
                <span key={this.props.paket.paket_status}>-</span>
              )}
            </div>
          </div>
          <div className="box p-5 mt-5">
            <div className="flex items-center border-b py-2">
              <div className="">
                <div className="text-gray-600">Kode RUP</div>
                <div>{this.props.paket.paket_rup}</div>
              </div>
              <i data-feather="credit-card" className="w-4 h-4 text-gray-600 ml-auto"></i>
            </div>
            <div className="flex items-center border-b py-2">
              <div className="">
                <div className="text-gray-600">Metode Pemilihan</div>
                <div>{this.props.metode_pemilihan.find((i) => i.id == this.props.paket.paket_metode).name}</div>
              </div>
              <i data-feather="minimize" className="w-4 h-4 text-gray-600 ml-auto"></i>
            </div>
            <div className="flex items-center border-b py-2">
              <div className="">
                <div className="text-gray-600">Pagu / HPS</div>
                <div>
                  <NumberFormat value={this.props.paket.paket_pagu} displayType={"text"} thousandSeparator={true} decimalScale={0} /> /{" "}
                  <NumberFormat value={this.props.paket.paket_hps} displayType={"text"} thousandSeparator={true} decimalScale={0} />
                </div>
              </div>
              <i data-feather="dollar-sign" className="w-4 h-4 text-gray-600 ml-auto"></i>
            </div>
            <div className="flex items-center border-b py-2">
              <div className="">
                <div className="text-gray-600">Lokasi</div>
                <div>
                  {this.props.paket.paket_lokasi
                    ? this.props.paket.paket_lokasi.split(",").map((e, index) => {
                        let x = this.props.kecamatan.find((obj) => {
                          return obj.kecamatan_id == e;
                        });
                        if (index > 0) {
                          return ", " + x.kecamatan_nama;
                        } else {
                          return x.kecamatan_nama;
                        }
                      })
                    : ""}
                </div>
              </div>
              <i data-feather="map-pin" className="w-4 h-4 text-gray-600 ml-auto"></i>
            </div>
            <div className="flex items-center border-b py-2">
              <div className="">
                <div className="text-gray-600">Jenis Pekerjaan</div>
                <div>
                  {(jenis_pekerjaan = this.props.jenis_pekerjaan.find((obj) => {
                    return obj.id === this.props.paket.paket_jenispekerjaan;
                  }))
                    ? jenis_pekerjaan.name
                    : "not found"}
                </div>
              </div>
              <i data-feather="archive" className="w-4 h-4 text-gray-600 ml-auto"></i>
            </div>
            <div className="flex items-center border-b py-2">
              <div className="">
                <div className="text-gray-600">Kualifikasi</div>
                <div>
                  {(kualifikasi = this.props.kualifikasi.find((obj, idx) => {
                    return idx === this.props.paket.paket_kualifikasi;
                  }))
                    ? kualifikasi
                    : "not found"}
                </div>
              </div>
              <i data-feather="layers" className="w-4 h-4 text-gray-600 ml-auto"></i>
            </div>
            <div className="flex items-center border-b py-2">
              <div className="">
                <div className="text-gray-600">Sumber Dana</div>
                <div>
                  {this.props.paket.paket_sumberdana
                    ? this.props.paket.paket_sumberdana
                        .toString()
                        .split(",")
                        .map((e, idx) => {
                          let x = this.props.sumberdana.find((obj) => {
                            // if (obj.kecamatan_id == e) {
                            //   console.log("ketemu");
                            // } else {
                            //   console.log("tidak ", e);
                            // }
                            return obj.sd_id == e;
                            // return "a";
                          });
                          // console.log(this.props.kecamatan);
                          // console.log("x: ", x);
                          if (idx > 0) {
                            return ", " + x.sd_nama;
                          } else {
                            return x.sd_nama;
                          }
                        })
                    : "-"}
                </div>
              </div>
              <i data-feather="database" className="w-4 h-4 text-gray-600 ml-auto"></i>
            </div>
            <div className="flex items-center border-b py-2">
              <div className="">
                <div className="text-gray-600">Jenis Kontrak</div>
                <div>
                  {this.props.paket.paket_kontrak
                    ? this.props.jenis_kontrak.find((o) => {
                        return o.id === this.props.paket.paket_kontrak;
                      }).name
                    : "-"}
                </div>
              </div>
              <i data-feather="book" className="w-4 h-4 text-gray-600 ml-auto"></i>
            </div>
            <div className="flex items-center pt-2">
              <div className="">
                <div className="text-gray-600">Sifat </div>
                <div className="">
                  {this.props.paket.paket_kompleks
                    ? this.props.sifat_pengadaan.find((o) => {
                        return o.id === this.props.paket.paket_kompleks;
                      }).name
                    : "-"}
                </div>
              </div>
              <i data-feather="alert-circle" className="w-4 h-4 text-gray-600 ml-auto"></i>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Detail;
