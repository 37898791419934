import React, { Component } from "react";

class Sanggahan extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="intro-y box col-span-12 lg:col-span-6">
        <div className="flex items-center px-5 py-5 sm:py-0 border-b border-gray-200">
          <h2 className="font-medium text-base mr-auto py-5">Sanggahan</h2>
        </div>
        <div className="p-5">
          <div className="tab-content">
            <div className="tab-content__pane active" id="latest-tasks-new">
              {this.props.sanggahan.map((e, idx) => {
                return (
                  <div className="flex items-center" key={idx}>
                    <div className="border-l-2 border-theme-1 pl-4">
                      {e.rkn_nama}
                      <div className="text-gray-600">{e.sgh_tanggal}</div>
                    </div>
                    <input className="input input--switch ml-auto border" type="checkbox" />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Sanggahan;
