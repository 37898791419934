import React, { Component } from "react";
import axios from "axios";
import { withRouter, Link } from "react-router-dom";
import feather from "feather-icons";
import cash from "cash-dom";
import Velocity from "velocity-animate";

class Register extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
      error: false,
      error_message: "",
      ketemu: false,
      nama: "",
      jabatan: "",
      pangkat: "",
      email: "",
      nohp: "",
      jk: "",
      nip: "",
      id_spse: "",
      level: "",
      foto: null,
      file_foto: null,
      setuju: false,
      loading_check: false,
      loading: false,
      lengkap: false,
      levels: [],
    };
  }

  onChange(e) {
    const name = e.target.name;
    const tipe = e.target.type;
    var value;

    if (tipe === "file") {
      value = e.target.files[0];
      let size = value.size / 1024 / 1024; //dalam mb
      if (size > 1) {
        alert("Ukuran File tidak boleh lebih dari 1Mb");
        return false;
      }
      this.setState({
        file_foto: URL.createObjectURL(value),
      });
    } else if (tipe === "checkbox") {
      value = !this.state.setuju;
    } else value = e.target.value;

    this.setState(
      {
        [name]: value,
      },
      () => {
        if (this.state.nama.length > 0 && this.state.email.length > 0 && this.state.nohp.length > 0 && this.state.jk.length > 0 && this.state.file_foto && this.state.setuju) {
          this.setState({
            lengkap: true,
          });
        } else {
          this.setState({
            lengkap: false,
          });
        }
        // this.setState({ lengkap: true });
      }
    );
  }

  loadingIcon() {
    cash(`[data-loading-icon]`).each(function () {
      let color = cash(this).data("color") !== undefined ? cash(this).data("color") : cash("body").css("color");
      let classAttr = cash(this).attr("class") !== undefined ? cash(this).attr("class") : "";
      let icons = [
        {
          name: "audio",
          svg: `
                      <svg width="15" viewBox="0 0 55 80" xmlns="http://www.w3.org/2000/svg" fill="${color}" className="${classAttr}">
                          <g transform="matrix(1 0 0 -1 0 80)">
                              <rect width="10" height="20" rx="3">
                                  <animate attributeName="height"
                                      begin="0s" dur="4.3s"
                                      values="20;45;57;80;64;32;66;45;64;23;66;13;64;56;34;34;2;23;76;79;20" calcMode="linear"
                                      repeatCount="indefinite" />
                              </rect>
                              <rect x="15" width="10" height="80" rx="3">
                                  <animate attributeName="height"
                                      begin="0s" dur="2s"
                                      values="80;55;33;5;75;23;73;33;12;14;60;80" calcMode="linear"
                                      repeatCount="indefinite" />
                              </rect>
                              <rect x="30" width="10" height="50" rx="3">
                                  <animate attributeName="height"
                                      begin="0s" dur="1.4s"
                                      values="50;34;78;23;56;23;34;76;80;54;21;50" calcMode="linear"
                                      repeatCount="indefinite" />
                              </rect>
                              <rect x="45" width="10" height="30" rx="3">
                                  <animate attributeName="height"
                                      begin="0s" dur="2s"
                                      values="30;45;13;80;56;72;45;76;34;23;67;30" calcMode="linear"
                                      repeatCount="indefinite" />
                              </rect>
                          </g>
                      </svg>
                  `,
        },
        {
          name: "ball-triangle",
          svg: `
                      <svg width="20" viewBox="0 0 57 57" xmlns="http://www.w3.org/2000/svg" className="${classAttr}">
                          <g fill="none" fill-rule="evenodd">
                              <g transform="translate(1 1)">
                                  <circle cx="5" cy="50" r="5" fill="${color}">
                                      <animate attributeName="cy"
                                          begin="0s" dur="2.2s"
                                          values="50;5;50;50"
                                          calcMode="linear"
                                          repeatCount="indefinite" />
                                      <animate attributeName="cx"
                                          begin="0s" dur="2.2s"
                                          values="5;27;49;5"
                                          calcMode="linear"
                                          repeatCount="indefinite" />
                                  </circle>
                                  <circle cx="27" cy="5" r="5" fill="${color}">
                                      <animate attributeName="cy"
                                          begin="0s" dur="2.2s"
                                          from="5" to="5"
                                          values="5;50;50;5"
                                          calcMode="linear"
                                          repeatCount="indefinite" />
                                      <animate attributeName="cx"
                                          begin="0s" dur="2.2s"
                                          from="27" to="27"
                                          values="27;49;5;27"
                                          calcMode="linear"
                                          repeatCount="indefinite" />
                                  </circle>
                                  <circle cx="49" cy="50" r="5" fill="${color}">
                                      <animate attributeName="cy"
                                          begin="0s" dur="2.2s"
                                          values="50;50;5;50"
                                          calcMode="linear"
                                          repeatCount="indefinite" />
                                      <animate attributeName="cx"
                                          from="49" to="49"
                                          begin="0s" dur="2.2s"
                                          values="49;5;27;49"
                                          calcMode="linear"
                                          repeatCount="indefinite" />
                                  </circle>
                              </g>
                          </g>
                      </svg>
                  `,
        },
        {
          name: "bars",
          svg: `
                      <svg width="20" viewBox="0 0 135 140" xmlns="http://www.w3.org/2000/svg" fill="${color}" className="${classAttr}">
                          <rect y="10" width="15" height="120" rx="6">
                              <animate attributeName="height"
                                  begin="0.5s" dur="1s"
                                  values="120;110;100;90;80;70;60;50;40;140;120" calcMode="linear"
                                  repeatCount="indefinite" />
                              <animate attributeName="y"
                                  begin="0.5s" dur="1s"
                                  values="10;15;20;25;30;35;40;45;50;0;10" calcMode="linear"
                                  repeatCount="indefinite" />
                          </rect>
                          <rect x="30" y="10" width="15" height="120" rx="6">
                              <animate attributeName="height"
                                  begin="0.25s" dur="1s"
                                  values="120;110;100;90;80;70;60;50;40;140;120" calcMode="linear"
                                  repeatCount="indefinite" />
                              <animate attributeName="y"
                                  begin="0.25s" dur="1s"
                                  values="10;15;20;25;30;35;40;45;50;0;10" calcMode="linear"
                                  repeatCount="indefinite" />
                          </rect>
                          <rect x="60" width="15" height="140" rx="6">
                              <animate attributeName="height"
                                  begin="0s" dur="1s"
                                  values="120;110;100;90;80;70;60;50;40;140;120" calcMode="linear"
                                  repeatCount="indefinite" />
                              <animate attributeName="y"
                                  begin="0s" dur="1s"
                                  values="10;15;20;25;30;35;40;45;50;0;10" calcMode="linear"
                                  repeatCount="indefinite" />
                          </rect>
                          <rect x="90" y="10" width="15" height="120" rx="6">
                              <animate attributeName="height"
                                  begin="0.25s" dur="1s"
                                  values="120;110;100;90;80;70;60;50;40;140;120" calcMode="linear"
                                  repeatCount="indefinite" />
                              <animate attributeName="y"
                                  begin="0.25s" dur="1s"
                                  values="10;15;20;25;30;35;40;45;50;0;10" calcMode="linear"
                                  repeatCount="indefinite" />
                          </rect>
                          <rect x="120" y="10" width="15" height="120" rx="6">
                              <animate attributeName="height"
                                  begin="0.5s" dur="1s"
                                  values="120;110;100;90;80;70;60;50;40;140;120" calcMode="linear"
                                  repeatCount="indefinite" />
                              <animate attributeName="y"
                                  begin="0.5s" dur="1s"
                                  values="10;15;20;25;30;35;40;45;50;0;10" calcMode="linear"
                                  repeatCount="indefinite" />
                          </rect>
                      </svg>
                  `,
        },
        {
          name: "circles",
          svg: `
                      <svg width="20" viewBox="0 0 135 135" xmlns="http://www.w3.org/2000/svg" fill="${color}" className="${classAttr}">
                          <path d="M67.447 58c5.523 0 10-4.477 10-10s-4.477-10-10-10-10 4.477-10 10 4.477 10 10 10zm9.448 9.447c0 5.523 4.477 10 10 10 5.522 0 10-4.477 10-10s-4.478-10-10-10c-5.523 0-10 4.477-10 10zm-9.448 9.448c-5.523 0-10 4.477-10 10 0 5.522 4.477 10 10 10s10-4.478 10-10c0-5.523-4.477-10-10-10zM58 67.447c0-5.523-4.477-10-10-10s-10 4.477-10 10 4.477 10 10 10 10-4.477 10-10z">
                              <animateTransform
                                  attributeName="transform"
                                  type="rotate"
                                  from="0 67 67"
                                  to="-360 67 67"
                                  dur="2.5s"
                                  repeatCount="indefinite"/>
                          </path>
                          <path d="M28.19 40.31c6.627 0 12-5.374 12-12 0-6.628-5.373-12-12-12-6.628 0-12 5.372-12 12 0 6.626 5.372 12 12 12zm30.72-19.825c4.686 4.687 12.284 4.687 16.97 0 4.686-4.686 4.686-12.284 0-16.97-4.686-4.687-12.284-4.687-16.97 0-4.687 4.686-4.687 12.284 0 16.97zm35.74 7.705c0 6.627 5.37 12 12 12 6.626 0 12-5.373 12-12 0-6.628-5.374-12-12-12-6.63 0-12 5.372-12 12zm19.822 30.72c-4.686 4.686-4.686 12.284 0 16.97 4.687 4.686 12.285 4.686 16.97 0 4.687-4.686 4.687-12.284 0-16.97-4.685-4.687-12.283-4.687-16.97 0zm-7.704 35.74c-6.627 0-12 5.37-12 12 0 6.626 5.373 12 12 12s12-5.374 12-12c0-6.63-5.373-12-12-12zm-30.72 19.822c-4.686-4.686-12.284-4.686-16.97 0-4.686 4.687-4.686 12.285 0 16.97 4.686 4.687 12.284 4.687 16.97 0 4.687-4.685 4.687-12.283 0-16.97zm-35.74-7.704c0-6.627-5.372-12-12-12-6.626 0-12 5.373-12 12s5.374 12 12 12c6.628 0 12-5.373 12-12zm-19.823-30.72c4.687-4.686 4.687-12.284 0-16.97-4.686-4.686-12.284-4.686-16.97 0-4.687 4.686-4.687 12.284 0 16.97 4.686 4.687 12.284 4.687 16.97 0z">
                              <animateTransform
                                  attributeName="transform"
                                  type="rotate"
                                  from="0 67 67"
                                  to="360 67 67"
                                  dur="8s"
                                  repeatCount="indefinite"/>
                          </path>
                      </svg>
                  `,
        },
        {
          name: "grid",
          svg: `
                      <svg width="20" viewBox="0 0 105 105" xmlns="http://www.w3.org/2000/svg" fill="${color}" className="${classAttr}">
                          <circle cx="12.5" cy="12.5" r="12.5">
                              <animate attributeName="fill-opacity"
                              begin="0s" dur="1s"
                              values="1;.2;1" calcMode="linear"
                              repeatCount="indefinite" />
                          </circle>
                          <circle cx="12.5" cy="52.5" r="12.5" fill-opacity=".5">
                              <animate attributeName="fill-opacity"
                              begin="100ms" dur="1s"
                              values="1;.2;1" calcMode="linear"
                              repeatCount="indefinite" />
                          </circle>
                          <circle cx="52.5" cy="12.5" r="12.5">
                              <animate attributeName="fill-opacity"
                              begin="300ms" dur="1s"
                              values="1;.2;1" calcMode="linear"
                              repeatCount="indefinite" />
                          </circle>
                          <circle cx="52.5" cy="52.5" r="12.5">
                              <animate attributeName="fill-opacity"
                              begin="600ms" dur="1s"
                              values="1;.2;1" calcMode="linear"
                              repeatCount="indefinite" />
                          </circle>
                          <circle cx="92.5" cy="12.5" r="12.5">
                              <animate attributeName="fill-opacity"
                              begin="800ms" dur="1s"
                              values="1;.2;1" calcMode="linear"
                              repeatCount="indefinite" />
                          </circle>
                          <circle cx="92.5" cy="52.5" r="12.5">
                              <animate attributeName="fill-opacity"
                              begin="400ms" dur="1s"
                              values="1;.2;1" calcMode="linear"
                              repeatCount="indefinite" />
                          </circle>
                          <circle cx="12.5" cy="92.5" r="12.5">
                              <animate attributeName="fill-opacity"
                              begin="700ms" dur="1s"
                              values="1;.2;1" calcMode="linear"
                              repeatCount="indefinite" />
                          </circle>
                          <circle cx="52.5" cy="92.5" r="12.5">
                              <animate attributeName="fill-opacity"
                              begin="500ms" dur="1s"
                              values="1;.2;1" calcMode="linear"
                              repeatCount="indefinite" />
                          </circle>
                          <circle cx="92.5" cy="92.5" r="12.5">
                              <animate attributeName="fill-opacity"
                              begin="200ms" dur="1s"
                              values="1;.2;1" calcMode="linear"
                              repeatCount="indefinite" />
                          </circle>
                      </svg>
                  `,
        },
        {
          name: "hearts",
          svg: `
                      <svg width="30" viewBox="0 0 140 64" xmlns="http://www.w3.org/2000/svg" fill="${color}" className="${classAttr}">
                          <path d="M30.262 57.02L7.195 40.723c-5.84-3.976-7.56-12.06-3.842-18.063 3.715-6 11.467-7.65 17.306-3.68l4.52 3.76 2.6-5.274c3.717-6.002 11.47-7.65 17.305-3.68 5.84 3.97 7.56 12.054 3.842 18.062L34.49 56.118c-.897 1.512-2.793 1.915-4.228.9z" fill-opacity=".5">
                              <animate attributeName="fill-opacity"
                                  begin="0s" dur="1.4s"
                                  values="0.5;1;0.5"
                                  calcMode="linear"
                                  repeatCount="indefinite" />
                          </path>
                          <path d="M105.512 56.12l-14.44-24.272c-3.716-6.008-1.996-14.093 3.843-18.062 5.835-3.97 13.588-2.322 17.306 3.68l2.6 5.274 4.52-3.76c5.84-3.97 13.592-2.32 17.307 3.68 3.718 6.003 1.998 14.088-3.842 18.064L109.74 57.02c-1.434 1.014-3.33.61-4.228-.9z" fill-opacity=".5">
                              <animate attributeName="fill-opacity"
                                  begin="0.7s" dur="1.4s"
                                  values="0.5;1;0.5"
                                  calcMode="linear"
                                  repeatCount="indefinite" />
                          </path>
                          <path d="M67.408 57.834l-23.01-24.98c-5.864-6.15-5.864-16.108 0-22.248 5.86-6.14 15.37-6.14 21.234 0L70 16.168l4.368-5.562c5.863-6.14 15.375-6.14 21.235 0 5.863 6.14 5.863 16.098 0 22.247l-23.007 24.98c-1.43 1.556-3.757 1.556-5.188 0z" />
                      </svg>
                  `,
        },
        {
          name: "oval",
          svg: `
                      <svg width="25" viewBox="-2 -2 42 42" xmlns="http://www.w3.org/2000/svg" stroke="${color}" className="${classAttr}">
                          <g fill="none" fill-rule="evenodd">
                              <g transform="translate(1 1)" stroke-width="4">
                                  <circle stroke-opacity=".5" cx="18" cy="18" r="18"/>
                                  <path d="M36 18c0-9.94-8.06-18-18-18">
                                      <animateTransform
                                          attributeName="transform"
                                          type="rotate"
                                          from="0 18 18"
                                          to="360 18 18"
                                          dur="1s"
                                          repeatCount="indefinite"/>
                                  </path>
                              </g>
                          </g>
                      </svg>
                  `,
        },
        {
          name: "puff",
          svg: `
                      <svg width="25" viewBox="0 0 44 44" xmlns="http://www.w3.org/2000/svg" stroke="${color}" className="${classAttr}">
                          <g fill="none" fill-rule="evenodd" stroke-width="4">
                              <circle cx="22" cy="22" r="1">
                                  <animate attributeName="r"
                                      begin="0s" dur="1.8s"
                                      values="1; 20"
                                      calcMode="spline"
                                      keyTimes="0; 1"
                                      keySplines="0.165, 0.84, 0.44, 1"
                                      repeatCount="indefinite" />
                                  <animate attributeName="stroke-opacity"
                                      begin="0s" dur="1.8s"
                                      values="1; 0"
                                      calcMode="spline"
                                      keyTimes="0; 1"
                                      keySplines="0.3, 0.61, 0.355, 1"
                                      repeatCount="indefinite" />
                              </circle>
                              <circle cx="22" cy="22" r="1">
                                  <animate attributeName="r"
                                      begin="-0.9s" dur="1.8s"
                                      values="1; 20"
                                      calcMode="spline"
                                      keyTimes="0; 1"
                                      keySplines="0.165, 0.84, 0.44, 1"
                                      repeatCount="indefinite" />
                                  <animate attributeName="stroke-opacity"
                                      begin="-0.9s" dur="1.8s"
                                      values="1; 0"
                                      calcMode="spline"
                                      keyTimes="0; 1"
                                      keySplines="0.3, 0.61, 0.355, 1"
                                      repeatCount="indefinite" />
                              </circle>
                          </g>
                      </svg>
                  `,
        },
        {
          name: "rings",
          svg: `
                      <svg width="30" viewBox="0 0 45 45" xmlns="http://www.w3.org/2000/svg" stroke="${color}" className="${classAttr}">
                          <g fill="none" fill-rule="evenodd" transform="translate(1 1)" stroke-width="3">
                              <circle cx="22" cy="22" r="6" stroke-opacity="0">
                                  <animate attributeName="r"
                                      begin="1.5s" dur="3s"
                                      values="6;22"
                                      calcMode="linear"
                                      repeatCount="indefinite" />
                                  <animate attributeName="stroke-opacity"
                                      begin="1.5s" dur="3s"
                                      values="1;0" calcMode="linear"
                                      repeatCount="indefinite" />
                                  <animate attributeName="stroke-width"
                                      begin="1.5s" dur="3s"
                                      values="2;0" calcMode="linear"
                                      repeatCount="indefinite" />
                              </circle>
                              <circle cx="22" cy="22" r="6" stroke-opacity="0">
                                  <animate attributeName="r"
                                      begin="3s" dur="3s"
                                      values="6;22"
                                      calcMode="linear"
                                      repeatCount="indefinite" />
                                  <animate attributeName="stroke-opacity"
                                      begin="3s" dur="3s"
                                      values="1;0" calcMode="linear"
                                      repeatCount="indefinite" />
                                  <animate attributeName="stroke-width"
                                      begin="3s" dur="3s"
                                      values="2;0" calcMode="linear"
                                      repeatCount="indefinite" />
                              </circle>
                              <circle cx="22" cy="22" r="8">
                                  <animate attributeName="r"
                                      begin="0s" dur="1.5s"
                                      values="6;1;2;3;4;5;6"
                                      calcMode="linear"
                                      repeatCount="indefinite" />
                              </circle>
                          </g>
                      </svg>
                  `,
        },
        {
          name: "spinning-circles",
          svg: `
                      <svg width="20" viewBox="0 0 58 58" xmlns="http://www.w3.org/2000/svg" className="${classAttr}">
                          <g fill="none" fill-rule="evenodd">
                              <g transform="translate(2 1)" stroke="${color}" stroke-width="1.5">
                                  <circle cx="42.601" cy="11.462" r="5" fill-opacity="1" fill="${color}">
                                      <animate attributeName="fill-opacity"
                                          begin="0s" dur="1.3s"
                                          values="1;0;0;0;0;0;0;0" calcMode="linear"
                                          repeatCount="indefinite" />
                                  </circle>
                                  <circle cx="49.063" cy="27.063" r="5" fill-opacity="0" fill="${color}">
                                      <animate attributeName="fill-opacity"
                                          begin="0s" dur="1.3s"
                                          values="0;1;0;0;0;0;0;0" calcMode="linear"
                                          repeatCount="indefinite" />
                                  </circle>
                                  <circle cx="42.601" cy="42.663" r="5" fill-opacity="0" fill="${color}">
                                      <animate attributeName="fill-opacity"
                                          begin="0s" dur="1.3s"
                                          values="0;0;1;0;0;0;0;0" calcMode="linear"
                                          repeatCount="indefinite" />
                                  </circle>
                                  <circle cx="27" cy="49.125" r="5" fill-opacity="0" fill="${color}">
                                      <animate attributeName="fill-opacity"
                                          begin="0s" dur="1.3s"
                                          values="0;0;0;1;0;0;0;0" calcMode="linear"
                                          repeatCount="indefinite" />
                                  </circle>
                                  <circle cx="11.399" cy="42.663" r="5" fill-opacity="0" fill="${color}">
                                      <animate attributeName="fill-opacity"
                                          begin="0s" dur="1.3s"
                                          values="0;0;0;0;1;0;0;0" calcMode="linear"
                                          repeatCount="indefinite" />
                                  </circle>
                                  <circle cx="4.938" cy="27.063" r="5" fill-opacity="0" fill="${color}">
                                      <animate attributeName="fill-opacity"
                                          begin="0s" dur="1.3s"
                                          values="0;0;0;0;0;1;0;0" calcMode="linear"
                                          repeatCount="indefinite" />
                                  </circle>
                                  <circle cx="11.399" cy="11.462" r="5" fill-opacity="0" fill="${color}">
                                      <animate attributeName="fill-opacity"
                                          begin="0s" dur="1.3s"
                                          values="0;0;0;0;0;0;1;0" calcMode="linear"
                                          repeatCount="indefinite" />
                                  </circle>
                                  <circle cx="27" cy="5" r="5" fill-opacity="0" fill="${color}">
                                      <animate attributeName="fill-opacity"
                                          begin="0s" dur="1.3s"
                                          values="0;0;0;0;0;0;0;1" calcMode="linear"
                                          repeatCount="indefinite" />
                                  </circle>
                              </g>
                          </g>
                      </svg>
                  `,
        },
        {
          name: "tail-spin",
          svg: `
                      <svg width="20" viewBox="0 0 38 38" xmlns="http://www.w3.org/2000/svg" className="${classAttr}">
                          <defs>
                              <linearGradient x1="8.042%" y1="0%" x2="65.682%" y2="23.865%" id="a">
                                  <stop stop-color="${color}" stop-opacity="0" offset="0%"/>
                                  <stop stop-color="${color}" stop-opacity=".631" offset="63.146%"/>
                                  <stop stop-color="${color}" offset="100%"/>
                              </linearGradient>
                          </defs>
                          <g fill="none" fill-rule="evenodd">
                              <g transform="translate(1 1)">
                                  <path d="M36 18c0-9.94-8.06-18-18-18" id="Oval-2" stroke="url(#a)" stroke-width="3">
                                      <animateTransform
                                          attributeName="transform"
                                          type="rotate"
                                          from="0 18 18"
                                          to="360 18 18"
                                          dur="0.9s"
                                          repeatCount="indefinite" />
                                  </path>
                                  <circle fill="${color}" cx="36" cy="18" r="1">
                                      <animateTransform
                                          attributeName="transform"
                                          type="rotate"
                                          from="0 18 18"
                                          to="360 18 18"
                                          dur="0.9s"
                                          repeatCount="indefinite" />
                                  </circle>
                              </g>
                          </g>
                      </svg>
                  `,
        },
        {
          name: "three-dots",
          svg: `
                      <svg width="25" viewBox="0 0 120 30" xmlns="http://www.w3.org/2000/svg" fill="${color}" className="${classAttr}">
                          <circle cx="15" cy="15" r="15">
                              <animate attributeName="r" from="15" to="15"
                                      begin="0s" dur="0.8s"
                                      values="15;9;15" calcMode="linear"
                                      repeatCount="indefinite" />
                              <animate attributeName="fill-opacity" from="1" to="1"
                                      begin="0s" dur="0.8s"
                                      values="1;.5;1" calcMode="linear"
                                      repeatCount="indefinite" />
                          </circle>
                          <circle cx="60" cy="15" r="9" fill-opacity="0.3">
                              <animate attributeName="r" from="9" to="9"
                                      begin="0s" dur="0.8s"
                                      values="9;15;9" calcMode="linear"
                                      repeatCount="indefinite" />
                              <animate attributeName="fill-opacity" from="0.5" to="0.5"
                                      begin="0s" dur="0.8s"
                                      values=".5;1;.5" calcMode="linear"
                                      repeatCount="indefinite" />
                          </circle>
                          <circle cx="105" cy="15" r="15">
                              <animate attributeName="r" from="15" to="15"
                                      begin="0s" dur="0.8s"
                                      values="15;9;15" calcMode="linear"
                                      repeatCount="indefinite" />
                              <animate attributeName="fill-opacity" from="1" to="1"
                                      begin="0s" dur="0.8s"
                                      values="1;.5;1" calcMode="linear"
                                      repeatCount="indefinite" />
                          </circle>
                      </svg>
                  `,
        },
      ];

      icons.forEach((icon) => {
        if (cash(this).data("loading-icon") === icon.name) {
          cash(this).replaceWith(icon.svg);
        }
      });
    });
  }

  componentDidMount() {
    feather.replace({
      "stroke-width": 1.5,
    });
    document.title = "Register | " + process.env.REACT_APP_NAME;
    this.loadingIcon();
  }

  onCheck() {
    this.setState({
      loading_check: true,
    });

    let formData = {
      username: this.state.username,
      password: this.state.password,
    };

    axios
      .post(`${process.env.REACT_APP_APIURL}auth/login_spse`, formData)
      .then((response) => {
        let level = response.data.pegawai.idgroup ? response.data.pegawai.idgroup : response.data.pegawai.usrgroup;
        this.setState({
          ketemu: true,
          nama: response.data.pegawai.peg_nama,
          email: "",
          nohp: response.data.pegawai.peg_telepon,
          nip: response.data.pegawai.peg_nip,
          jabatan: response.data.pegawai.peg_jabatan,
          pangkat: response.data.pegawai.peg_pangkat,
          // level: response.data.pegawai.idgroup,
          id_spse: response.data.pegawai.peg_id,
          loading_check: false,
          levels: response.data.level.findIndex((e) => e.name === level),
        });
      })
      .catch((err) => {
        this.setState(
          {
            error: true,
            loading_check: false,
            error_message: err.response.data.message,
          },
          () => {
            Velocity(cash("#error_message"), "slideDown", {
              duration: 300,
              complete: function (el) {
                setTimeout(() => {
                  Velocity(cash(el), "slideUp");
                }, 5000);
              },
            });
          }
        );
      });
  }

  onSubmit() {
    this.setState({
      loading_check: true,
    });
    const formData = new FormData();
    formData.append("nama", this.state.nama);
    formData.append("username", this.state.username);
    formData.append("password", this.state.password);
    formData.append("nohp", this.state.nohp);
    formData.append("email", this.state.email);
    formData.append("jk", this.state.jk);
    formData.append("nip", this.state.nip);
    formData.append("jabatan", this.state.jabatan);
    formData.append("pangkat", this.state.pangkat);
    formData.append("level", this.state.levels);
    formData.append("id_spse", this.state.id_spse);
    formData.append("foto", this.state.foto);

    axios
      .post(`${process.env.REACT_APP_APIURL}auth/register`, formData)
      .then((response) => {
        this.props.history.push("/auth");
      })
      .catch((err) => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
        let pesan = err.response.data.results.message;
        this.setState(
          {
            error: true,
            loading_check: false,
            error_message: pesan,
          },
          () => {
            Velocity(cash("#error_message"), "fadeIn", {
              duration: 500,
              complete: function (el) {
                setTimeout(() => {
                  Velocity(cash("#error_message"), "slideUp", {
                    duration: 500,
                    complete: function (el) {
                      cash(el).removeClass("show");
                    },
                  });
                }, 5000);
              },
            });
          }
        );
      });
  }

  render() {
    return (
      <div id="signup">
        <h2 className="intro-x font-bold text-2xl xl:text-3xl text-center xl:text-left">Sign Up</h2>
        <div id="error_message" className={`rounded-md flex items-center px-5 py-4 mb-2 bg-theme-31 text-theme-6 ${this.state.error === true ? "" : "hidden"}`}>
          <i data-feather="alert-octagon" className="w-6 h-6 mr-2"></i>
          {this.state.error_message}
        </div>
        <div className="intro-x mt-2 text-gray-500 xl:hidden text-center">A few more clicks to sign in to your account. Manage all your packets in one place</div>
        <div className="intro-x mt-8">
          <input type="text" name="username" className="form-control" placeholder="Username pada SPSE" onChange={(e) => this.onChange(e)} />
          <input type="password" name="password" className="form-control mt-4" placeholder="Password pada SPSE" onChange={(e) => this.onChange(e)} />
          <button type="button" onClick={() => this.onCheck()} className="btn mr-1 mb-2 btn-primary mt-3">
            Check Login
            <div className={this.state.loading_check ? "" : "hidden"}>
              <i data-loading-icon="oval" data-color="white" className="w-4 h-4 ml-2"></i>
            </div>
          </button>
          <Link to="/auth" className={`btn xl:w-32 btn-outline-secondary mt-3 xl:mt-0 ${this.state.ketemu ? "hidden" : ""}`}>
            Sign in
          </Link>
          <div className={this.state.ketemu ? "" : "hidden"}>
            <input type="text" className="form-control mt-4" placeholder="NIP" onChange={(e) => this.onChange(e)} value={this.state.nip} name="nip" />
            <input type="text" className="form-control mt-4" placeholder="Nama Lengkap" onChange={(e) => this.onChange(e)} value={this.state.nama} name="nama" />
            <div className="flex flex-col sm:flex-row mt-4">
              <div className="flex items-center text-gray-700 mr-2">
                <input type="radio" className="input border mr-2" id="horizontal-radio-chris-evans" name="jk" value="L" onChange={(e) => this.onChange(e)} />
                <label className="cursor-pointer select-none" htmlFor="horizontal-radio-chris-evans">
                  Laki-laki
                </label>
              </div>
              <div className="flex items-center text-gray-700 mr-2 mt-2 sm:mt-0">
                <input type="radio" className="input border mr-2" id="horizontal-radio-liam-neeson" name="jk" value="P" onChange={(e) => this.onChange(e)} />
                <label className="cursor-pointer select-none" htmlFor="horizontal-radio-liam-neeson">
                  Perempuan
                </label>
              </div>
            </div>

            <input type="email" className="form-control mt-4" placeholder="Email" onChange={(e) => this.onChange(e)} value={this.state.email} name="email" />
            <input type="hidden" onChange={(e) => this.onChange(e)} value={this.state.id_spse} name="id_spse" />
            <input type="text" className="form-control mt-4" placeholder="No Whatsapp" onChange={(e) => this.onChange(e)} value={this.state.nohp} name="nohp" />
            <input
              type="file"
              className="form-control mt-4"
              placeholder="Foto"
              onChange={(e) => this.onChange(e)}
              // value={this.state.foto}
              name="foto"
            />
            <img src={this.state.file_foto} className="mt-4" width="150px" alt="foto" />
            <div className="intro-x flex items-center text-gray-700 mt-4 text-xs sm:text-sm">
              <input type="checkbox" className="input border mr-2" id="remember-me" name="setuju" onChange={(e) => this.onChange(e)} value={this.state.setuju} />
              <label className="cursor-pointer select-none" htmlFor="remember-me">
                Saya setuju dengan semua
              </label>
              <a className="text-theme-1 ml-1" href="/kebijakan">
                kebijakan pada system ini.
              </a>
              .
            </div>
            <div className="intro-x mt-5 xl:mt-8 text-center xl:text-left">
              <button
                className={`btn w-full xl:w-32 ${this.state.lengkap || this.state.loading_check ? "btn-primary" : "btn-secondary"} xl:mr-3`}
                onClick={() => this.onSubmit()}
                disabled={!this.state.lengkap || this.state.loading_check}
              >
                Register
                <div className={this.state.loading_check ? "" : "hidden"}>
                  <i data-loading-icon="oval" data-color="white" className="w-4 h-4 ml-2"></i>
                </div>
              </button>
              <Link to="/auth" className="btn w-full xl:w-32 btn-secondary mt-3 xl:mt-0">
                Sign in
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Register);
