import React, { Component } from "react";
import Cookies from "js-cookie";
import axios from "axios";
import { Link, withRouter } from "react-router-dom";
import NumberFormat from "react-number-format";
import feather from "feather-icons";
import Pristine from "pristinejs";
import Toastify from "toastify-js";
import cash from "cash-dom";
import TomSelect from "tom-select";
import Failed from "../master/Failed";

class PaketEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sumberdana: [],
      sumber_dana: [],
      kecamatan: [],
      lokasi: [],
      jenis_pekerjaan: [],
      jenispekerjaan: "",
      jenis_kontrak: [],
      kualifikasi: [],
      selected_kualifikasi: "",
      jeniskontrak: "",
      metode_pemilihan: [],
      metodepemilihan: "",
      sifat_pengadaan: [],
      satker: [],
      sifatpengadaan: "",
      nama_paket: "",
      rup_id: "",
      opd: "",
      myc: "0",
      opd_nama: "",
      pagu: 0,
      hps: 0,
      error_code: 0,
      proses: false,
      status: "",
    };
  }

  onChange(e) {
    const name = e.target.name;
    const tipe = e.target.type;
    let value = e.target.value;
    if (tipe === "select-multiple") {
      value = Array.from(e.target.selectedOptions, (option) => option.value);
    }

    this.setState({
      [name]: value,
    });
  }

  onValueChange(e) {
    this.setState({
      hps: e.value,
    });
  }

  tomselect() {
    cash(".tom-select").each(function () {
      let options = {
        plugins: {
          dropdown_input: {},
        },
      };

      if (cash(this).data("placeholder")) {
        options.placeholder = cash(this).data("placeholder");
      }

      if (cash(this).attr("multiple") !== undefined) {
        options = {
          ...options,
          plugins: {
            ...options.plugins,
            remove_button: {
              title: "Remove this item",
            },
          },
          closeAfterSelect: true,
          persist: false,
          create: true,
        };
      }

      if (cash(this).data("header")) {
        options = {
          ...options,
          plugins: {
            ...options.plugins,
            dropdown_header: {
              title: cash(this).data("header"),
            },
          },
        };
      }

      new TomSelect(this, options);
    });
  }

  componentDidMount() {
    axios
      .get(`${process.env.REACT_APP_APIURL}paket/detail/${this.props.match.params.id}`, {
        headers: { Authorization: "Bearer " + Cookies.get("auth-token") },
      })
      .then((response) => {
        this.setState(
          {
            status: response.data.paket.paket_status,
            jenispekerjaan: response.data.paket.paket_jenispekerjaan,
            sifatpengadaan: response.data.paket.paket_kompleks,
            jeniskontrak: response.data.paket.paket_kontrak,
            hps: response.data.paket.paket_hps,
            selected_kualifikasi: response.data.paket.paket_kualifikasi,
            lokasi: response.data.paket.paket_lokasi.toString().split(","),
            sumberdana: response.data.paket.paket_sumberdana.toString().split(","),
            kualifikasi: response.data.kualifikasi,
            sumber_dana: response.data.sumberdana,
            kecamatan: response.data.kecamatan,
            jenis_pekerjaan: response.data.jenis_pekerjaan,
            jenis_kontrak: response.data.jenis_kontrak,
            metode_pemilihan: response.data.metode_pemilihan,
            sifat_pengadaan: response.data.sifat_pengadaan,
            opd: response.data.paket.paket_skpd,
          },
          () => {
            this.tomselect();
          }
        );
        if (this.props.history.location.state == undefined) {
          this.setState({
            rup_id: response.data.paket.paket_rup,
            nama_paket: response.data.paket.paket_nama,
            pagu: response.data.paket.paket_pagu,
            opd_nama: response.data.satker.find((e) => e.stk_id == response.data.paket.paket_skpd).stk_nama,
            metodepemilihan: response.data.paket.paket_metode,
          });
        }
      });

    if (this.props.history.location.state != undefined) {
      axios
        .get(`${process.env.REACT_APP_APIURL}sirup/detail/${this.props.history.location.state.rup_id}`, {
          headers: { Authorization: "Bearer " + Cookies.get("auth-token") },
        })
        .then((response) => {
          this.setState({
            rup_id: response.data.sirup.id,
            nama_paket: response.data.sirup.nama,
            opd_nama: response.data.sirup.stk_nama,
            opd: response.data.sirup.stk_id,
            pagu: response.data.sirup.pagu,
            metodepemilihan: response.data.sirup.metode_pengadaan,
          });
        });
    }

    feather.replace({
      "stroke-width": 1.5,
    });
  }

  onSubmit() {
    let pris;
    cash(".validate-form").each(function () {
      pris = new Pristine(this, {
        classTo: "input-form",
        errorClass: "has-error",
        errorTextParent: "input-form",
        errorTextClass: "text-primary-3 mt-2",
      });
    });
    if (pris.validate()) {
      this.setState({
        proses: true,
      });
      let formData = {
        id: this.props.match.params.id,
        paket_nama: this.state.nama_paket,
        paket_pagu: this.state.pagu,
        paket_hps: this.state.hps,
        paket_lokasi: this.state.lokasi,
        paket_jenispekerjaan: this.state.jenispekerjaan,
        paket_metode: this.state.metodepemilihan,
        // paket_melalui: this.state.melalui, // untuk PL lgsg atau ukpbj
        // paket_pp: this.state.melalui, // untuk PL lgsg atau ukpbj
        paket_ppk: Cookies.get("user_spse"),
        paket_sumberdana: this.state.sumberdana,
        paket_skpd: this.state.opd,
        paket_kontrak: this.state.jeniskontrak,
        paket_status: this.state.status,
        paket_tahun: Cookies.get("tahun"),
        paket_myc: this.state.myc,
        paket_rup: this.state.rup_id,
        paket_kompleks: this.state.sifatpengadaan,
        paket_kualifikasi: this.state.selected_kualifikasi,
        paket_database: "1",
        username: Cookies.get("username"),
      };

      let url;
      url = process.env.REACT_APP_APIURL + "paket/update";

      axios
        .post(`${url}`, formData, {
          headers: { Authorization: "Bearer " + Cookies.get("auth-token") },
        })
        .then((response) => {
          this.props.history.push("/paket");
        })
        .catch((error) => {
          this.setState({
            error_code: error.response.data.error,
          });
          Toastify({
            node: cash("#error-notification-content").clone().removeClass("hidden")[0],
            duration: 5000,
            newWindow: true,
            close: true,
            gravity: "top",
            position: "right",
            stopOnFocus: true,
          }).showToast();
        });
    } else {
      Toastify({
        node: cash("#failed-notification-content").clone().removeClass("hidden")[0],
        duration: 3000,
        newWindow: true,
        close: true,
        gravity: "top",
        position: "right",
        stopOnFocus: true,
      }).showToast();
      feather.replace({
        "stroke-width": 1.5,
      });
    }
  }

  render() {
    return (
      <>
        <div className="intro-y flex flex-col sm:flex-row items-center mt-8">
          <h2 className="text-lg font-medium mr-auto">Edit Paket</h2>
        </div>
        <div className="intro-y box mt-5 py-10 px-5 sm:px-5">
          <form name="pengajuan" className="validate-form" method="post">
            <div className="font-medium text-base">Data Paket</div>
            <div className="flex grid grid-cols-12 gap-4 row-gap-5 items-center">
              <div className="mt-3 col-span-12 sm:col-span-6">
                <label>Nama Paket</label>
                <div className="grid grid-cols-12 gap-4 row-gap-5 input-form">
                  <div className="relative mt-2 col-span-10">
                    <input type="text" className="form-control" placeholder="belum pilih" name="nama_paket" value={this.state.nama_paket} readOnly required />
                  </div>
                  <div className="relative col-span-2 mt-2">
                    <Link to={{ pathname: "/paket/pilih_rup", state: { prevPath: "edit", paket_id: this.props.match.params.id } }} className="btn w-24 btn-primary">
                      Pilih RUP
                    </Link>
                  </div>
                </div>
              </div>
              <div className="mt-3 col-span-12 sm:col-span-6 input-form">
                <label>OPD</label>
                <div className="relative mt-2">
                  <input type="text" className="form-control" placeholder="belum pilih" name="opd" value={this.state.opd_nama} readOnly required />
                </div>
              </div>
              <div className="col-span-12 sm:col-span-4 input-form">
                <label>Pagu</label>
                <div className="relative mt-2">
                  <NumberFormat className="form-control" value={this.state.pagu} displayType={"input"} thousandSeparator={true} decimalScale={0} readOnly required />
                </div>
              </div>
              <div className="col-span-12 sm:col-span-4 input-form">
                <label>HPS</label>
                <div className="relative mt-2">
                  <NumberFormat
                    name="hps"
                    className="form-control"
                    value={this.state.hps}
                    displayType={"input"}
                    thousandSeparator={true}
                    decimalScale={0}
                    minLength="5"
                    onValueChange={(e) => this.onValueChange(e)}
                    required
                  />
                </div>
              </div>
              <div className="col-span-12 sm:col-span-4 input-form">
                <label>Sumber Dana</label>
                <div className="relative mt-2">
                  <select className="tom-select w-full" name="sumberdana" value={this.state.sumberdana} multiple onChange={(e) => this.onChange(e)} required>
                    <option value="">Pilih Sumber Dana</option>
                    {this.state.sumber_dana.map((e, idx) => {
                      return (
                        <option key={idx} value={e.sd_id}>
                          {e.sd_nama}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              <div className="col-span-12 sm:col-span-4 input-form">
                <label>Lokasi Pekerjaan</label>
                <div className="relative mt-2">
                  <select
                    className="tom-select w-full"
                    name="lokasi"
                    multiple
                    value={this.state.lokasi}
                    onChange={(e) => this.onChange(e)}
                    placeholder="Pilih Lokasi"
                    required
                  >
                    {this.state.kecamatan.map((e, idx) => {
                      return (
                        <option key={idx} value={e.kecamatan_id}>
                          {e.kecamatan_nama}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              <div className="col-span-12 sm:col-span-4 input-form">
                <label>Jenis Pekerjaan</label>
                <div className="relative mt-2">
                  <select className="form-select" name="jenispekerjaan" value={this.state.jenispekerjaan} required onChange={(e) => this.onChange(e)}>
                    <option value="">Pilih Jenis Pekerjaan</option>
                    {this.state.jenis_pekerjaan.map((e, idx) => {
                      return (
                        <option key={idx} value={e.id}>
                          {e.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              <div className="col-span-12 sm:col-span-4 input-form">
                <label>Kualifikasi</label>
                <div className="relative mt-2">
                  <select className="form-select" name="selected_kualifikasi" value={this.state.selected_kualifikasi} required onChange={(e) => this.onChange(e)}>
                    <option value="">Pilih Kualifikasi</option>
                    {this.state.kualifikasi.map((e, idx) => {
                      return (
                        <option key={idx} value={idx}>
                          {e}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              <div className="col-span-12 sm:col-span-6 input-form">
                <label>Jenis Kontrak</label>
                <div className="relative mt-2">
                  <select className="form-select" name="jeniskontrak" value={this.state.jeniskontrak} onChange={(e) => this.onChange(e)} required>
                    <option value="">Pilih Jenis Kontrak</option>
                    {this.state.jenis_kontrak.map((e, idx) => {
                      return (
                        <option key={idx} value={e.id}>
                          {e.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              <div className="col-span-12 sm:col-span-6 input-form">
                <label>Metode Pemilihan</label>
                <div className="relative mt-2">
                  <select className="form-select" name="metodepemilihan" value={this.state.metodepemilihan} value={this.state.metodepemilihan} readOnly required>
                    <option value="">Pilih Metode</option>
                    {this.state.metode_pemilihan.map((e, idx) => {
                      return (
                        <option key={idx} value={e.id}>
                          {e.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              <div className="col-span-12 sm:col-span-6 input-form">
                <label>Sifat Pengadaan</label>
                <div className="relative mt-2">
                  <select className="form-select" name="sifatpengadaan" value={this.state.sifatpengadaan} onChange={(e) => this.onChange(e)} required>
                    <option value="">Pilih Sifat Pengadaan</option>
                    {this.state.sifat_pengadaan.map((e, idx) => {
                      return (
                        <option key={idx} value={e.id}>
                          {e.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              <div className="col-span-12 sm:col-span-6 input-form">
                <label>Tahun Jamak / MYC</label>
                <div className="relative mt-2">
                  <select className="form-select " name="myc" value={this.state.myc} onChange={(e) => this.onChange(e)} required>
                    <option value="0">Bukan</option>
                    <option value="1">Ya</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="intro-y col-span-12 flex items-center justify-center sm:justify-end mt-5">
              <button
                type="button"
                onClick={() => this.onSubmit()}
                className={`btn w-24 justify-center block ${this.state.proses ? "bg-gray-200" : "bg-theme-1"} text-white ml-2`}
                disabled={this.state.proses}
              >
                Simpan
              </button>
            </div>
          </form>
          <div id="error-notification" className="p-5">
            <div className="preview">
              <div className="text-center">
                <div id="error-notification-content" className="toastify-content hidden flex">
                  <i className={`text-theme-6`} data-feather="x"></i>
                  <div className="ml-4 mr-4">
                    <div className="font-medium">Error {this.state.error_code}!</div>
                    <div className="text-gray-600 mt-1">Paket gagal diupdate.</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Failed />
        </div>
      </>
    );
  }
}

export default withRouter(PaketEdit);
