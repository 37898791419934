import React, { Component } from "react";
import Cookies from "js-cookie";
import feather from "feather-icons";
import moment from "moment";
import axios from "axios";
import cash from "cash-dom";

class Chat extends Component {
  constructor(props) {
    super(props);
    this.textInput = React.createRef();
    this.state = {
      pesan: "",
      attachment: null,
      tombol: false,
      tinggi: 400,
    };
  }

  onChange(e) {
    const name = e.target.name;
    const tipe = e.target.type;
    let value = e.target.value;

    if (this.state.pesan.length > 2) {
      this.setState({
        tombol: true,
      });
    } else {
      this.setState({
        tombol: false,
      });
    }
    if (tipe === "file") {
      value = e.target.files[0];
    }

    this.setState({
      [name]: value,
    });
  }

  componentDidMount() {
    document.getElementById("chat_div").scrollTop = document.getElementById("chat_div").scrollHeight;
    let kiri = document.querySelector("#kiri");
    this.setState({ kiri: kiri.offsetHeight });
  }

  kirim() {
    const formData = new FormData();
    formData.append("paket_id", this.props.paket.paket_id);
    formData.append("tahun", this.props.paket.paket_tahun);
    formData.append("user", Cookies.get("username"));
    formData.append("panitia", Cookies.get("level"));
    formData.append("pesan", this.state.pesan);
    formData.append("attachment", this.state.attachment);
    axios
      .post(`${process.env.REACT_APP_APIURL}chat/kirim`, formData, {
        headers: { Authorization: "Bearer " + Cookies.get("auth-token") },
      })
      .then((response) => {
        let chat = this.props.chat;
        chat.push({
          chat_date: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
          chat_file: this.state.attachment,
          chat_id: response.data.insertId,
          chat_paket: this.state.paket,
          chat_panitia: Cookies.get("level"),
          chat_text: this.state.pesan,
          chat_user: Cookies.get("username"),
          user_image: Cookies.get("foto"),
          user_level: Cookies.get("level"),
          user_nama: Cookies.get("nama"),
        });
        this.setState(
          {
            chat: chat,
            pesan: "",
            attachment: "",
            tombol: false,
          },
          () => {
            document.getElementById("chat_div").scrollTop = document.getElementById("chat_div").scrollHeight;
          }
        );
      });
  }

  lampiran(id) {
    return (
      <span>
        &nbsp; | <a href={`${process.env.REACT_APP_APIURL}chat/download_file/${id}`}>Lampiran </a>
      </span>
    );
  }

  reply(o, chat_id) {
    o.preventDefault();
    this.setState({
      pesan: `#${chat_id}# `,
    });
    this.textInput.current.focus();
  }

  quoteReply(e, theme) {
    const RawHTML = ({ children, className = "" }) => <div className={className} dangerouslySetInnerHTML={{ __html: children.replace(/\n/g, "<br />") }} />;
    let ketemu = e.match(/#\d+#/g);
    if (ketemu) {
      let id_chat = ketemu[0].replace(/#/g, "");
      let chat = this.props.chat.find((a) => a.chat_id == id_chat);
      return (
        <div className={`border-l-2 border-theme-${theme} pl-4 mb-2`}>
          <a href="#" onClick={(o) => this.goTo(o, id_chat)} className="font-medium">
            {chat.chat_user == Cookies.get("username") ? chat.chat_user : Cookies.get("level") == 4 ? (chat.chat_panitia == "5" ? "Pokja" : "UKPBJ") : chat.chat_user} menulis:
          </a>
          <div className="text-gray-600">
            <RawHTML>{chat.chat_text.replace(/#\d+#/g, "").substring(0, 50)}</RawHTML>
          </div>
        </div>
      );
    }
  }

  goTo(o, i) {
    o.preventDefault();
    const anchor = document.getElementById(`${i}`);
    anchor.scrollIntoView({ behavior: "smooth", block: "start" });
  }

  onPrevent(e) {
    e.preventDefault();
  }

  render() {
    const RawHTML = ({ children, className = "" }) => <div className={className} dangerouslySetInnerHTML={{ __html: children.replace(/\n/g, "<br />") }} />;
    return (
      <div className="intro-y col-span-12 lg:col-span-8 2xl:col-span-9">
        <div className="chat__box box" style={{ height: this.state.kiri + "px" }}>
          <div className="h-full flex flex-col">
            <div className="flex flex-col sm:flex-row border-b border-gray-200 px-5 py-4">
              <div className="flex items-center">
                <div className="w-10 h-10 sm:w-12 sm:h-12 flex-none image-fit relative">
                  <img alt="Midone Tailwind HTML Admin Template" className="rounded-full" src="dist/images/chat.png" />
                </div>
                <div className="ml-3 mr-auto">
                  <div className="font-medium text-base">Percakapan</div>
                  <div className="text-gray-600 text-xs sm:text-sm">Berikut adalah isi seluruh percakapan pada paket ini...</div>
                </div>
              </div>
              <div className="flex items-center sm:ml-auto mt-5 sm:mt-0 border-t sm:border-0 border-gray-200 pt-3 sm:pt-0 -mx-5 sm:mx-0 px-5 sm:px-0">
                <a href="#" className="w-5 h-5 text-gray-500" onClick={(e) => this.onPrevent(e)}>
                  <i data-feather="printer" className="w-5 h-5"></i>
                </a>
                <a href="#" className="w-5 h-5 text-gray-500 ml-5" onClick={(e) => this.onPrevent(e)}>
                  <i data-feather="user-plus" className="w-5 h-5"></i>
                </a>
                <div className="dropdown ml-auto sm:ml-3">
                  <a href="#" className="dropdown-toggle w-5 h-5 text-gray-600" onClick={(e) => this.onPrevent(e)}>
                    <i data-feather="more-vertical" className="w-5 h-5"></i>
                  </a>
                  <div className="dropdown-menu w-40">
                    <div className="dropdown-menu__content box dark:bg-dark-1 p-2">
                      <a href="" className="flex items-center block p-2 transition duration-300 ease-in-out bg-white hover:bg-gray-200 rounded-md">
                        <i data-feather="share-2" className="w-4 h-4 mr-2"></i> Share Contact
                      </a>
                      <a href="" className="flex items-center block p-2 transition duration-300 ease-in-out bg-white hover:bg-gray-200 rounded-md">
                        <i data-feather="settings" className="w-4 h-4 mr-2"></i> Settings
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="overflow-y-scroll px-5 pt-5 flex-1" id="chat_div">
              {this.props.chat.map((e, index) => {
                if (e.user_level !== Cookies.get("level") && (Cookies.get("level") === "4" || e.user_level === "4")) {
                  return (
                    <div key={index} id={e.chat_id}>
                      <div className="chat__box__text-box flex items-end float-left mb-4 lg:max-w-3/4 md:max-w-3/4 sm:max-w-3/4">
                        <div className="w-10 h-10 hidden sm:block flex-none image-fit relative mr-5">
                          <img
                            alt={Cookies.get("level") == 4 ? "UKPBJ" : e.user_nama}
                            className="rounded-full"
                            src={`${process.env.REACT_APP_APIURL}uploads/${Cookies.get("level") == 4 ? (e.user_jk == "L" ? "avatar5.png" : "female2.png") : e.user_image}`}
                          />
                        </div>
                        <div className="bg-gray-200 px-4 py-3 text-gray-700 rounded-r-md rounded-t-md">
                          {this.quoteReply(e.chat_text, 12)}
                          <RawHTML>{e.chat_text.replace(/#\d+#/g, "")}</RawHTML>
                          <div className="mt-1 text-xs text-gray-600">
                            {Cookies.get("level") == 4 ? (e.chat_panitia == 5 ? "Pokja" : "UKPBJ") : e.user_nama} |{" "}
                            {moment().diff(moment(e.chat_date), "days") > 6 ? moment(e.chat_date).format("LLLL") : moment(e.chat_date).fromNow()}
                            {e.chat_file !== null ? this.lampiran(e.chat_id) : ""}
                          </div>
                        </div>
                        <div className="hidden sm:block dropdown relative ml-3 my-auto">
                          <a href="#" onClick={(o) => this.reply(o, e.chat_id)} className="w-4 h-4 text-gray-500">
                            <i data-feather="corner-up-left" className="w-4 h-4"></i>
                          </a>
                        </div>
                      </div>
                      <div className="clear-both"></div>
                    </div>
                  );
                } else {
                  return (
                    <div key={index} id={e.chat_id}>
                      <div className="chat__box__text-box flex items-end float-right mb-4 lg:max-w-3/4 md:max-w-3/4 sm:max-w-3/4">
                        <div className="hidden sm:block dropdown relative mr-3 my-auto">
                          <a href="#" onClick={(o) => this.reply(o, e.chat_id)} className="w-4 h-4 text-gray-500">
                            <i data-feather="corner-up-right" className="w-4 h-4"></i>
                          </a>
                        </div>
                        <div className="bg-theme-1 px-4 py-3 text-white rounded-l-md rounded-t-md">
                          {this.quoteReply(e.chat_text, 12)}
                          <RawHTML>{e.chat_text.replace(/#\d+#/g, "")}</RawHTML>
                          <div className="mt-1 text-xs text-theme-25">
                            {e.user_nama} | {moment().diff(moment(e.chat_date), "days") > 6 ? moment(e.chat_date).format("LLLL") : moment(e.chat_date).fromNow()}
                            {e.chat_file !== null ? this.lampiran(e.chat_id) : ""}
                          </div>
                        </div>
                        <div className="w-10 h-10 hidden sm:block flex-none image-fit relative ml-5">
                          <img alt={e.user_nama} className="rounded-full" src={`${process.env.REACT_APP_APIURL}uploads/${e.user_image}`} />
                        </div>
                      </div>
                      <div className="clear-both"></div>
                    </div>
                  );
                }
              })}
            </div>
            <div className="pt-4 pb-10 sm:py-4 flex items-center border-t border-gray-200">
              <textarea
                className="chat__box__input form-control dark:bg-dark-3 h-16 resize-none border-transparent px-5 py-3 shadow-none focus:ring-0"
                rows="1"
                placeholder="Type your message..."
                name="pesan"
                value={this.state.pesan}
                onChange={(e) => this.onChange(e)}
                ref={this.textInput}
              />
              <div className="flex absolute sm:static left-0 bottom-0 ml-5 sm:ml-0 mb-5 sm:mb-0">
                <div className="w-4 h-4 sm:w-5 sm:h-5 relative text-gray-500 mr-3 sm:mr-5">
                  <i data-feather="paperclip" className="w-full h-full"></i>
                  <input type="file" className="w-full h-full top-0 left-0 absolute opacity-0" name="attachment" onChange={(e) => this.onChange(e)} />
                </div>
              </div>
              <button
                onClick={() => this.kirim()}
                disabled={!this.state.tombol}
                className={`w-8 h-8 sm:w-10 sm:h-10 block bg-${
                  this.state.tombol ? "theme-1" : "gray-200"
                } text-white rounded-full flex-none flex items-center justify-center mr-5`}
              >
                <i data-feather="send" className="w-4 h-4"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Chat;
