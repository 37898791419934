import React from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import "../css/app.css";
import "../css/tambahan.css";

import MenuMobile from "./MenuMobile";
import Menu from "./Menu";
import Topbar from "./topbar";

import Dashboard from "../dashboard/index";
import Login from "../login/index";
import RouteLink, { route_extra } from "../../Route";

import { ThemeContext } from "./theme-context";

function App() {
  if (window.location.hash === "#/auth" || window.location.hash === "#/register") {
    document.body.classList.remove("main");
    document.body.classList.add("login");
    return (
      <div>
        <Login />
      </div>
    );
  } else {
    document.body.classList.remove("login");
    document.body.classList.add("main");
    return (
      <>
        <MenuMobile />
        <div className="flex">
          <Menu />
          <div className="content">
            <Topbar />
            <Switch>
              {RouteLink.map((e, index) => {
                if (e.subMenus != undefined) {
                  return e.subMenus.map((subMenu, i) => {
                    return <Route path={subMenu.path} exact component={subMenu.component} key={subMenu.path} />;
                  });
                } else return <Route exact path={e.path} component={e.component} key={e.path} />;
              })}
              {route_extra.map((e, index) => {
                return <Route exact path={e.path} component={e.component} key={e.path} />;
              })}
              <Route component={Dashboard} />
            </Switch>
          </div>
        </div>
        {Switcher()}
      </>
    );
  }
}

function Switcher() {
  const { theme, toggle, dark } = React.useContext(ThemeContext);
  if (process.env.REACT_APP_DARKMODE === "ON") {
    return (
      <div
        onClick={toggle}
        className="dark-mode-switcher cursor-pointer shadow-md fixed bottom-0 right-0 box dark:bg-dark-2 border rounded-full w-40 h-12 flex items-center justify-center z-50 mb-10 mr-10"
      >
        <div className="mr-4 text-gray-700 dark:text-gray-300">Dark Mode</div>
        <div className={`${theme} dark-mode-switcher__toggle border`}></div>
      </div>
    );
  }
}

export default withRouter(App);
