import React, { Component } from "react";
import { status } from "../../Definisi";
import Modal from "../master/Modal";

class ModalEditStatus extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stats: "",
      new_status: "",
    };
  }

  componentWillUnmount() {
    this.setState({
      new_status: "",
    });
  }

  componentDidMount() {}

  ubahStatus(status) {
    this.setState({
      new_status: parseInt(status.target.value),
    });
  }

  render() {
    return (
      <Modal>
        <div className="modal" id="edit_status">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="flex items-center px-5 py-5 sm:py-3 border-b border-gray-200">
                <h2 className="font-medium text-base mr-auto">Edit Status</h2>
              </div>
              <div className="p-5">
                <div className="col-span-12 ">
                  <label>Pilih Status</label>
                  <select
                    className="form-select mt-2 sm:mr-2"
                    name="stats"
                    placeholder="Pilih Status"
                    onChange={(e) => this.ubahStatus(e)}
                    value={this.state.new_status}
                    required
                  >
                    <option value="">Pilih Status</option>
                    {status.map((e) => {
                      return (
                        <option value={`${e.id}`} key={e.id}>
                          {e.label}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              <div className="px-5 py-3 text-right border-gray-200">
                <button type="button" className="btn btn-secondary w-20 mr-1" data-dismiss="modal">
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn btn-primary w-20"
                  onClick={() => this.props.updateStatus(this.state.new_status)}
                  disabled={this.state.new_status === "" ? true : false}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

export default ModalEditStatus;
