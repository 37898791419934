export const warna_grafik = [
  "#285FD3", //1
  "#FF8B26", //2
  "#FFC533", //3
  "#BCBABA", //4
  "#7CB5EC", //5
  "#FF7599", //6
  "#8085E9", //7
  "#434348", //8
  "#90ED7D", //9
  "#91C714", //10
  "#44A9A8", //11
  "#F45B5B", //12
  "#D32929", //13
  "#BFB095", //14
  "#9C8FFF", //15
];

export const warna_label = ["bg-theme-1", "bg-theme-11", "bg-theme-12"];

export const pembulatan = function (angka) {
  if (angka > 1000000000000) return (angka / 1000000000000).toFixed(2) + " T";
  else if (angka > 1000000000) return (angka / 1000000000).toFixed(2) + " M";
  else if (angka > 1000000) return (angka / 1000000).toFixed(2) + " jt";
  else return angka;
};

export const status = [
  { id: -2, label: "Draft", color: "border dark:border-dark-5" },
  { id: -1, label: "Batal Diajukan", color: "bg-theme-6 text-white" },
  { id: 0, label: "Dikembalikan", color: "border border-theme-6 text-theme-6 dark:border-theme-6" },
  { id: 1, label: "Pengajuan", color: "bg-theme-8 border text-gray-700" },
  {
    id: 2,
    label: "Dokumen blm Lengkap",
    color: "border border-theme-6 text-theme-6 dark:border-theme-6",
  },
  {
    id: 3,
    label: "Dokumen Lengkap",
    color: "border border-theme-9 text-theme-9 dark:border-theme-9 mr-1",
  },
  // { id: 4, label: "Penunjukan Pokja", color: "bg-gray-200 text-gray-600" },
  { id: 5, label: "SK Dibuat", color: "bg-theme-30 text-white" },
  { id: 6, label: "Reviu", color: "bg-theme-12 text-white" },
  { id: 7, label: "PPK Sepakat", color: "bg-theme-9 text-white" },
  { id: 8, label: "Pokja Sepakat", color: "bg-green-600 text-white" },
  // {
  //   id: 9,
  //   label: "Pengajuan Ke Agency",
  //   color: "bg-theme-9 text-white",
  // },
  // { id: 10, label: "Agency Buat Paket", color: "bg-theme-9 text-white" },
  {
    id: 11,
    label: "Proses Tender",
    color: "bg-blue-600 text-white",
  },
  { id: 12, label: "Tender Gagal", color: "bg-theme-6 text-white" },
  { id: 13, label: "Tender Ulang", color: "bg-theme-11 text-white" },
  { id: 14, label: "Selesai", color: "bg-theme-1 text-white" },
  { id: 15, label: "Pengajuan Ulang", color: "border border-theme-1 text-theme-1" },
  // { id: 16, label: "Upload Kontrak", color: "bg-theme-1 text-white" },
  // { id: 17, label: "Upload BAST", color: "bg-theme-1 text-white" },
];

export const tombol_action = [
  { label: "Ajukan", color: "bg-theme-8 border text-gray-700", action: "pengajuan", icon: "navigation", user_level: ["4"] }, //0
  { label: "Batal", color: "bg-theme-6 border text-white", action: "batal", icon: "x", user_level: ["0", "4"] }, //1
  { label: "Dokumen Lengkap", color: "bg-theme-9 border text-white", action: "lengkap", icon: "check", user_level: ["2"] }, //2
  { label: "Dokumen Tidak Lengkap", color: "bg-theme-11 border text-white", action: "tdk_lengkap", icon: "x", user_level: ["2"] }, //3
  { label: "Tunjuk Pokja", color: "btn-dark", action: "tunjuk_pokja", icon: "edit", user_level: ["1"] }, //4
  { label: "Edit Pokja", color: "btn-dark", action: "edit_pokja", icon: "edit", user_level: ["1"] }, //5
  { label: "Reviu", color: "bg-theme-12", action: "reviu", icon: "book-open", user_level: ["5", "6"] }, //6
  { label: "PPK Sepakat", color: "bg-theme-9 border text-white", action: "ppk_sepakat", icon: "thumbs-up", user_level: ["4"] }, //7
  { label: "Pokja Sepakat", color: "bg-theme-9 border text-white", action: "pokja_sepakat", icon: "thumbs-up", user_level: ["5"] }, //8
  { label: "Tidak Sepakat", color: "btn-danger", action: "tidak_sepakat", icon: "thumbs-down", user_level: ["4", "5", "6"] }, //9
  { label: "Proses", color: "bg-blue-600 border text-white", action: "proses", icon: "play", user_level: ["5"] }, //10
  { label: "Evaluasi", color: "bg-theme-11 border text-white", action: "evaluasi", icon: "edit", user_level: ["0", "1", "5"] }, //11
  { label: "Tender Gagal", color: "bg-theme-6 border text-white", action: "tender_gagal", icon: "x", user_level: ["0", "5"] }, //12
  { label: "Tender Ulang", color: "bg-theme-11 border text-white", action: "tender_ulang", icon: "rotate-ccw", user_level: ["0", "5"] }, //13
  { label: "Selesai", color: "btn-dark", action: "selesai", icon: "check", user_level: ["5"] }, //14
  { label: "Ajukan Lagi", color: "bg-theme-8 border text-gray-700", action: "pengajuan_ulang", icon: "navigation", user_level: ["4"] }, //15
  { label: "Upload File", color: "text-white bg-theme-1", action: "upload", icon: "upload", user_level: ["0", "4"] }, //16
  { label: "Tunjuk PP", color: "btn-dark", action: "tunjuk_pp", icon: "edit", user_level: ["1"] }, //17
  { label: "Edit PP", color: "btn-dark", action: "edit_pp", icon: "edit", user_level: ["0", "1"] }, //18
  { label: "PP Sepakat", color: "bg-theme-9 border text-white", action: "pokja_sepakat", icon: "thumbs-up", user_level: ["5", "6"] }, //19
];

export const tombol_status = [
  { id: -2, tombol: [16, 0, 1] }, //draft
  { id: -1, tombol: [16, 0] }, //batal diajukan
  { id: 0, tombol: [16, 0] }, // Dikembalikan
  { id: 1, tombol: [16, 1, 2, 3] }, //pengajuan
  { id: 2, tombol: [16, 1, 15] }, // Dokumen tidak lengkap
  { id: 3, tombol: [16, 1, 4, 17] }, // Dokumen Lengkap
  { id: 5, tombol: [16, 5, 18, 6] }, // SK Dibuat
  { id: 6, tombol: [16, 5, 7, 9] }, // Reviu
  { id: 7, tombol: [16, 6, 8, 9, 19] }, // ppk sepakat
  { id: 8, tombol: [16, 6, 9, 10] }, //pokja sepakat
  { id: 11, tombol: [16, 11] }, //evaluasi
  { id: 12, tombol: [16, 13] }, // tender gagal
  { id: 13, tombol: [16, 11] }, //tender ulang
];

export const metode_pengadaan_sirup = [
  { id: 13, name: "Tender" },
  { id: 14, name: "Tender Cepat" },
  { id: 15, name: "Seleksi" },
  { id: 16, name: "Dikecualikan" },
  { id: 7, name: "Penunjukan Langsung" },
  { id: 8, name: "Pengadaan Langsung" },
  { id: 9, name: "E-Purchasing" },
];
