import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import cash from "cash-dom";
import feather from "feather-icons";
import Cookies from "js-cookie";
import Velocity from "velocity-animate";

import RouteLink from "../../Route";

class MenuMobile extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onClick = (e) => {
    e.preventDefault();
  };

  tutup() {
    Velocity(cash(".mobile-menu").parent().find("ul").first(), "slideUp", {
      duration: 300,
      complete: function (el) {
        cash(this).removeClass("menu__sub-open");
      },
    });
  }

  componentDidMount() {
    cash("#mobile-menu-toggler").on("click", function () {
      if (cash(".mobile-menu").find("ul").first()[0].offsetParent !== null) {
        Velocity(cash(".mobile-menu").find("ul").first(), "slideUp");
      } else {
        Velocity(cash(".mobile-menu").find("ul").first(), "slideDown");
      }
    });

    cash(".mobile-menu")
      .find(".menu")
      .on("click", function () {
        if (cash(this).parent().find("ul").length) {
          if (cash(this).parent().find("ul").first()[0].offsetParent !== null) {
            cash(this).find(".menu__sub-icon").removeClass("transform rotate-180");
            Velocity(cash(this).parent().find("ul").first(), "slideUp", {
              duration: 300,
              complete: function (el) {
                cash(this).removeClass("menu__sub-open");
              },
            });
          } else {
            cash(this).find(".menu__sub-icon").addClass("transform rotate-180");
            Velocity(cash(this).parent().find("ul").first(), "slideDown", {
              duration: 300,
              complete: function (el) {
                cash(this).addClass("menu__sub-open");
              },
            });
          }
        }
      });
    feather.replace({
      "stroke-width": 1.5,
    });
  }

  render() {
    return (
      <div className="mobile-menu md:hidden">
        <div className="mobile-menu-bar">
          <Link to="/" className="flex mr-auto">
            <img alt="SISINFO" className="w-6" src="dist/images/logo.svg" />
          </Link>
          <a href="#" id="mobile-menu-toggler" onClick={this.onClick}>
            <i data-feather="bar-chart-2" className="w-8 h-8 text-white transform -rotate-90"></i>
          </a>
        </div>
        <ul className="border-t border-theme-24 py-5 hidden">
          {RouteLink.map((e, index) => {
            if (e.path == "pemisah") {
              return <li className="menu__devider my-6" key={index}></li>;
            } else if (e.subMenus == undefined) {
              if (e.auth.includes("-1") || e.auth.includes(Cookies.get("level"))) {
                return (
                  <li key={index}>
                    <Link to={e.path} className="menu" onClick={() => this.tutup()}>
                      <div className="menu__icon">
                        <i data-feather={e.icon}></i>
                      </div>
                      <div className="menu__title"> {e.name} </div>
                    </Link>
                  </li>
                );
              }
            } else {
              if (e.auth.includes("-1") || e.auth.includes(Cookies.get("level"))) {
                return (
                  <li key={index}>
                    <a href="#" onClick={this.onClick} className="menu">
                      <div className="menu__icon">
                        <i data-feather={e.icon}></i>
                      </div>
                      <div className="menu__title">
                        {e.name}
                        <i data-feather="chevron-down" className="menu__sub-icon"></i>
                      </div>
                    </a>
                    <ul className="">
                      {e.subMenus.map((subMenu, i) => {
                        if (subMenu.auth.includes("-1") || subMenu.auth.includes(Cookies.get("level"))) {
                          return (
                            <li key={i}>
                              <Link to={subMenu.path} className="menu" onClick={() => this.tutup()}>
                                <div className="menu__icon">
                                  <i data-feather={subMenu.icon}></i>
                                </div>
                                <div className="menu__title"> {subMenu.name} </div>
                              </Link>
                            </li>
                          );
                        }
                      })}
                    </ul>
                  </li>
                );
              }
            }
          })}
        </ul>
      </div>
    );
  }
}

export default withRouter(MenuMobile);
