import React, { Component } from "react";
import Cookies from "js-cookie";
import axios from "axios";
import Modal from "../master/Modal";
import cash from "cash-dom";

class ModalPersetujuanAuditor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      proses: false,
    };
  }

  setuju() {
    let url = process.env.REACT_APP_APIURL + "users/setuju_auditor";

    axios
      .post(
        `${url}`,
        { paket_id: this.props.paket_id },
        {
          headers: { Authorization: "Bearer " + Cookies.get("auth-token") },
        }
      )
      .then((response) => {
        this.props.setujuAudit();
        cash("#persetujuanauditor").modal("hide");
        // this.props.trigger();
      });
  }

  render() {
    return (
      <Modal>
        <div className="modal" id="persetujuanauditor">
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="p-5 text-center">
                <i data-feather="help-circle" className="w-16 h-16 text-theme-6 mx-auto mt-3"></i>
                <div className="text-3xl mt-5 ">Persetujuan</div>
                <div className="text-gray-600 mt-2">
                  <ol>
                    <li>1. Berperan secara aktif dalam upaya pencegahan dan pemberantasan Korupsi, Kolusi, dan Nepotisme;</li>
                    <li>2. Bersikap transparan, jujur, obyektif, dan akuntabel dalam melaksanakan tugas;</li>
                    <li>3. Bertanggung jawab terhadap seluruh data yang diperoleh dari system ini;</li>
                  </ol>
                </div>
              </div>
              <div className="px-5 pb-8 text-center">
                <button type="button" data-dismiss="modal" className="btn w-24 btn-secondary mr-1">
                  Tidak
                </button>
                <button className="btn w-24 bg-theme-6 text-white" onClick={() => this.setuju()}>
                  Setuju
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

export default ModalPersetujuanAuditor;
