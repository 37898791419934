import React, { Component } from "react";
import NumberFormat from "react-number-format";
import Cookies from "js-cookie";
import axios from "axios";
import Modal from "../master/Modal";

class ModalProses extends Component {
  constructor(props) {
    super(props);
    this.state = {
      proses: false,
      paket: {},
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    return {
      paket: nextProps.paket,
    };
  }

  componentDidMount() {}

  prosesTender(e) {
    this.setState({
      proses: true,
    });
    let url = process.env.REACT_APP_APIURL + "paket/prosestender/" + e.pkt_id;
    if (e.metode_pengadaan == 8) {
      url = process.env.REACT_APP_APIURL + "paket/prosespl/" + e.pkt_id + "/" + this.state.paket.paket_pp;
    }
    let formData = {
      paket_id: this.state.paket.paket_id,
    };
    axios
      .post(`${url}`, formData, {
        headers: { Authorization: "Bearer " + Cookies.get("auth-token") },
      })
      .then((response) => {
        this.setState({
          proses: false,
        });
        this.props.prosesTender("sukses");
      })
      .catch((err) => {
        console.log(err.response);
      });
  }

  pilihanProses(e) {
    if (this.state.paket.paket_hps - e.pkt_hps > 1 || e.pkt_hps - this.state.paket.paket_hps > 1) {
      return <button className="btn bg-theme-6 text-white mr-2">HPS Tidak Sama</button>;
    } else if (e.pkt_status != 0) {
      return <button className="btn w-20 bg-theme-6 text-white mr-2">Sudah prnh diproses </button>;
    } else {
      return (
        <button
          className={`btn w-20 ${this.state.proses ? "bg-gray-200" : "bg-theme-11 text-white"} mr-2 proses_tender`}
          pkt_id={e.pkt_id}
          disabled={this.state.proses}
          onClick={() => this.prosesTender(e)}
        >
          Proses
        </button>
      );
    }
  }

  render() {
    return (
      <Modal>
        <div className="modal" id="modal_proses">
          <div className="modal-dialog modal-xl">
            <div className="modal-content">
              <div className="flex items-center px-5 py-5 sm:py-3 border-b border-gray-200">
                <h2 className="font-medium text-base mr-auto">Data Paket</h2>
              </div>
              <div className="p-5">
                <table className="table">
                  <thead>
                    <tr className="bg-gray-700 text-white">
                      <th className="whitespace-no-wrap">#</th>
                      <th className="whitespace-no-wrap">Nama Paket</th>
                      <th className="whitespace-no-wrap">HPS</th>
                      <th className="whitespace-no-wrap">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.props.sirup.map((e, idx) => {
                      return (
                        <tr key={idx}>
                          <td className="border-b">{idx + 1}</td>
                          <td className="border-b">{e.pkt_nama}</td>
                          <td className="border-b">
                            <NumberFormat value={e.pkt_hps} displayType={"text"} thousandSeparator={true} decimalScale={0} />
                          </td>
                          <td className="border-b">{this.pilihanProses(e)}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
              <div className="px-5 py-3 text-right border-gray-200">
                <button type="button" className="btn w-20 bg-theme-1 text-white mr-2" data-dismiss="modal">
                  OK
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

export default ModalProses;
