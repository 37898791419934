import React, { Component } from "react";
import moment from "moment";
import "moment/locale/id";

class Kalender extends Component {
  constructor(props) {
    super(props);
    this.state = {
      now: null,
      // akhir_bulan: null,
      index_awal: null,
      bulan_kemarin: null,
      tgl: [],
      tgl_kemarin: [],
      awal_bulan_kemarin: 0,
      sisa: [],
    };
  }

  componentDidMount() {
    this.setState({
      now: moment().format("DD"),
      index_awal: moment()
        .startOf("month")
        .day(),
      bulan_kemarin: moment()
        .subtract(1, "months")
        .endOf("month")
        .format("DD"),
      awal_bulan_kemarin:
        moment()
          .subtract(1, "months")
          .endOf("month")
          .format("DD") -
        moment()
          .startOf("month")
          .day(),
      tgl: Array.apply(null, {
        length: moment()
          .endOf("month")
          .format("DD"),
      }),
      tgl_kemarin: Array.apply(null, {
        length: moment()
          .startOf("month")
          .day(),
      }),
      sisa: Array.apply(null, {
        length:
          42 -
          moment()
            .startOf("month")
            .day() -
          moment()
            .endOf("month")
            .format("DD"),
      }),
    });
  }

  render() {
    let idx = 0;
    return (
      <div className="col-span-12 md:col-span-6 xl:col-span-4 2xl:col-span-12 xl:col-start-1 xl:row-start-2 2xl:col-start-auto 2xl:row-start-auto mt-3">
        <div className="intro-x flex items-center h-10">
          <h2 className="text-lg font-medium truncate mr-5">Kalender</h2>
        </div>
        <div className="mt-5">
          <div className="intro-x box">
            <div className="p-5">
              <div className="flex">
                <i
                  data-feather="chevron-left"
                  className="w-5 h-5 text-gray-600"
                ></i>
                <div className="font-medium mx-auto">
                  {moment().format("MMMM YYYY")}
                </div>
                <i
                  data-feather="chevron-right"
                  className="w-5 h-5 text-gray-600"
                ></i>
              </div>
              <div className="grid grid-cols-7 gap-4 mt-5 text-center">
                <div className="font-medium text-theme-6">Su</div>
                <div className="font-medium">Mo</div>
                <div className="font-medium">Tu</div>
                <div className="font-medium">We</div>
                <div className="font-medium">Th</div>
                <div className="font-medium">Fr</div>
                <div className="font-medium">Sa</div>
                {this.state.tgl_kemarin.map((e, index) => {
                  return (
                    <div
                      className="py-1 rounded relative text-gray-600"
                      key={index}
                    >
                      {this.state.awal_bulan_kemarin + index + 1}
                    </div>
                  );
                })}
                {this.state.tgl.map((e, index) => {
                  idx = this.state.index_awal + index;
                  if (index + 1 == this.state.now) {
                    return (
                      <div
                        className="py-1 bg-theme-1 text-white rounded relative"
                        key={index}
                      >
                        {index + 1}
                      </div>
                    );
                  } else if (idx % 7 == 0) {
                    return (
                      <div
                        className="py-1 rounded text-theme-6 relative"
                        key={index}
                      >
                        {index + 1}
                      </div>
                    );
                  } else {
                    return (
                      <div className="py-1 rounded relative" key={index}>
                        {index + 1}
                      </div>
                    );
                  }
                })}
                {this.state.sisa.map((e, index) => {
                  return (
                    <div
                      className="py-1 rounded relative text-gray-600"
                      key={index}
                    >
                      {index + 1}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Kalender;
