import React, { Component } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import Modal from "../master/Modal";
import Pristine from "pristinejs";
import Toastify from "toastify-js";
import cash from "cash-dom";

class ModalGagal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      proses: false,
      alasan: "",
    };
  }

  componentDidMount() {}

  onChange(e) {
    const name = e.target.name;
    const tipe = e.target.type;
    let value = e.target.value;

    this.setState({
      [name]: value,
    });
  }

  saveGagal() {
    let pris;
    cash(".validate-form-gagal").each(function () {
      pris = new Pristine(this, {
        classTo: "input-form",
        errorClass: "has-error",
        errorTextParent: "input-form",
        errorTextClass: "text-primary-3 mt-2",
      });
    });
    if (pris.validate()) {
      this.setState({
        proses: true,
      });
      let formData = {
        paket_id: this.props.paket.paket_id,
        lls_id: this.props.lls_id,
        alasan: this.state.alasan,
        user: Cookies.get("username"),
      };
      axios
        .post(process.env.REACT_APP_APIURL + "evaluasi/gagal", formData, {
          headers: { Authorization: "Bearer " + Cookies.get("auth-token") },
          timeout: 180000,
        })
        .then((response) => {
          this.props.tenderGagal("oke");
        });
    }
  }

  render() {
    return (
      <Modal>
        <div className="modal" id="modal_gagal">
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="flex items-center px-5 py-5 sm:py-3 border-b border-gray-200">
                <h2 className="font-medium text-base mr-auto">Tender Gagal</h2>
              </div>
              <div className="p-5">
                <form method="post" className="validate-form-gagal">
                  <div className="col-span-12 input-form">
                    <label>Nama Tender</label>
                    <input type="text" className="form-control" name="pemenang" value={this.props.paket.paket_nama} onChange={(e) => this.onChange(e)} readOnly required />
                  </div>
                  <div className="col-span-12 mt-2 input-form">
                    <label>Alasan Gagal</label>
                    <textarea className="form-control alasan" name="alasan" value={this.state.alasan} onChange={(e) => this.onChange(e)} minLength="15" required></textarea>
                  </div>
                </form>
              </div>
              <div className="px-5 py-3 text-right border-gray-200">
                <button type="button" className="btn w-20 btn-secondary mr-2" data-dismiss="modal">
                  Cancel
                </button>
                <button
                  type="button"
                  className={`btn w-20 ${this.state.proses ? "btn-secondary" : "btn-primary "} text-white`}
                  disabled={this.state.proses}
                  onClick={() => this.saveGagal()}
                >
                  Simpan
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

export default ModalGagal;
