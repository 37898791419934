import React, { Component } from "react";
// import "slick-carousel";
import cash from "cash-dom";
import { tns } from "tiny-slider/src/tiny-slider";
import moment from "moment";
import "moment/locale/id";
import feather from "feather-icons";
import { Link } from "react-router-dom";

// import ObrolanList from "./_Obrolanlist";
import Kalender from "./_Kalender";
import Lastfile from "./_Lastfile";

moment.locale("id");

class Kanan extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.chat !== this.props.chat) {
      this.slicks();
      // console.log(this.props.jumlah_paket);
    }
  }

  componentDidMount() {
    feather.replace({
      "stroke-width": 1.5,
    });

    // if (this.props.chat.length > 0) this.slicks();
    if (this.props.chat.length > 0) this.slider();
    // Slider widget page
  }

  slider() {
    if (cash(".tiny-slider").length) {
      cash(".tiny-slider").each(function () {
        this.tns = tns({
          container: this,
          slideBy: "page",
          mouseDrag: true,
          autoplay: true,
          controls: false,
          nav: false,
          speed: 500,
        });
      });
    }

    if (cash(".tiny-slider-navigator").length) {
      cash(".tiny-slider-navigator").each(function () {
        cash(this).on("click", function () {
          if (cash(this).data("target") == "prev") {
            cash("#" + cash(this).data("carousel"))[0].tns.goTo("prev");
          } else {
            cash("#" + cash(this).data("carousel"))[0].tns.goTo("next");
          }
        });
      });
    }
  }

  render() {
    const RawHTML = ({ children, className = "" }) => <div className={className} dangerouslySetInnerHTML={{ __html: children.replace(/\n/g, "<br />") }} />;
    return (
      <div className="col-span-12 2xl:col-span-3">
        <div className="2xl:border-l border-theme-5 -mb-10 pb-10">
          <div className="2xl:pl-6 grid grid-cols-12 gap-6">
            <Lastfile lastfile={this.props.lastfile} />
            <div className="col-span-12 md:col-span-6 xl:col-span-12 xl:col-start-1 xl:row-start-1 2xl:col-start-auto 2xl:row-start-auto mt-3">
              <div className="intro-x flex items-center h-10">
                <h2 className="text-lg font-medium truncate mr-auto">Chat Terakhir</h2>
                <button data-carousel="important-notes" data-target="prev" className="tiny-slider-navigator btn px-2 border-gray-400 text-gray-700 dark:text-gray-300 mr-2">
                  {" "}
                  <i data-feather="chevron-left" className="w-4 h-4"></i>{" "}
                </button>
                <button data-carousel="important-notes" data-target="next" className="tiny-slider-navigator btn px-2 border-gray-400 text-gray-700 dark:text-gray-300 mr-2">
                  {" "}
                  <i data-feather="chevron-right" className="w-4 h-4"></i>{" "}
                </button>
              </div>
              <div className="mt-5 intro-x">
                <div className="box zoom-in">
                  <div className="tiny-slider" id="important-notes">
                    {this.props.chat.map((e, index) => {
                      return (
                        <div className="p-5" key={index}>
                          <Link to={"/paket/detail/" + +e.chat_paket}>
                            <div className="text-base font-medium truncate">{e.paket_nama}</div>
                            <div className="text-theme-9 text-xs mt-1">{moment(e.chat_date).fromNow()}</div>
                            <div className="text-gray-600 text-justify mt-1">
                              <RawHTML>{e.chat_text.replace(/#\d+#/g, "").substring(0, 150)}</RawHTML>
                              {e.chat_text.length > 150 ? "..." : ""}
                            </div>
                            <div className="font-medium flex mt-5">
                              <span>{e.chat_user}</span>
                              <button type="button" className="btn btn-outline-secondary py-1 px-2 ml-auto ml-auto">
                                Lihat
                              </button>
                            </div>
                          </Link>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
            <Kalender />
          </div>
        </div>
      </div>
    );
  }
}

export default Kanan;
