import React, { Component } from "react";
import Cookies from "js-cookie";
import axios from "axios";
import feather from "feather-icons";

// import $ from "jquery";
// import "daterangepicker";
// import moment from "moment";
// import chart from "chart.js";

import Kanan from "./_Kanan";
import Paketperbulan from "./_Paketperbulan";
import Kategori from "./Kategori";
import Kategori2 from "./Kategori2";
import Perkecamatan from "./Perkecamatan";
import Paketperpokja from "./_Paketperpokja";
import Paketpokjaterbanyak from "./_Paketpokjaterbanyak";
import Datapaket from "./_Datapaket";
import Loading from "../master/Loading";

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      chat_last: [],
      jenis_pekerjaan: [1],
      kecamatan: [],
      paket: [],
      pengadaan_barang: 0,
      konstruksi: 0,
      konsultansi: 0,
      jasa_lainnya: 0,
      total_pagu: 0,
      total_paket: 0,
      perbulan: [],
      now: 0,
      lastfile: [],
      panitia: [],
      loading: false,
    };
  }

  componentDidMount() {
    feather.replace({
      "stroke-width": 1.5,
    });
    axios
      .get(`${process.env.REACT_APP_APIURL}dashboard/${Cookies.get("tahun")}`, {
        headers: { Authorization: "Bearer " + Cookies.get("auth-token") },
      })
      .then((response) => {
        let pengadaan_barang = 0,
          konstruksi = 0,
          konsultansi = 0,
          jasa_lainnya = 0,
          total_paket = 0,
          total_pagu = 0;
        let bulan = [];
        let nomor = 1;
        response.data.paket
          .filter((r) => r.paket_status > 0)
          .map((e, index) => {
            if (e.paket_jenispekerjaan === 0) {
              pengadaan_barang++;
            } else if (e.paket_jenispekerjaan === 2) {
              konstruksi++;
            } else if (e.paket_jenispekerjaan === 1 || e.paket_jenispekerjaan === 4 || e.paket_jenispekerjaan === 5) {
              konsultansi++;
            } else if (e.paket_jenispekerjaan === 3) {
              jasa_lainnya++;
            }
            total_pagu += e.paket_pagu;
            total_paket++;
            if (e.paket_created != null) {
              var date = new Date(e.paket_created).getMonth();
              if (date in bulan) {
                bulan[date]++;
              } else {
                bulan[date] = 1;
              }
            }
          });
        this.setState({
          now: response.data.now,
          chat_last: response.data.chatting,
          jenis_pekerjaan: response.data.jenis_pekerjaan,
          kecamatan: response.data.kecamatan,
          paket: response.data.paket,
          lastfile: response.data.dokumen_lelang,
          pengadaan_barang: pengadaan_barang,
          konstruksi: konstruksi,
          konsultansi: konsultansi,
          jasa_lainnya: jasa_lainnya,
          total_pagu: total_pagu,
          total_paket: total_paket,
          perbulan: bulan,
          panitia: response.data.panitia,
          loading: true,
        });
      })
      .catch((err) => {
        Object.keys(Cookies.get()).forEach(function (cookieName) {
          if (cookieName != "perpage" && cookieName != "satker") Cookies.remove(cookieName);
        });
        this.props.history.push("/auth");
      });
  }

  menuKUKPBJ() {
    if (Cookies.get("level") == "0" || Cookies.get("level") == "1") {
      return (
        <>
          <Paketperpokja panitia={this.state.panitia} />
          <Paketpokjaterbanyak panitia={this.state.panitia} />
        </>
      );
    }
  }

  render() {
    return this.state.loading ? (
      <div className="grid grid-cols-12 gap-6">
        <div className="col-span-12 2xl:col-span-9">
          <div className="grid grid-cols-12 gap-6">
            <Kategori
              pengadaan_barang={this.state.pengadaan_barang}
              konstruksi={this.state.konstruksi}
              konsultansi={this.state.konsultansi}
              jasa_lainnya={this.state.jasa_lainnya}
              tahun={Cookies.get("tahun")}
            />
            <Paketperbulan total_pagu={this.state.total_pagu} total_paket={this.state.total_paket} perbulan={this.state.perbulan} />
            <Perkecamatan kecamatan={this.state.kecamatan} paket={this.state.paket.filter((r) => r.paket_status > 0)} />

            <Kategori2
              jenis_pekerjaan={this.state.jenis_pekerjaan}
              total={this.state.total_paket}
              jumlah_paket={[this.state.pengadaan_barang, this.state.konstruksi, this.state.konsultansi, this.state.jasa_lainnya]}
            />
            {this.menuKUKPBJ()}
            <Datapaket paket={this.state.paket} jenis_pekerjaan={this.state.jenis_pekerjaan} />
          </div>
        </div>
        <Kanan chat={this.state.chat_last} now={this.state.now} lastfile={this.state.lastfile} />
      </div>
    ) : (
      <Loading />
    );
  }
}

export default Index;
