import React, { Component } from "react";
import feather from "feather-icons";
import Cookies from "js-cookie";
import axios from "axios";
import { Link } from "react-router-dom";
import Loading from "../master/Loading";

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pp: [],
      loading: false,
    };
  }

  componentDidMount() {
    axios
      .get(`${process.env.REACT_APP_APIURL}pp`, {
        headers: { Authorization: "Bearer " + Cookies.get("auth-token") },
      })
      .then((response) => {
        this.setState({
          pp: response.data.pp,
          loading: true,
        });
      });
    feather.replace({
      "stroke-width": 1.5,
    });
  }

  progressClass(val) {
    if (val == 0) return "w-0";
    else if (val < 8) return "w-1/12";
    else if (val < 16) return "w-1/6";
    else if (val < 21) return "w-1/5";
    else if (val < 26) return "w-1/4";
    else if (val < 33) return "w-1/3";
    else if (val < 41) return "w-2/5";
    else if (val < 42) return "w-5/12";
    else if (val < 51) return "w-1/2";
    else if (val < 59) return "w-7/12";
    else if (val < 60) return "w-3/5";
    else if (val < 67) return "w-2/3";
    else if (val < 75) return "w-3/4";
    else if (val < 80) return "w-4/5";
    else if (val < 84) return "w-5/6";
    else if (val < 92) return "w-11/12";
    else if (val < 97) return "w-97";
    else if (val >= 100) return "w-full";
    else return "w-full";
  }

  render() {
    return this.state.loading ? (
      <>
        <h2 className="intro-y text-lg font-medium mt-10">List Seluruh Pejabat Pengadaan (Total: {this.state.pp.length} org)</h2>
        <div className="grid grid-cols-12 gap-6 mt-5">
          {this.state.pp.map((e, idx) => {
            return (
              <div className="intro-y col-span-12 md:col-span-6" key={idx}>
                <div className="box">
                  <div className="flex flex-col lg:flex-row items-center p-5 border-b border-gray-200">
                    <div className="w-24 h-24 lg:w-12 lg:h-12 image-fit lg:mr-1">
                      <img alt={e.user_nama} className="rounded-full" src={`${process.env.REACT_APP_APIURL}uploads/${e.user_image}`} />
                    </div>
                    <div className="lg:ml-2 lg:mr-auto text-center lg:text-left mt-3 lg:mt-0">
                      {e.user_nama}
                      <div className="text-gray-600 text-xs">{e.user_jabatan}</div>
                    </div>
                    <div className="flex -ml-2 lg:ml-0 lg:justify-end mt-3 lg:mt-0">
                      <span className="w-8 h-8 rounded-full flex items-center justify-center border ml-2 bg-theme-9 text-white" title="Selesai">
                        {e.selesai}
                      </span>
                      <span className="w-8 h-8 rounded-full flex items-center justify-center border ml-2 bg-theme-1 text-white" title="Total paket">
                        {e.banyak}
                      </span>
                    </div>
                  </div>
                  <div className="flex flex-wrap lg:flex-no-wrap items-center justify-center p-5">
                    <div className="w-full lg:w-1/2 mb-4 lg:mb-0 mr-auto">
                      <div className="flex">
                        <div className="text-gray-600 text-xs mr-auto">Progress</div>
                        <div className="text-xs font-medium">{((e.selesai / e.banyak) * 100).toFixed()}%</div>
                      </div>
                      <div className="w-full h-1 mt-2 bg-gray-400 rounded-full">
                        <div className={`${this.progressClass((e.selesai / e.banyak) * 100)} progress-bar h-full bg-theme-1 rounded-full`}></div>
                      </div>
                    </div>
                    <Link to={`/paket/pp/${e.user_pp_id}`} className="btn btn-secondary">
                      Lihat Paket
                    </Link>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </>
    ) : (
      <Loading />
    );
  }
}

export default Index;
