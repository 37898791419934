import Dashboard from "./pages/dashboard/index";

import Paket from "./pages/paket/index";
import PaketDetail from "./pages/paket/detail";
import PaketEdit from "./pages/paket/edit";
import Pengajuan from "./pages/paket/pengajuan";
import PilihRUP from "./pages/paket/pilih_rup";

import Evaluasi from "./pages/evaluasi/index";

import TenagaAhli from "./pages/tenagaahli/index";
import Peralatan from "./pages/peralatan/index";
import Pemenang from "./pages/pemenang/index";

import Pokja from "./pages/pokja/index";
import Ppk from "./pages/ppk/index";
import Pp from "./pages/pp/index";

import Dokumen from "./pages/dokumen/index";
import DokumenEdit from "./pages/dokumen/edit";
import DokumenAdd from "./pages/dokumen/add";

import Dasar from "./pages/dasar_sk/index";
import DasarEdit from "./pages/dasar_sk/edit";
import DasarAdd from "./pages/dasar_sk/add";

import Setting from "./pages/setting/index";
import SettingEdit from "./pages/setting/edit";

import Log from "./pages/log/login";
import Logger from "./pages/log/logger";

import Users from "./pages/users/index";
import UserAdd from "./pages/users/add";
import UserEdit from "./pages/users/edit";
import UserProfil from "./pages/users/profile";
// import Contoh from "./pages/paket/template";
import Logout from "./pages/login/logout";

const routes = [
  {
    path: "/dashboard",
    component: Dashboard,
    name: "Dashboard",
    icon: "home",
    auth: ["-1"],
  },
  {
    path: "/paket",
    component: Paket,
    name: "Paket",
    icon: "box",
    auth: ["-1"],
    subMenus: [
      {
        path: "/paket/pengajuan/:rup_id?/:paket_id?",
        component: Pengajuan,
        name: "Ajukan Paket",
        icon: "fast-forward",
        auth: ["0", "4"],
      },
      {
        path: "/paket",
        component: Paket,
        name: "Data Paket",
        icon: "list",
        auth: ["0", "1", "2", "3", "4", "5", "7"],
      },
      {
        path: "/paket/pp",
        component: Paket,
        name: "Pengadaan Langsung",
        icon: "hash",
        auth: ["0", "1", "2", "3", "4", "5", "6", "7"],
      },
      {
        path: "/paket/pl_selesai",
        component: Paket,
        name: "PL Selesai",
        icon: "shopping-bag",
        auth: ["0", "1", "2", "3", "4", "5", "6", "7"],
      },
      {
        path: "/paket/gagal",
        component: Paket,
        name: "Gagal",
        icon: "x",
        auth: ["0", "1", "2", "3", "4", "5", "7"],
      },
      {
        path: "/paket/selesai",
        component: Paket,
        name: "Selesai",
        icon: "check",
        auth: ["0", "1", "2", "3", "4", "5", "7"],
      },
      {
        path: "/paket/dikembalikan",
        component: Paket,
        name: "Dikembalikan",
        icon: "rotate-ccw",
        auth: ["0", "1", "2", "3", "4", "5", "7"],
      },
    ],
  },
  {
    path: "/tenagaahli",
    component: TenagaAhli,
    name: "Tenaga Ahli",
    icon: "user-check",
    auth: ["0", "1", "2", "5", "6", "7"],
  },
  {
    path: "/peralatan",
    component: Peralatan,
    name: "Peralatan",
    icon: "tool",
    auth: ["0", "1", "2", "5", "6", "7"],
  },
  {
    path: "/pemenang",
    component: Pemenang,
    name: "Pemenang",
    icon: "star",
    auth: ["0", "1", "2", "5", "6", "7"],
  },
  {
    path: "pemisah",
    component: Dashboard,
    name: "Pemenang",
    icon: "star",
    auth: ["-1"],
  },
  {
    path: "/pokja",
    component: Pokja,
    name: "Pokja",
    icon: "award",
    auth: ["0", "1", "7"],
  },
  {
    path: "/pp",
    component: Pp,
    name: "Pj. Pengadaan",
    icon: "github",
    auth: ["0", "1", "7"],
  },
  {
    path: "/ppk",
    component: Ppk,
    name: "PPK",
    icon: "smile",
    auth: ["0", "1", "7"],
  },
  {
    path: "/profile",
    component: UserProfil,
    name: "Profile",
    icon: "user",
    auth: ["-1"],
  },
  {
    path: "/log",
    component: Dashboard,
    name: "Log",
    icon: "git-merge",
    auth: ["-1"],
    subMenus: [
      {
        path: "/log/login",
        component: Log,
        name: "Data Login",
        icon: "activity",
        auth: ["-1"],
      },
      {
        path: "/log/akses",
        component: Logger,
        name: "Log Akses",
        icon: "twitch",
        auth: ["-1"],
      },
    ],
  },
  {
    path: "pemisah",
    component: Dashboard,
    name: "Pemenang",
    icon: "star",
  },
  {
    path: "/users",
    component: Users,
    name: "Users",
    icon: "users",
    auth: ["0"],
  },
  {
    path: "/setting",
    component: Setting,
    name: "Settings",
    icon: "settings",
    auth: ["0"],
    subMenus: [
      {
        path: "/setting/system",
        component: Setting,
        name: "Setting System",
        icon: "terminal",
        auth: ["0"],
      },
      {
        path: "/setting/dokumen",
        component: Dokumen,
        name: "Setting Dokumen",
        icon: "book",
        auth: ["0"],
      },
      {
        path: "/setting/dasar",
        component: Dasar,
        name: "Dasar SK",
        icon: "edit-3",
        auth: ["0"],
      },
      {
        path: "/changelog",
        component: Dashboard,
        name: "Changelog",
        icon: "hash",
        auth: ["0"],
      },
    ],
  },
  // {
  //   path: "/pakta",
  //   component: Dashboard,
  //   name: "Pakta Integritas",
  //   icon: "edit",
  //   auth: ["0"],
  // },
  {
    path: "/logout",
    component: Logout,
    name: "Logout",
    icon: "lock",
    auth: ["-1"],
  },
];
export default routes;

export const route_extra = [
  {
    path: "/kategori/:id",
    component: Paket,
    name: "Paket Kategori",
    auth: ["-1"],
  },
  {
    path: "/pd/:id",
    component: Paket,
    name: "Paket Perangkat Daerah",
    auth: ["-1"],
  },
  {
    path: "/paket/detail/:id",
    component: PaketDetail,
    name: "Paket Detail",
    auth: ["-1"],
  },
  {
    path: "/paket/edit/:id",
    component: PaketEdit,
    name: "Paket Edit",
    auth: ["-1"],
  },
  {
    path: "/paket/pilih_rup",
    component: PilihRUP,
    name: "Pilih RUP",
    auth: ["-1"],
  },
  {
    path: "/users/add",
    component: UserAdd,
    name: "Add User",
    auth: ["0"],
  },
  {
    path: "/users/edit/:id",
    component: UserEdit,
    name: "Edit User",
    auth: ["0"],
  },
  {
    path: "/evaluasi/:paket_id",
    component: Evaluasi,
    name: "Evaluasi",
    auth: ["-1"],
  },
  {
    path: "/paket/pokja/:user_spse",
    component: Paket,
    name: "Paket Per Pokja",
    auth: ["-1"],
  },
  {
    path: "/paket/pp/:user_pp_id?",
    component: Paket,
    name: "Paket Per PP",
    auth: ["-1"],
  },
  {
    path: "/paket/ppk/:user_spse",
    component: Paket,
    name: "Paket Per PPK",
    auth: ["-1"],
  },
  {
    path: "/dokumen/edit/:id",
    component: DokumenEdit,
    name: "Edit Dokumen",
    auth: ["0"],
  },
  {
    path: "/dokumen/add",
    component: DokumenAdd,
    name: "Add Dokumen",
    auth: ["0"],
  },
  {
    path: "/dasar/edit/:id",
    component: DasarEdit,
    name: "Edit Dasar",
    auth: ["0"],
  },
  {
    path: "/setting/edit/:id",
    component: SettingEdit,
    name: "Edit Setting",
    auth: ["0"],
  },
  {
    path: "/dasar/add",
    component: DasarAdd,
    name: "Add Dasar",
    auth: ["0"],
  },
];
