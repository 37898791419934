import React, { Component } from "react";
import Cookies from "js-cookie";
import axios from "axios";
import { Link, withRouter } from "react-router-dom";
import Pristine from "pristinejs";
import cash from "cash-dom";

class Edit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      jenis_pekerjaan: [],
      dokumen: [],
      id: "",
      kode: "",
      nama: "",
      jenispekerjaan: "",
      wajib: "",
      tahap: "",
      proses: false,
    };
  }

  onChange(e) {
    const name = e.target.name;
    let value = e.target.value;

    if (e.target.name == "wajib") {
      value = value == "1" ? "0" : "1";
    }

    this.setState({
      [name]: value,
    });
  }

  componentDidMount() {
    let url = process.env.REACT_APP_APIURL + "dokumen/detail/" + this.props.match.params.id;

    axios
      .get(`${url}`, {
        headers: { Authorization: "Bearer " + Cookies.get("auth-token") },
      })
      .then((response) => {
        this.setState({
          id: response.data.dokumen.dok_id,
          kode: response.data.dokumen.dok_kode,
          nama: response.data.dokumen.dok_nama,
          jenispekerjaan: response.data.dokumen.dok_jenispekerjaan,
          wajib: response.data.dokumen.dok_wajib,
          tahap: response.data.dokumen.dok_tahap,
          jenis_pekerjaan: response.data.jenis_pekerjaan,
        });
      });
  }

  onSubmit() {
    let pris;
    cash(".validate-form").each(function () {
      pris = new Pristine(this, {
        classTo: "input-form",
        errorClass: "has-error",
        errorTextParent: "input-form",
        errorTextClass: "text-primary-3 mt-2",
      });
    });
    if (pris.validate()) {
      this.setState({ proses: true });
      let formData = {
        id: this.state.id,
        kode: this.state.kode,
        nama: this.state.nama,
        jenispekerjaan: this.state.jenispekerjaan,
        wajib: this.state.wajib,
        tahap: this.state.tahap,
      };
      let url = process.env.REACT_APP_APIURL + "dokumen/update";

      axios
        .put(`${url}`, formData, {
          headers: { Authorization: "Bearer " + Cookies.get("auth-token") },
        })
        .then((response) => {
          this.setState({ proses: false });
          this.props.history.push({
            pathname: "/setting/dokumen",
            changedRows: response.data.dokumen.changedRows,
          });
        });
    }
  }

  render() {
    return (
      <div>
        <div className="intro-y flex flex-col sm:flex-row items-center mt-8">
          <h2 className="text-lg font-medium mr-auto">Edit Dokumen</h2>
        </div>
        <div className="grid grid-cols-12 gap-6 mt-5">
          <div className="intro-y col-span-12 lg:col-span-6">
            <form method="post" name="dokumen" className="validate-form">
              <div className="intro-y box">
                <div className="p-5">
                  <div className="">
                    <div className="sm:grid grid-cols-2 gap-2">
                      <div>
                        <label>Kode</label>
                        <input
                          type="text"
                          className="form-control mt-2"
                          placeholder="Kode Dokumen"
                          name="kode"
                          value={this.state.kode}
                          onChange={(e) => this.onChange(e)}
                          readOnly
                          required
                        />
                      </div>
                      <div>
                        <label>Nama Dokumen</label>
                        <input
                          type="text"
                          className="form-control mt-2"
                          placeholder="Nama Dokumen"
                          name="nama"
                          value={this.state.nama}
                          onChange={(e) => this.onChange(e)}
                          required
                        />
                      </div>
                    </div>
                  </div>
                  <div className="mt-3">
                    <div className="sm:grid grid-cols-2 gap-2">
                      <div>
                        <label>Jenis Pekerjaan</label>
                        <select name="jenispekerjaan" className="form-select mt-2" onChange={(e) => this.onChange(e)} value={this.state.jenispekerjaan}>
                          <option value="">Pilih Jenis Pekerjaan</option>
                          <option value="-1">Semua</option>
                          {this.state.jenis_pekerjaan.map((e, index) => {
                            return (
                              <option key={index} value={index}>
                                {e.name}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                      <div>
                        <label>Tahap</label>
                        <select name="tahap" className="form-select mt-2" onChange={(e) => this.onChange(e)} value={this.state.tahap}>
                          <option value="">Pilih Tahap</option>
                          <option value="0">Pengajuan</option>
                          <option value="2">Selesai</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="mt-3 border-b border-gray-200 pb-5">
                    <div className="sm:grid grid-cols-2 gap-2">
                      <div className="mt-2">
                        <label>Wajib</label>
                        <div className="mt-2">
                          <input
                            type="checkbox"
                            name="wajib"
                            onChange={(e) => this.onChange(e)}
                            value={this.state.wajib}
                            checked={this.state.wajib == "1" ? true : false}
                            className="form-check-switch"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="text-right mt-5">
                    <Link to="/setting/dokumen" className="btn btn-secondary w-24 mr-1">
                      Cancel
                    </Link>
                    <button
                      type="button"
                      className={`btn w-24 ${!this.state.proses ? "btn-primary" : "btn-secondary"}`}
                      onClick={() => this.onSubmit()}
                      disabled={this.state.proses}
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Edit);
