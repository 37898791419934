import React, { Component } from "react";
import chart from "chart.js";
import cash from "cash-dom";

import { pembulatan } from "../../Definisi";

class Paketperbulan extends Component {
  constructor(props) {
    super(props);
    this.state = { tayang: [] };
  }

  componentDidMount() {
    let t = new Array();
    for (let i = 0; i < 12; i++) {
      if (i in this.props.perbulan) {
        t.push(this.props.perbulan[i]);
      } else {
        t.push(0);
      }
    }
    this.setState({ tayang: t }, () => {
      this.renderChart();
    });
    this.renderChart();
  }

  renderChart() {
    if (cash("#report-line-chart").length) {
      let ctx = cash("#report-line-chart")[0].getContext("2d");
      new chart(ctx, {
        type: "line",
        data: {
          labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
          datasets: [
            {
              label: "# Jumlah Paket",
              data: this.state.tayang,
              borderWidth: 2,
              borderColor: "#3160D8",
              backgroundColor: "transparent",
              pointBorderColor: "transparent",
            },
            // {
            //   label: "# of Votes2",
            //   data: [0, 1, 2, 3, 4, 10, 6, 7, 8, 9, 10, 2],
            //   borderWidth: 2,
            //   borderDash: [2, 2],
            //   borderColor: "#BCBABA",
            //   backgroundColor: "transparent",
            //   pointBorderColor: "transparent",
            // },
          ],
        },
        options: {
          legend: {
            display: false,
          },
          scales: {
            xAxes: [
              {
                ticks: {
                  fontSize: "12",
                  fontColor: "#777777",
                },
                gridLines: {
                  display: false,
                },
              },
            ],
            yAxes: [
              {
                ticks: {
                  fontSize: "12",
                  fontColor: "#777777",
                  callback: function (value, index, values) {
                    return value;
                  },
                },
                gridLines: {
                  color: "#D8D8D8",
                  zeroLineColor: "#D8D8D8",
                  borderDash: [2, 2],
                  zeroLineBorderDash: [2, 2],
                  drawBorder: false,
                },
              },
            ],
          },
        },
      });
    }
  }

  render() {
    return (
      <div className="col-span-12 lg:col-span-6 mt-8">
        <div className="intro-y block sm:flex items-center h-10">
          <h2 className="text-lg font-medium truncate mr-5">Paket Per Bulan</h2>
        </div>
        <div className="intro-y box p-5 mt-12 sm:mt-5">
          <div className="flex flex-col xl:flex-row xl:items-center">
            <div className="flex">
              <div>
                <div className="text-theme-20 text-lg xl:text-xl font-bold">Rp {pembulatan(this.props.total_pagu)}</div>
                <div className="text-gray-600">Total Pagu</div>
              </div>
            </div>

            <div className="w-px h-12 border border-r border-dashed border-gray-300 mx-4 xl:mx-6"></div>
            <div>
              <div className="text-gray-600 text-lg xl:text-xl font-medium">{this.props.total_paket}</div>
              <div className="text-gray-600">Total Paket</div>
            </div>
          </div>
          <div className="report-chart">
            <canvas id="report-line-chart" height="160" className="mt-6"></canvas>
          </div>
        </div>
      </div>
    );
  }
}

export default Paketperbulan;
