import React, { Component } from "react";
import chart from "chart.js";
import cash from "cash-dom";

class Paketperpokja extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.panitia !== this.props.panitia) {
      this.renderChart();
    }
  }

  renderChart() {
    if (cash("#vertical-bar-chart-widget").length) {
      let nama = [],
        jumlah = [],
        selesai = [];
      this.props.panitia.map((e, index) => {
        nama.push(e.user_nama);
        jumlah.push(e.banyak);
        selesai.push(e.selesai);
      });
      let ctx = cash("#vertical-bar-chart-widget")[0].getContext("2d");
      new chart(ctx, {
        type: "bar",
        data: {
          labels: nama,
          datasets: [
            {
              label: "Total Paket",
              barPercentage: 0.5,
              barThickness: 6,
              maxBarThickness: 8,
              minBarLength: 2,
              data: jumlah,
              backgroundColor: "#3160D8",
            },
            {
              label: "Selesai",
              barPercentage: 0.5,
              barThickness: 6,
              maxBarThickness: 8,
              minBarLength: 2,
              data: selesai,
              backgroundColor: "#BCBABA",
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            xAxes: [
              {
                ticks: {
                  fontSize: "12",
                  fontColor: "#777777",
                },
                gridLines: {
                  display: false,
                },
              },
            ],
            yAxes: [
              {
                ticks: {
                  fontSize: "12",
                  fontColor: "#777777",
                  callback: function (value, index, values) {
                    return value;
                  },
                },
                gridLines: {
                  color: "#D8D8D8",
                  zeroLineColor: "#D8D8D8",
                  borderDash: [2, 2],
                  zeroLineBorderDash: [2, 2],
                  drawBorder: false,
                },
              },
            ],
          },
        },
      });
    }
  }

  componentDidMount() {
    this.renderChart();
  }

  render() {
    return (
      <div className="col-span-12 xl:col-span-8 mt-6">
        <div className="intro-y block sm:flex items-center h-10">
          <h2 className="text-lg font-medium truncate mr-5">Paket per Pokja</h2>
        </div>
        <div className="intro-y box p-5 mt-12 sm:mt-5" style={{ height: "391px" }}>
          <div>Jumlah paket untuk masing-masing personil pokja</div>
          <canvas id="vertical-bar-chart-widget"></canvas>
        </div>
      </div>
    );
  }
}

export default Paketperpokja;
