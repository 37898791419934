import React, { Component } from "react";
import { Link } from "react-router-dom";
import { pembulatan } from "../../Definisi";

class Paketpokjaterbanyak extends Component {
  constructor(props) {
    super(props);
    this.state = { panitia: [] };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.panitia !== this.props.panitia) {
      this.renderTerbanyak();
    }
  }

  componentDidMount() {
    this.renderTerbanyak();
  }

  renderTerbanyak() {
    // result.sort((a, b) => b.total - a.total);
    // let panitia = this.props.panitia.sort((a, b) => b.banyak - a.banyak);
    this.setState({
      panitia: this.props.panitia.slice(0, 4),
    });
  }

  render() {
    return (
      <div className="col-span-12 xl:col-span-4 mt-6">
        <div className="intro-y flex items-center h-10">
          <h2 className="text-lg font-medium truncate mr-5">Pokja paket terbanyak</h2>
        </div>
        <div className="mt-5">
          {this.state.panitia.map((e, index) => {
            return (
              <div className="intro-y" key={index}>
                <div className="box px-4 py-4 mb-3 flex items-center zoom-in">
                  <div className="w-10 h-10 flex-none image-fit rounded-md overflow-hidden">
                    <img alt={e.user_nama} src={`${process.env.REACT_APP_APIURL}uploads/${e.user_image}`} />
                  </div>
                  <div className="ml-4 mr-auto">
                    <div className="font-medium">{e.user_nama}</div>
                    <div className="text-gray-600 text-xs">Rp {pembulatan(e.total)}</div>
                  </div>
                  <div className="py-1 px-2 rounded-full text-xs bg-theme-9 text-white cursor-pointer font-medium">{e.banyak} pkt</div>
                </div>
              </div>
            );
          })}
          <Link to="/pokja" className="intro-y w-full block text-center rounded-md py-4 border border-dotted border-theme-15 text-theme-16">
            View More
          </Link>
        </div>
      </div>
    );
  }
}

export default Paketpokjaterbanyak;
