import React, { Component } from "react";
import Cookies from "js-cookie";
import axios from "axios";
import jwt from "jsonwebtoken";
import Pristine from "pristinejs";
import Toastify from "toastify-js";
import cash from "cash-dom";
import { Link, withRouter } from "react-router-dom";
import feather from "feather-icons";
import FailedNotification from "../master/Failed";
import SuccessNotif from "../master/SuccessNotif";
import PejabaPengadaan from "./_pejabatPengadaan";

class Edit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      level: [],
      user_id: "",
      user_name: "",
      user_level: "",
      user_pp_name: "",
      nama: "",
      email: "",
      nohp: "",
      jk: "",
      foto: "",
      file_foto: "",
      password: "",
      repeat_password: "",
      pesan: "Data berhasil diupdate",
    };
  }

  onChange(e) {
    const name = e.target.name;
    const tipe = e.target.type;
    let value = e.target.value;

    if (tipe === "file") {
      value = e.target.files[0];
      this.setState({
        file_foto: URL.createObjectURL(value),
      });
    }
    this.setState({
      [name]: value,
    });
  }

  componentDidMount() {
    let url = process.env.REACT_APP_APIURL + "users/" + jwt.decode(Cookies.get("auth-token")).userid;
    axios
      .get(`${url}`, {
        headers: { Authorization: "Bearer " + Cookies.get("auth-token") },
      })
      .then((response) => {
        this.setState({
          user_id: response.data.user.user_id,
          user_name: response.data.user.user_name,
          user_pp_name: response.data.user.user_pp_name,
          user_level: response.data.user.user_level,
          nama: response.data.user.user_nama,
          jabatan: response.data.user.user_jabatan,
          pangkat: response.data.user.user_pangkat,
          nip: response.data.user.user_nip,
          email: response.data.user.user_email,
          nohp: response.data.user.user_nohp,
          jk: response.data.user.user_jk,
          level: response.data.level,
          file_foto: process.env.REACT_APP_APIURL + "uploads/" + response.data.user.user_image,
        });
      });
  }

  preview(e) {
    this.setState({
      file_foto: URL.createObjectURL(e.target.files[0]),
    });
  }

  menuPP() {
    cash("#programmatically-slide-over").modal("show");
  }

  updatePP = () => {
    this.setState(
      {
        pesan: "User Pejabat Pengadaan Telah Tersimpan",
      },
      () => {
        Toastify({
          node: cash("#success-notification-content").clone().removeClass("hidden")[0],
          duration: 5000,
          newWindow: true,
          close: true,
          gravity: "top",
          position: "right",
          stopOnFocus: true,
        }).showToast();
      }
    );
  };

  onSubmit() {
    let pris;
    cash(".validate-form").each(function () {
      pris = new Pristine(this, {
        classTo: "input-form",
        errorClass: "has-error",
        errorTextParent: "input-form",
        errorTextClass: "text-primary-3 mt-2",
      });
    });
    if (pris.validate()) {
      const formData = new FormData();
      formData.append("id", this.state.user_id);
      formData.append("nama", this.state.nama);
      formData.append("nip", this.state.nip);
      formData.append("jabatan", this.state.jabatan);
      formData.append("pangkat", this.state.pangkat);
      formData.append("user_name", this.state.user_name);
      formData.append("password", this.state.password);
      formData.append("repeat_password", this.state.repeat_password);
      formData.append("nohp", this.state.nohp);
      formData.append("email", this.state.email);
      formData.append("jk", this.state.jk);
      formData.append("level", this.state.user_level);
      formData.append("foto", this.state.foto);

      let url = process.env.REACT_APP_APIURL + "users/updateprofile";

      axios
        .put(`${url}`, formData, {
          headers: { Authorization: "Bearer " + Cookies.get("auth-token") },
        })
        .then((response) => {
          feather.replace({
            "stroke-width": 1.5,
          });
          Toastify({
            node: cash("#success-notification-content").clone().removeClass("hidden")[0],
            duration: 5000,
            newWindow: true,
            close: true,
            gravity: "top",
            position: "right",
            stopOnFocus: true,
          }).showToast();
        });
    } else {
      Toastify({
        node: cash("#failed-notification-content").clone().removeClass("hidden")[0],
        duration: 3000,
        newWindow: true,
        close: true,
        gravity: "top",
        position: "right",
        stopOnFocus: true,
      }).showToast();
      feather.replace({
        "stroke-width": 1.5,
      });
    }
  }

  pejabatPengadaan() {
    if (Cookies.get("level") == 5) {
      return (
        <button type="button" className={`btn w-40 btn-warning mr-1`} onClick={() => this.menuPP()} disabled={this.state.valid}>
          Pejabat Pengadaan
        </button>
      );
    }
  }

  render() {
    return (
      <div>
        <div className="intro-y flex flex-col sm:flex-row items-center mt-8">
          <h2 className="text-lg font-medium mr-auto">My Profile</h2>
        </div>
        <div className="grid grid-cols-12 gap-6 mt-5">
          <div className="intro-y col-span-12 lg:col-span-6">
            <form method="post" name="user" className="validate-form">
              <div className="intro-y box">
                <div className="flex flex-col lg:flex-row items-center p-5 border-b border-gray-200">
                  <div className="w-24 h-24 lg:w-12 lg:h-12 image-fit lg:mr-1">
                    <label htmlFor="file-input">
                      <img alt={this.state.nama} className="rounded-full foto" src={this.state.file_foto} />
                    </label>
                    <input id="file-input" type="file" style={{ display: "none" }} name="foto" onChange={(e) => this.onChange(e)} />
                  </div>
                  <div className="lg:ml-2 lg:mr-auto text-center lg:text-left mt-3 lg:mt-0">
                    {this.state.nama}
                    <div className="text-gray-600 text-xs">{this.state.level[this.state.user_level] ? this.state.level[this.state.user_level].name : "loading..."}</div>
                  </div>
                </div>
                <div className="p-5">
                  <div className="">
                    <div className="sm:grid grid-cols-2 gap-2">
                      <div className="input-form">
                        <label>Nama Lengkap</label>
                        <input
                          type="text"
                          className="form-control mt-2"
                          placeholder="Nama Lengkap"
                          name="nama"
                          value={this.state.nama}
                          onChange={(e) => this.onChange(e)}
                          required
                        />
                      </div>
                      <div className="input-form">
                        <label>NIP</label>
                        <input type="text" className="form-control mt-2" placeholder="NIP" name="nip" value={this.state.nip} onChange={(e) => this.onChange(e)} required />
                      </div>
                    </div>
                  </div>
                  <div className="mt-3">
                    <div className="sm:grid grid-cols-2 gap-2">
                      <div className="input-form">
                        <label>Jabatan</label>
                        <input
                          type="text"
                          className="form-control mt-2"
                          placeholder="Jabatan"
                          name="jabatan"
                          value={this.state.jabatan}
                          onChange={(e) => this.onChange(e)}
                          required
                        />
                      </div>
                      <div className="input-form">
                        <label>Pangkat</label>
                        <input
                          type="text"
                          className="form-control mt-2"
                          placeholder="Pangkat"
                          name="pangkat"
                          value={this.state.pangkat}
                          onChange={(e) => this.onChange(e)}
                          required
                        />
                      </div>
                    </div>
                  </div>

                  <div className="mt-3">
                    <div className="sm:grid grid-cols-2 gap-2">
                      <div className="mt-2 input-form">
                        <label>Email</label>
                        <input
                          type="text"
                          className="form-control mt-2"
                          placeholder="asd@gmail.com"
                          name="email"
                          value={this.state.email}
                          onChange={(e) => this.onChange(e)}
                          required
                        />
                      </div>
                      <div className="mt-2 input-form">
                        <label>No HP</label>
                        <input
                          type="text"
                          className="form-control mt-2"
                          placeholder="08xxxxx"
                          name="nohp"
                          value={this.state.nohp}
                          required
                          onChange={(e) => this.onChange(e)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="mt-3 border-b border-gray-200 pb-5">
                    <div className="sm:grid grid-cols-2 gap-2">
                      <div className="relative mt-2 input-form">
                        <label>Jenis Kelamin</label>
                        <div className="flex flex-col sm:flex-row mt-2">
                          <div className="flex items-center text-gray-700 mr-2">
                            <input
                              type="radio"
                              className="input border mr-2"
                              id="horizontal-radio-chris-evans"
                              name="jk"
                              value="L"
                              onChange={(e) => this.onChange(e)}
                              checked={this.state.jk === "L" ? true : false}
                              required
                            />
                            <label className="cursor-pointer select-none" htmlFor="horizontal-radio-chris-evans">
                              Laki-Laki
                            </label>
                          </div>
                          <div className="flex items-center text-gray-700 mr-2 mt-2 sm:mt-0">
                            <input
                              type="radio"
                              className="input border mr-2"
                              id="horizontal-radio-liam-neeson"
                              name="jk"
                              value="P"
                              onChange={(e) => this.onChange(e)}
                              checked={this.state.jk === "P" ? true : false}
                              required
                            />
                            <label className="cursor-pointer select-none" htmlFor="horizontal-radio-liam-neeson">
                              Perempuan
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mt-3">
                    <div className="sm:grid grid-cols-2 gap-2">
                      <div className="mt-2 input-form">
                        <label>Password</label>
                        <input
                          type="password"
                          className="form-control mt-2"
                          placeholder="password"
                          name="password"
                          autoComplete="new-password"
                          value={this.state.password}
                          onChange={(e) => this.onChange(e)}
                        />
                      </div>
                      <div className="mt-2 input-form">
                        <label>Ulangi Password</label>
                        <input
                          type="password"
                          className="form-control mt-2"
                          placeholder="ulangi password"
                          name="repeat_password"
                          value={this.state.repeat_password}
                          onChange={(e) => this.onChange(e)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="text-right mt-5">
                    <Link to="/users" className="btn w-24 border text-gray-700 mr-1">
                      Cancel
                    </Link>
                    {this.pejabatPengadaan()}
                    <button
                      type="button"
                      className={`btn w-24 ${!this.state.valid ? "btn-primary" : "bg-gray-200 text-gray-600"}`}
                      onClick={() => this.onSubmit()}
                      disabled={this.state.valid}
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        <PejabaPengadaan user_id={this.state.user_id} updatePP={this.updatePP} user_pp_name={this.state.user_pp_name} />
        <SuccessNotif pesan={this.state.pesan} />
        <FailedNotification />
      </div>
    );
  }
}

export default withRouter(Edit);
