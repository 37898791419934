import React, { Component } from "react";
import NumberFormat from "react-number-format";
import axios from "axios";
import Cookies from "js-cookie";
import Modal from "../master/Modal";
import Pristine from "pristinejs";
import Toastify from "toastify-js";
import cash from "cash-dom";

class ModalPemenang extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bahp: "",
      hasil_tender: "",
      proses: false,
      progress: 0,
    };
  }

  progressClass() {
    if (this.state.progress == 0) return "w-0";
    else if (this.state.progress < 8) return "w-1/12";
    else if (this.state.progress < 16) return "w-1/6";
    else if (this.state.progress < 21) return "w-1/5";
    else if (this.state.progress < 26) return "w-1/4";
    else if (this.state.progress < 33) return "w-1/3";
    else if (this.state.progress < 41) return "w-2/5";
    else if (this.state.progress < 42) return "w-5/12";
    else if (this.state.progress < 51) return "w-1/2";
    else if (this.state.progress < 59) return "w-7/12";
    else if (this.state.progress < 60) return "w-3/5";
    else if (this.state.progress < 67) return "w-2/3";
    else if (this.state.progress < 75) return "w-3/4";
    else if (this.state.progress < 80) return "w-4/5";
    else if (this.state.progress < 84) return "w-5/6";
    else if (this.state.progress < 92) return "w-11/12";
    else if (this.state.progress >= 100) return "w-full";
    else return "w-0";
  }

  componentDidMount() {}

  onChange(e) {
    const name = e.target.name;
    const tipe = e.target.type;
    let value = e.target.value;

    if (tipe === "file") {
      value = e.target.files[0];
    }
    this.setState({
      [name]: value,
    });
  }

  setPemenang() {
    let pris;
    cash(".validate-form").each(function () {
      pris = new Pristine(this, {
        classTo: "input-form",
        errorClass: "has-error",
        errorTextParent: "input-form",
        errorTextClass: "text-primary-3 mt-2",
      });
    });
    if (pris.validate()) {
      this.setState({
        proses: true,
      });
      const formData = new FormData();
      formData.append("paket_id", this.props.paket.paket_id);
      formData.append("tahun", this.props.paket.paket_tahun);
      formData.append("lls_id", this.props.calon_pemenang.lls_id);
      formData.append("rkn_id", this.props.calon_pemenang.rkn_id);
      formData.append("rkn_nama", this.props.calon_pemenang.rkn_nama);
      formData.append("harga", this.props.calon_pemenang.psr_harga);
      formData.append("bahp", this.state.bahp);
      formData.append("hasil_tender", this.state.hasil_tender);
      formData.append("user", Cookies.get("username"));
      axios
        .post(process.env.REACT_APP_APIURL + "pemenang/tetapkan", formData, {
          headers: { Authorization: "Bearer " + Cookies.get("auth-token") },
          onUploadProgress: (data) => {
            this.setState({
              progress: Math.round((100 * data.loaded) / data.total),
            });
          },
          timeout: 180000,
        })
        .then((response) => {
          this.props.oke("oke");
        });
    }
  }

  render() {
    return (
      <Modal>
        <div className="modal" id="modal_pemenang">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="flex items-center px-5 py-5 sm:py-3 border-b border-gray-200">
                <h2 className="font-medium text-base mr-auto">Pemenang</h2>
              </div>
              <div className="p-5">
                <form method="post" className="validate-form">
                  <div className="col-span-12 ">
                    <label>Nama Pemenang</label>
                    <input
                      type="text"
                      className="form-control"
                      name="pemenang"
                      value={this.props.calon_pemenang.rkn_nama}
                      onChange={(e) => this.onChange(e)}
                      readOnly
                      required
                    />
                  </div>
                  <div className="col-span-12 mt-2">
                    <label>Nilai Penawaran</label>
                    <NumberFormat
                      value={this.props.calon_pemenang.psr_harga}
                      displayType={"input"}
                      thousandSeparator={true}
                      decimalScale={0}
                      className="form-control"
                      required
                    />
                  </div>
                  <div className="col-span-12 mt-2 input-form">
                    <label>BAHP</label>
                    <input type="file" className="form-control upload_file" name="bahp" onChange={(e) => this.onChange(e)} required />
                  </div>
                  <div className="col-span-12 mt-2 input-form">
                    <label>Dokumen Hasil Tender</label>
                    <input type="file" className="form-control upload_file" name="hasil_tender" onChange={(e) => this.onChange(e)} required />
                  </div>
                  <div className="w-full h-4 bg-gray-400 rounded mt-1">
                    <div
                      className={`${this.progressClass()} progress-bar ${this.state.progress > 0 ? "bg-theme-1" : ""} rounded text-center text-white`}
                      role="progressbar"
                      aria-valuenow={this.state.progress}
                      aria-valuemin="0"
                      aria-valuemax="100"
                    >
                      {this.state.progress}%
                    </div>
                  </div>
                </form>
              </div>
              <div className="px-5 py-3 text-right border-gray-200">
                <button type="button" className="btn w-20 btn-secondary mr-2" data-dismiss="modal">
                  Cancel
                </button>
                <button
                  type="button"
                  className={`btn w-20 ${this.state.proses ? "btn-secondary" : "btn-primary"}`}
                  disabled={this.state.proses}
                  onClick={() => this.setPemenang()}
                >
                  Simpan
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

export default ModalPemenang;
