import React, { Component } from "react";
import Cookies from "js-cookie";
import axios from "axios";
import { Link } from "react-router-dom";
import { pembulatan, status } from "../../Definisi";
import cash from "cash-dom";
import "@left4code/tw-starter/dist/js/modal";
import Loading from "../master/Loading";

// import dt from "datatables.net";
// import dtResponsive from "datatables.net-responsive-dt";
// import $ from "jquery";
import feather from "feather-icons";
import moment from "moment";

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      paket: [],
      satker: [],
      jenis_pekerjaan: [],
      page: 0,
      limit: Cookies.get("perpage"),
      search: "",
      halaman: "",
      paket_id: undefined,
      loading: false,
    };
  }

  onChange(e) {
    const name = e.target.name;
    const value = e.target.value;

    if (e.target.type === "select-one") {
      Cookies.set("perpage", value);
      this.tableRender(1, value);
    }
    // if (name === "search") {
    //   let pkt = this.state.paket;
    //   this.setState({
    //     paket: pkt.filter((p) => p.paket_nama.includes(value)),
    //   });
    // }
    this.setState({
      [name]: value,
    });
  }

  paging() {
    let halaman = [],
      showPage = 5;
    let totalPage = Math.ceil(this.state.paket.filter((name) => name.paket_nama.toLowerCase().includes(this.state.search.toLowerCase())).length / Cookies.get("perpage"));
    let endPage = this.state.page % showPage === 0 ? this.state.page + Math.floor(showPage / 2) : Math.ceil(this.state.page / showPage) * showPage;
    let startPage = this.state.page % showPage === 0 ? this.state.page - Math.floor(showPage / 2) : endPage - showPage + 1;

    halaman.push(
      <li key="first">
        <button className="pagination__link" onClick={() => this.tableRender(1, Cookies.get("perpage") * 1)}>
          &lt;&lt;
        </button>
      </li>
    );

    if (this.state.page !== 1) {
      halaman.push(
        <li key="prev">
          <button className="pagination__link" onClick={() => this.tableRender(this.state.page - 1, Cookies.get("perpage") * (this.state.page - 1))}>
            &lt;
          </button>
        </li>
      );
    }

    // if (this.state.page > 4) {
    //   halaman.push(
    //     <li key="prevpage">
    //       <button className="pagination__link">...</button>
    //     </li>
    //   );
    // }

    for (let i = startPage; i <= endPage; i++) {
      if (i > totalPage) break;

      halaman.push(
        <li key={i}>
          <button className={`pagination__link ${this.state.page === i ? "pagination__link--active" : ""}`} onClick={() => this.tableRender(i, Cookies.get("perpage") * i)}>
            {i}
          </button>
        </li>
      );
    }

    // if (this.state.page < totalPage - 4) {
    //   halaman.push(
    //     <li key="nextpage">
    //       <button className="pagination__link">...</button>
    //     </li>
    //   );
    // }
    if (endPage < totalPage) {
      halaman.push(
        <li key="nextpage">
          <button className="pagination__link">...</button>
        </li>
      );
      halaman.push(
        <li key="akhir">
          <button
            className={`pagination__link ${this.state.page === totalPage ? "pagination__link--active" : ""}`}
            onClick={() => this.tableRender(totalPage, Cookies.get("perpage") * totalPage)}
          >
            {totalPage}
          </button>
        </li>
      );
    }

    if (this.state.page !== totalPage) {
      halaman.push(
        <li key="next">
          <button className="pagination__link" onClick={() => this.tableRender(this.state.page + 1, Cookies.get("perpage") * (this.state.page + 1))}>
            &gt;
          </button>
        </li>
      );
    }
    halaman.push(
      <li key="last">
        <button className="pagination__link" onClick={() => this.tableRender(totalPage, Cookies.get("perpage") * totalPage)}>
          &gt;&gt;
        </button>
      </li>
    );

    halaman.push(
      <li key="hal">
        <div className="pagination__link">
          Halaman {this.state.page} dari {totalPage}
        </div>
      </li>
    );
    return halaman;
  }

  tableRender(hal, limit) {
    // console.log(hal, limit);
    this.setState({
      page: hal,
      limit: limit,
    });
  }

  getData() {
    this.setState({ loading: false });
    let url = process.env.REACT_APP_APIURL;
    if (this.props.location.pathname.indexOf("/kategori") > -1) url = url + "paket/" + this.props.location.pathname.substr(1);
    else if (this.props.location.pathname.indexOf("/pd") > -1) url = url + "paket/" + this.props.location.pathname.substr(1);
    else url = url + this.props.location.pathname.substr(1);
    this.setState({
      halaman: this.props.location.pathname.substr(7),
    });

    axios
      .get(`${url}`, {
        headers: { Authorization: "Bearer " + Cookies.get("auth-token") },
      })
      .then((response) => {
        this.setState(
          {
            paket: response.data.paket,
            satker: response.data.satker,
            jenis_pekerjaan: response.data.jenis_pekerjaan,
            loading: true,
          },
          () => {
            this.tableRender(1, Cookies.get("perpage"));
          }
        );
      })
      .catch((err) => {
        Object.keys(Cookies.get()).forEach(function (cookieName) {
          if (cookieName != "perpage" && cookieName != "satker") Cookies.remove(cookieName);
        });
        this.props.history.push("/auth");
      });
  }

  componentDidMount() {
    this.getData();
    feather.replace({
      "stroke-width": 1.5,
    });
    let _this = this;
    // cash("body").on("click", "#hapus_paket", function () {
    //   console.log("hapus");
    //   _this.delete();
    // });
  }

  confirmDelete(o, e, element) {
    o.preventDefault();
    this.setState({
      paket_id: e.paket_id,
    });
    cash(element).modal("show");
    feather.replace({
      "stroke-width": 1.5,
    });
  }

  delete() {
    let url = process.env.REACT_APP_APIURL + "paket/delete/" + this.state.paket_id;

    axios
      .delete(`${url}`, {
        headers: { Authorization: "Bearer " + Cookies.get("auth-token") },
      })
      .then(() => {
        cash("#delete-confirmation-modal").modal("hide");
        this.getData();
      });
  }

  renderHeaderAction() {
    if (Cookies.get("level") == 4 || Cookies.get("level") == 0) {
      return <th className="text-center whitespace-nowrap">ACTIONS</th>;
    }
  }

  renderAction(e) {
    let link = `/paket/edit/${e.paket_id}`;
    if (e.paket_status < 1) {
      link = `/paket/pengajuan/${e.paket_rup}/${e.paket_id}`;
    }
    if ((e.paket_status < 11 && Cookies.get("level") == 4) || Cookies.get("level") == 0) {
      // if (e.paket_status < 11 && Cookies.get("level") == 4) {
      return (
        <td className="table-report__action w-32">
          <div className="flex justify-center items-center">
            <Link to={link} className="flex items-center mr-3" href="edit">
              <i data-feather="check-square" className="w-4 h-4 mr-1"></i>
              Edit
            </Link>
            <a href="/del" onClick={(o) => this.confirmDelete(o, e, "#delete-confirmation-modal")} className="flex items-center text-theme-6">
              <i data-feather="trash-2" className="w-4 h-4 mr-1"></i>
              Delete
            </a>
          </div>
        </td>
      );
    } else if (Cookies.get("level") == 4) {
      return (
        <td className="table-report__action w-32">
          <div className="flex justify-center items-center">
            <Link to={link} className="flex items-center mr-3" href="edit">
              <i data-feather="check-square" className="w-4 h-4 mr-1"></i>
              Edit
            </Link>
          </div>
        </td>
      );
    }
  }

  tombolAjukan() {
    if (Cookies.get("level") == 4) {
      return (
        <Link to="/paket/pengajuan" className="btn btn-primary shadow-md mr-2">
          Ajukan Paket Baru
        </Link>
      );
    }
  }

  render() {
    let label = {},
      skpd = "";
    return this.state.loading ? (
      <div className="col-span-12">
        <h2 className="intro-y text-lg font-medium mt-10">
          Data Paket {Cookies.get("tahun")} {this.state.halaman.replace(/^./, (str) => str.toUpperCase())}
        </h2>
        <div className="grid grid-cols-12 gap-6 mt-5">
          <div className="intro-y col-span-12 flex flex-wrap sm:flex-no-wrap items-center mt-2">
            {this.tombolAjukan()}
            <div className="hidden md:block mx-auto text-gray-600">
              Showing {this.state.page * Cookies.get("perpage") - Cookies.get("perpage") + 1} to{" "}
              {this.state.page * Cookies.get("perpage") > this.state.paket.length ? this.state.paket.length : this.state.page * Cookies.get("perpage")} of{" "}
              {this.state.paket.filter((name) => name.paket_nama.toLowerCase().includes(this.state.search.toLowerCase())).length} entries
              {this.state.search.length > 0 ? ` (filtered from ${this.state.paket.length} total entries)` : ""}
            </div>
            <div className="w-full sm:w-auto mt-3 sm:mt-0 sm:ml-auto md:ml-0">
              <div className="w-56 relative text-gray-700">
                <input type="text" name="search" className="form-control w-56 box pr-10 placeholder-theme-13" placeholder="Search..." onChange={(e) => this.onChange(e)} />
                <i className="w-4 h-4 absolute my-auto inset-y-0 mr-3 right-0" data-feather="search"></i>
              </div>
            </div>
          </div>
          <div className="intro-y col-span-12 overflow-auto lg:overflow-visible">
            <table className="table table-report -mt-2">
              <thead>
                <tr>
                  <th className="whitespace-nowrap">ID</th>
                  <th className="whitespace-nowrap">NAMA PAKET</th>
                  <th className={`whitespace-nowrap ${Cookies.get("level") == 4 ? "hidden" : ""}`}>OPD</th>
                  <th className="text-center whitespace-nowrap">HPS</th>
                  <th className="text-center whitespace-nowrap">STATUS</th>
                  <th className="text-center whitespace-nowrap">LASTUPDATE</th>
                  {this.renderHeaderAction()}
                </tr>
              </thead>
              <tbody>
                {this.state.paket
                  .filter((pkt) => pkt.paket_nama.toLowerCase().includes(this.state.search.toLowerCase()))
                  .slice(this.state.page * Cookies.get("perpage") - Cookies.get("perpage"), this.state.limit)
                  .map((e, index) => {
                    label = status.find((o) => {
                      return o.id === e.paket_status;
                    });
                    return (
                      <tr className="intro-x" key={index}>
                        <td className="w-10">{e.paket_id}</td>
                        <td className="" style={{ width: "35%" }}>
                          <Link to={`/paket/detail/${e.paket_id}`} className="font-medium">
                            {e.paket_nama}
                          </Link>
                          <div className="text-gray-600 text-xs whitespace-nowrap">
                            {
                              this.state.jenis_pekerjaan.find((obj) => {
                                return obj.id === e.paket_jenispekerjaan;
                              }).name
                            }
                          </div>
                        </td>
                        <td className={`${Cookies.get("level") == 4 ? "hidden" : ""}`}>
                          <Link to={`/pd/${e.paket_skpd}`}>
                            {(skpd = this.state.satker.find((obj, index) => {
                              return obj.stk_id === e.paket_skpd;
                            }))
                              ? skpd.stk_nama
                              : "Not Found: " + e.paket_skpd}
                          </Link>
                        </td>
                        <td className="text-center">{pembulatan(e.paket_hps)}</td>
                        <td className="text-center text-xs whitespace-nowrap">
                          <span className={`text-xs px-2 py-1 rounded-full ${label.color}`}>{label.label}</span>
                        </td>
                        <td className="text-center w-40">{moment(e.paket_updated).fromNow()}</td>
                        {this.renderAction(e)}
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
          <div className="intro-y col-span-12 flex flex-wrap sm:flex-row sm:flex-no-wrap items-center">
            <ul className="pagination">{this.paging()}</ul>
            <select className="w-20 form-select box mt-3 sm:mt-0" onChange={(e) => this.onChange(e)} value={Cookies.get("perpage")}>
              <option value="5">5</option>
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
            </select>
          </div>
        </div>
        <div id="delete-confirmation-modal" className="modal" tabIndex="-1" aria-hidden="true">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-body p-0">
                <div className="p-5 text-center">
                  <i data-feather="x-circle" className="w-16 h-16 text-theme-6 mx-auto mt-3"></i>
                  <div className="text-3xl mt-5">Are you sure?</div>
                  <div className="text-gray-600 mt-2">Apakah anda ingin menghapus paket ini {this.state.paket_id}?</div>
                </div>
                <div className="px-5 pb-8 text-center">
                  <button type="button" data-dismiss="modal" className="btn btn-outline-secondary w-24 mr-1">
                    Cancel
                  </button>
                  <button type="button" className="btn btn-danger w-24" onClick={() => this.delete()}>
                    Delete
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    ) : (
      <Loading />
    );
  }
}

export default Index;
