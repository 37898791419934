import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import cash from "cash-dom";
import Cookies from "js-cookie";
import feather from "feather-icons";
import Velocity from "velocity-animate";

import RouteLink from "../../Route";

class Menu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      current: "/dashboard",
      sub: "",
    };
  }

  onClick = (e) => {
    e.preventDefault();
    // console.log("click");
  };

  changeMenu(e, sub = undefined) {
    this.setState({
      current: e,
      sub: sub,
    });
  }

  componentDidMount() {
    // console.log(this.props.history);
    feather.replace({
      "stroke-width": 1.5,
    });
    cash(".side-menu").on("click", function () {
      if (cash(this).parent().find("ul").length) {
        if (cash(this).parent().find("ul").first()[0].offsetParent !== null) {
          cash(this).find(".side-menu__sub-icon").removeClass("transform rotate-180");
          cash(this).removeClass("side-menu--open");
          Velocity(cash(this).parent().find("ul").first(), "slideUp", {
            duration: 300,
            complete: function (el) {
              cash(el).removeClass("side-menu__sub-open");
            },
          });
        } else {
          cash(this).find(".side-menu__sub-icon").addClass("transform rotate-180");
          cash(this).addClass("side-menu--open");
          Velocity(cash(this).parent().find("ul").first(), "slideDown", {
            duration: 300,
            complete: function (el) {
              cash(el).addClass("side-menu__sub-open");
            },
          });
        }
      }
    });
  }

  render() {
    return (
      <nav className="side-nav">
        <Link to="/" className="intro-x flex items-center pl-5 pt-4" onClick={() => this.changeMenu("/dashboard")}>
          <img alt="SISINFO" className="w-6" src="dist/images/logo.svg" />
          <span className="hidden xl:block text-white text-lg ml-3">{process.env.REACT_APP_NAME}</span>
        </Link>
        <div className="side-nav__devider my-6"></div>
        <ul>
          {RouteLink.map((e, index) => {
            if (e.path == "pemisah") {
              return <li className="side-nav__devider my-6" key={index}></li>;
            } else if (e.subMenus == undefined) {
              if (e.auth.includes("-1") || e.auth.includes(Cookies.get("level"))) {
                return (
                  <li key={index}>
                    <Link
                      to={e.path.replace(/:.*/g, "")}
                      className={`side-menu ${this.state.current === e.path ? "side-menu--active" : ""}`}
                      onClick={() => this.changeMenu(e.path)}
                    >
                      <div className="side-menu__icon">
                        <i data-feather={e.icon}></i>
                      </div>
                      <div className="side-menu__title"> {e.name} </div>
                    </Link>
                  </li>
                );
              }
            } else {
              if (e.auth.includes("-1") || e.auth.includes(Cookies.get("level"))) {
                return (
                  <li key={index}>
                    <a href="#" onClick={this.onClick} className={`side-menu ${this.state.current === e.path ? "side-menu--active" : ""}`}>
                      <div className="side-menu__icon">
                        <i data-feather={e.icon}></i>
                      </div>
                      <div className="side-menu__title">
                        {e.name}
                        <i data-feather="chevron-down" className="side-menu__sub-icon"></i>
                      </div>
                    </a>
                    <ul className="">
                      {e.subMenus.map((subMenu, i) => {
                        if (subMenu.auth.includes("-1") || subMenu.auth.includes(Cookies.get("level"))) {
                          return (
                            <li key={i}>
                              <Link
                                to={subMenu.path.replace(/:.*/g, "")}
                                className={`side-menu ${this.state.sub === subMenu.path ? "side-menu--active" : ""}`}
                                onClick={() => this.changeMenu(e.path, subMenu.path)}
                              >
                                <div className="side-menu__icon">
                                  <i data-feather={subMenu.icon}></i>
                                </div>
                                <div className="side-menu__title">{subMenu.name}</div>
                              </Link>
                            </li>
                          );
                        }
                      })}
                    </ul>
                  </li>
                );
              }
            }
          })}
        </ul>
      </nav>
    );
  }
}

export default withRouter(Menu);
