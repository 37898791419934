import React from "react";
import ReactDOM from "react-dom";
import { HashRouter } from "react-router-dom";
// import './index.css';
// import App from './App';
import App from "./pages/master/index";
import { ThemeProvider } from "./pages/master/theme-context";
// import reportWebVitals from './reportWebVitals';

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider>
      <HashRouter>
        <App />
      </HashRouter>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
