import React, { Component } from "react";
import NumberFormat from "react-number-format";
import { status } from "../../Definisi";

class Detail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // jenis_pekerjaan: [],
      // stats: status.find((e) => e.id == this.props.paket.paket_status),
      // paket: "",
    };
  }

  componentDidMount() {
    this.setState({
      stats: status.find((e) => e.id == this.props.paket.paket_status),
      paket: this.props.paket,
    });
  }

  render() {
    let jenis_pekerjaan = "",
      kualifikasi = "";
    return (
      <div className="intro-y box col-span-12 lg:col-span-6">
        <div className="flex items-center px-5 py-5 sm:py-0 border-b border-gray-200">
          <h2 className="font-medium text-base mr-auto py-5">Detail Paket </h2>
        </div>
        <div className="p-5">
          <div className="flex items-center border-b pb-2 pl-2">
            <div className="">
              <div className="text-gray-600">Nama Paket</div>
              <div>{this.props.paket.paket_nama}</div>
            </div>
            <i data-feather="archive" className="w-4 h-4 text-gray-600 ml-auto"></i>
          </div>
          <div className="flex items-center border-b py-2 pl-2">
            <div className="">
              <div className="text-gray-600">Pagu / HPS</div>
              <div>
                <NumberFormat value={this.props.paket.paket_pagu} displayType={"text"} thousandSeparator={true} decimalScale={0} /> /{" "}
                <NumberFormat value={this.props.paket.paket_hps} displayType={"text"} thousandSeparator={true} decimalScale={0} />
              </div>
            </div>
            <i data-feather="dollar-sign" className="w-4 h-4 text-gray-600 ml-auto"></i>
          </div>
          <div className="flex items-center border-b py-2 pl-2">
            <div className="">
              <div className="text-gray-600">Lokasi</div>
              <div>
                {this.props.paket.paket_lokasi
                  ? this.props.paket.paket_lokasi.split(",").map((e, index) => {
                      let x = this.props.kecamatan.find((obj) => {
                        return obj.kecamatan_id == e;
                      });
                      if (index > 0) {
                        return ", " + x.kecamatan_nama;
                      } else {
                        return x.kecamatan_nama;
                      }
                    })
                  : ""}
              </div>
            </div>
            <i data-feather="globe" className="w-4 h-4 text-gray-600 ml-auto"></i>
          </div>
          <div className="flex items-center border-b py-2 pl-2">
            <div className="">
              <div className="text-gray-600">Jenis Pekerjaan</div>
              <div>
                {(jenis_pekerjaan = this.props.jenis_pekerjaan.find((obj) => {
                  return obj.id === this.props.paket.paket_jenispekerjaan;
                }))
                  ? jenis_pekerjaan.name
                  : "not found"}
              </div>
            </div>
            <i data-feather="archive" className="w-4 h-4 text-gray-600 ml-auto"></i>
          </div>
          <div className="flex items-center border-b py-2 pl-2">
            <div className="">
              <div className="text-gray-600">Kualifikasi</div>
              <div>
                {(kualifikasi = this.props.kualifikasi.find((obj, idx) => {
                  return idx === this.props.paket.paket_kualifikasi;
                }))
                  ? kualifikasi
                  : "not found"}
              </div>
            </div>
            <i data-feather="layers" className="w-4 h-4 text-gray-600 ml-auto"></i>
          </div>
          <div className="flex items-center border-b py-2 pl-2">
            <div className="">
              <div className="text-gray-600">Sumber Dana</div>
              <div>
                {this.props.paket.paket_sumberdana
                  ? this.props.paket.paket_sumberdana.split(",").map((e, idx) => {
                      let x = this.props.sumberdana.find((obj) => {
                        // if (obj.kecamatan_id == e) {
                        //   console.log("ketemu");
                        // } else {
                        //   console.log("tidak ", e);
                        // }
                        return obj.sd_id == e;
                        // return "a";
                      });
                      // console.log(this.props.kecamatan);
                      // console.log("x: ", x);
                      if (idx > 0) {
                        return ", " + x.sd_nama;
                      } else {
                        return x.sd_nama;
                      }
                    })
                  : "-"}
              </div>
            </div>
            <i data-feather="database" className="w-4 h-4 text-gray-600 ml-auto"></i>
          </div>
          <div className="flex items-center border-b py-2 pl-2">
            <div className="">
              <div className="text-gray-600">Jenis Kontrak</div>
              <div>
                {this.props.paket.paket_kontrak
                  ? this.props.jenis_kontrak.find((o) => {
                      return o.id === this.props.paket.paket_kontrak;
                    }).name
                  : "-"}
              </div>
            </div>
            <i data-feather="book" className="w-4 h-4 text-gray-600 ml-auto"></i>
          </div>
          <div className="flex items-center pt-2 pl-2">
            <div className="">
              <div className="text-gray-600">Sifat </div>
              <div className="">
                {this.props.paket.paket_kompleks
                  ? this.props.sifat_pengadaan.find((o) => {
                      return o.id === this.props.paket.paket_kompleks;
                    }).name
                  : "-"}
              </div>
            </div>
            <i data-feather="alert-circle" className="w-4 h-4 text-gray-600 ml-auto"></i>
          </div>
        </div>
      </div>
    );
  }
}

export default Detail;
