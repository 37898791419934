import React, { Component } from "react";
import Cookies from "js-cookie";
import axios from "axios";
import Modal from "../master/Modal";
import cash from "cash-dom";

class ModalDelete extends Component {
  constructor(props) {
    super(props);
    this.state = {
      proses: false,
    };
  }

  delete() {
    let url = process.env.REACT_APP_APIURL + "users/delete/" + this.props.user.user_id;

    axios
      .delete(`${url}`, {
        headers: { Authorization: "Bearer " + Cookies.get("auth-token") },
      })
      .then(() => {
        cash("#delete").modal("hide");
        this.props.trigger();
      });
  }

  render() {
    return (
      <Modal>
        <div className="modal" id="delete">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="p-5 text-center">
                <i data-feather="help-circle" className="w-16 h-16 text-theme-6 mx-auto mt-3"></i>
                <div className="text-3xl mt-5">Are you sure?</div>
                <div className="text-gray-600 mt-2">
                  Apakah anda yakin untuk menghapus akun <b>{this.props.user.user_name}</b> ?
                </div>
              </div>
              <div className="px-5 pb-8 text-center">
                <button type="button" data-dismiss="modal" className="btn w-24 btn-secondary mr-1">
                  Cancel
                </button>
                <button className="btn w-24 bg-theme-6 text-white" onClick={() => this.delete()}>
                  OK
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

export default ModalDelete;
