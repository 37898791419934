import React, { Component } from "react";
import download from "js-file-download";
import Cookies from "js-cookie";
import axios from "axios";

class Pokja extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  downloadSK(e) {
    e.preventDefault();
    let url = `${process.env.REACT_APP_APIURL}sk/download/${this.props.paket.paket_id}/${Cookies.get("username")}`;
    if (this.props.paket.paket_metode == 8) {
      url = `${process.env.REACT_APP_APIURL}sk/download_pl/${this.props.paket.paket_id}/${Cookies.get("username")}`;
    }
    axios
      .get(url, {
        headers: { Authorization: "Bearer " + Cookies.get("auth-token") },
        responseType: "blob",
      })
      .then((response) => {
        let filename = response.headers["content-disposition"].split("filename=")[1].replaceAll('"', "");
        download(response.data, filename);
      });
  }

  async renderPokja() {
    if (this.props.paket.paket_metode == 8) {
      axios
        .get(`${process.env.REACT_APP_APIURL}users/pp/${this.props.paket.paket_pp}`, {
          headers: { Authorization: "Bearer " + Cookies.get("auth-token") },
        })
        .then((response) => {
          return (
            <div className="relative flex items-center">
              <div className="w-12 h-12 flex-none image-fit">
                <img alt="" className="rounded-full" src={process.env.REACT_APP_APIURL + "uploads/"} />
              </div>
              <div className="ml-4 mr-auto">
                nama
                <div className="text-gray-600 mr-5 sm:mr-5">hp</div>
              </div>
            </div>
          );
          // response.data.user.map((e, idx) => {
          //   return (
          //     <div className="relative flex items-center">
          //       <div className="w-12 h-12 flex-none image-fit">
          //         <img alt="" className="rounded-full" src={process.env.REACT_APP_APIURL + "uploads/" + e.user_image} />
          //       </div>
          //       <div className="ml-4 mr-auto">
          //         {e.user_nama}
          //         <div className="text-gray-600 mr-5 sm:mr-5">{e.user_nohp}</div>
          //       </div>
          //     </div>
          //   );
          // });
        });
    } else {
      this.props.pokja.map((e, idx) => {
        return (
          <div className={`relative flex items-center ${idx == 0 ? "" : "mt-5"}`} key={idx}>
            <div className="w-12 h-12 flex-none image-fit">
              <img alt="" className="rounded-full" src={process.env.REACT_APP_APIURL + "uploads/" + e.user_image} />
            </div>
            <div className="ml-4 mr-auto">
              {e.user_nama}
              <div className="text-gray-600 mr-5 sm:mr-5">{e.user_nohp}</div>
            </div>
          </div>
        );
      });
    }
  }

  render() {
    return (
      <div className="intro-y box col-span-12 lg:col-span-6">
        <div className="flex items-center px-5 py-5 sm:py-3 border-b border-gray-200">
          <h2 className="font-medium text-base mr-auto">{this.props.paket.paket_metode == 8 ? "Pejabat Pengadaan" : "Pokja"}</h2>
          <div className="dropdown ml-auto sm:hidden">
            <a className="dropdown-toggle w-5 h-5 block" href="javascript:;" aria-expanded="false">
              <i data-feather="more-horizontal" className="w-5 h-5 text-gray-700"></i>
            </a>
            <div className="dropdown-menu w-40">
              <div className="dropdown-menu__content box dark:bg-dark-1 p-2">
                <a
                  href="#"
                  className="flex items-center p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                  onClick={(e) => this.downloadSK(e)}
                >
                  <i data-feather="file" className="w-4 h-4 mr-2"></i> Download SK
                </a>
              </div>
            </div>
          </div>
          <button className="btn btn-outline-secondary hidden sm:flex" onClick={(e) => this.downloadSK(e)}>
            <i data-feather="file" className="w-4 h-4 mr-2"></i> Download SK
          </button>
        </div>
        <div className="p-5">
          {this.props.pokja.map((e, idx) => {
            return (
              <div className={`relative flex items-center ${idx == 0 ? "" : "mt-5"}`} key={idx}>
                <div className="w-12 h-12 flex-none image-fit">
                  <img alt="" className="rounded-full" src={process.env.REACT_APP_APIURL + "uploads/" + e.user_image} />
                </div>
                <div className="ml-4 mr-auto">
                  {e.user_nama}
                  <div className="text-gray-600 mr-5 sm:mr-5">{e.user_nohp}</div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

export default Pokja;
