import React, { Component } from "react";
import feather from "feather-icons";
import { withRouter } from "react-router-dom";

class Kategori extends Component {
  constructor(props) {
    super(props);
    this.state = {
      total: 0,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.pengadaan_barang !== this.props.pengadaan_barang) {
      this.setState({
        total: this.props.pengadaan_barang + this.props.konstruksi + this.props.konsultansi + this.props.jasa_lainnya,
      });
    }
  }

  componentDidMount() {
    this.setState({
      total: this.props.pengadaan_barang + this.props.konstruksi + this.props.konsultansi + this.props.jasa_lainnya,
    });
    feather.replace({
      "stroke-width": 1.5,
    });
  }

  paket(e) {
    this.props.history.push("/kategori/" + e);
  }

  render() {
    return (
      <div className="col-span-12 mt-8">
        <div className="intro-y flex items-center h-10">
          <h2 className="text-lg font-medium truncate mr-5">Dashboard {this.props.tahun}</h2>
          <a href="" className="ml-auto flex text-theme-1">
            <i data-feather="refresh-ccw" className="w-4 h-4 mr-3"></i> Reload Data
          </a>
        </div>
        <div className="grid grid-cols-12 gap-6 mt-5">
          <div className="col-span-12 sm:col-span-6 xl:col-span-3 intro-y" onClick={() => this.paket("barang")}>
            <div className="report-box zoom-in">
              <div className="box p-5">
                <div className="flex">
                  <i data-feather="package" className="report-box__icon text-theme-10"></i>
                  <div className="ml-auto">
                    <div className="report-box__indicator bg-theme-9 tooltip cursor-pointer" title="Persentase">
                      {Math.round((this.props.pengadaan_barang / this.state.total) * 100)}%
                    </div>
                  </div>
                </div>
                <div className="text-3xl font-bold leading-8 mt-6">{this.props.pengadaan_barang}</div>
                <div className="text-base text-gray-600 mt-1">Pengadaan Barang</div>
              </div>
            </div>
          </div>
          <div className="col-span-12 sm:col-span-6 xl:col-span-3 intro-y" onClick={() => this.paket("konstruksi")}>
            <div className="report-box zoom-in">
              <div className="box p-5">
                <div className="flex">
                  <i data-feather="server" className="report-box__icon text-theme-11"></i>
                  <div className="ml-auto">
                    <div className="report-box__indicator bg-theme-6 tooltip cursor-pointer" title="Persentase">
                      {Math.round((this.props.konstruksi / this.state.total) * 100)}%
                    </div>
                  </div>
                </div>
                <div className="text-3xl font-bold leading-8 mt-6">{this.props.konstruksi}</div>
                <div className="text-base text-gray-600 mt-1">Pekerjaan Konstruksi</div>
              </div>
            </div>
          </div>
          <div className="col-span-12 sm:col-span-6 xl:col-span-3 intro-y" onClick={() => this.paket("konsultan")}>
            <div className="report-box zoom-in">
              <div className="box p-5">
                <div className="flex">
                  <i data-feather="user" className="report-box__icon text-theme-12"></i>
                  <div className="ml-auto">
                    <div className="report-box__indicator bg-theme-9 tooltip cursor-pointer" title="Persentase">
                      {Math.round((this.props.konsultansi / this.state.total) * 100)}%
                    </div>
                  </div>
                </div>
                <div className="text-3xl font-bold leading-8 mt-6">{this.props.konsultansi}</div>
                <div className="text-base text-gray-600 mt-1">Jasa Konsultan</div>
              </div>
            </div>
          </div>
          <div className="col-span-12 sm:col-span-6 xl:col-span-3 intro-y" onClick={() => this.paket("lainnya")}>
            <div className="report-box zoom-in">
              <div className="box p-5">
                <div className="flex">
                  <i data-feather="archive" className="report-box__icon text-theme-9"></i>
                  <div className="ml-auto">
                    <div className="report-box__indicator bg-theme-9 tooltip cursor-pointer" title="Persentase">
                      {Math.round((this.props.jasa_lainnya / this.state.total) * 100)}%
                    </div>
                  </div>
                </div>
                <div className="text-3xl font-bold leading-8 mt-6">{this.props.jasa_lainnya}</div>
                <div className="text-base text-gray-600 mt-1">Jasa Lainnya</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Kategori);
