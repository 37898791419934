import React, { Component } from "react";
import Modal from "../master/Modal";
import Cookies from "js-cookie";
import cash from "cash-dom";
import axios from "axios";
import Velocity from "velocity-animate";
import feather from "feather-icons";

class PejabatPengadaan extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userid: "",
      username: "",
      password: "",
      ketemu: false,
      error: false,
      error_message: "",
    };
  }

  // componentDidUpdate(prevProps, prevState) {
  //   if (this.state.username != prevProps.user_pp_name) {
  //     this.setState({
  //       username: prevProps.user_pp_name,
  //     });
  //   }
  // }

  componentDidMount() {
    feather.replace({
      "stroke-width": 1.5,
    });
  }

  onChange(e) {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({
      [name]: value,
    });
  }

  cancel() {
    cash("#programmatically-slide-over").modal("hide");
  }

  login() {
    let formData = {
      username: this.state.username,
      password: this.state.password,
    };

    axios
      .post(`${process.env.REACT_APP_APIURL}auth/login_spse`, formData)
      .then((response) => {
        if (response.data.pegawai.idgroup === "PP" || response.data.pegawai.usrgroup === "PP") {
          axios
            .put(
              `${process.env.REACT_APP_APIURL}users/update_pp/${this.props.user_id}`,
              {
                user_pp_id: response.data.pegawai.peg_id,
                user_pp_name: response.data.pegawai.peg_namauser,
              },
              { headers: { Authorization: "Bearer " + Cookies.get("auth-token") } }
            )
            .then((response) => {
              this.props.updatePP();
            });
        } else {
          this.setState(
            {
              error: true,
              error_message: "User ini bukan Pejabat Pengadaan",
            },
            () => {
              Velocity(cash("#error_message"), "slideDown", {
                duration: 300,
                complete: function (el) {
                  setTimeout(() => {
                    Velocity(cash(el), "slideUp");
                  }, 5000);
                },
              });
            }
          );
        }
      })
      .catch((err) => {
        this.setState(
          {
            error: true,
            error_message: "Username / Password anda tidak sesuai!",
          },
          () => {
            Velocity(cash("#error_message"), "slideDown", {
              duration: 300,
              complete: function (el) {
                setTimeout(() => {
                  Velocity(cash(el), "slideUp");
                }, 5000);
              },
            });
          }
        );
      });
  }

  render() {
    return (
      <Modal>
        <div id="programmatically-slide-over" className="modal modal-slide-over" tabindex="-1" aria-hidden="true">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header p-5">
                <h2 className="font-medium text-base mr-auto">Data Login Pejabat Pengadaan</h2>
              </div>
              <div className="modal-body p-10">
                <div id="error_message" className={`rounded-md flex items-center px-5 py-4 mb-2 bg-theme-31 text-theme-6 ${this.state.error === true ? "" : "hidden"}`}>
                  <i data-feather="alert-octagon" className="w-6 h-6 mr-2"></i>
                  {this.state.error_message}
                </div>
                <form method="post" name="form-pp" className="form-pp">
                  <div className="mt-2">
                    <div className="input-form">
                      <label>Username PP</label>
                      <input
                        type="text"
                        className="form-control mt-2"
                        placeholder="Username Pejabat Pengadaan"
                        name="username"
                        value={this.state.username}
                        required
                        onChange={(e) => this.onChange(e)}
                      />
                    </div>
                    <div className="mt-2 input-form">
                      <label>Password</label>
                      <input
                        type="password"
                        className="form-control mt-2"
                        placeholder="password"
                        name="password"
                        value={this.state.password}
                        required
                        onChange={(e) => this.onChange(e)}
                      />
                    </div>
                    <div className="text-right mt-5">
                      <button type="button" className="btn w-24 btn-secondary mr-1" onClick={() => this.cancel()}>
                        Cancel
                      </button>
                      <button type="button" className={`btn w-24 ${!this.state.proses ? "btn-primary" : "btn-secondary"}`} onClick={() => this.login()}>
                        Login
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

export default PejabatPengadaan;
