import React, { Component } from "react";
import Cookies from "js-cookie";
import axios from "axios";
import Modal from "../master/Modal";

class ModalPilihPokja extends Component {
  constructor(props) {
    super(props);
    this.state = {
      jumlah: 3,
      pokja: [],
      proses: false,
    };
  }

  // static getDerivedStateFromProps(nextProps, prevState) {
  //   return {
  //     jumlah: nextProps.panitia.length,
  //   };
  // }

  componentDidUpdate(prevProps) {
    //Typical usage, don't forget to compare the props
    if (this.props.panitia !== prevProps.panitia) {
      this.setState({
        pokja: this.props.panitia.map((e, idx) => {
          e.id = idx.toString();
          return e;
        }),
        jumlah: this.props.panitia.length,
      });
    }
  }

  renderPilihan() {
    let pilihan = [];

    for (let i = 0; i < this.state.jumlah; i++) {
      pilihan.push(
        <div className="col-span-12 mt-3" key={i}>
          <label>Pilih Pokja {i + 1}</label>
          <select
            className="form-select"
            value={this.state.pokja[i] ? this.state.pokja[i].user_spse : ""}
            name={i}
            placeholder="Pilih Pokja"
            onChange={(e) => this.pilihPokja(e)}
          >
            <option value=""> Pilih Pokja</option>
            {this.props.pokja.map((e, idx) => {
              return (
                <option value={`${e.user_spse}`} key={e.user_spse}>
                  {e.user_nama} ({e.selesai}/{e.banyak})
                </option>
              );
            })}
          </select>
        </div>
      );
    }
    return pilihan;
  }

  tambahPilihan() {
    let jumlah = this.state.jumlah;
    this.setState({
      jumlah: jumlah + 2,
    });
  }

  kurangPilihan() {
    let jumlah = this.state.jumlah;
    this.setState({
      jumlah: jumlah - 2,
      pokja: this.state.pokja.slice(0, jumlah - 2),
    });
  }

  componentDidMount() {}

  simpanPokja() {
    let jumlah = this.state.pokja.length % 2;
    if (jumlah !== 1) {
      alert("Jumlah Pokja harus Ganjil");
      return false;
    }
    this.setState({ proses: true });
    let formData = {
      paket_id: this.props.paket.paket_id,
      tahun: Cookies.get("tahun"),
      pokja: this.state.pokja,
      panitia: this.props.panitia,
      ppk: this.props.paket.paket_ppk,
      nama_ttd: Cookies.get("nama"),
      jabatan: Cookies.get("jabatan"),
      nip: Cookies.get("nip"),
      pangkat: Cookies.get("pangkat"),
      username: Cookies.get("username"),
    };
    let url, mode;
    if (this.props.panitia.length == 0) {
      url = process.env.REACT_APP_APIURL + "panitia/add";
      mode = "add";
    } else {
      url = process.env.REACT_APP_APIURL + "panitia/update";
      mode = "update";
    }
    axios
      .post(`${url}`, formData, {
        headers: { Authorization: "Bearer " + Cookies.get("auth-token") },
      })
      .then((response) => {
        this.setState({ proses: false });
        this.props.pilihPokja(mode);
      });
  }

  pilihPokja(e) {
    let pokja = this.state.pokja;
    const name = e.target.name;
    const value = e.target.value;

    let idx = pokja.findIndex((a) => a.id === name);
    let sudah = pokja.findIndex((a) => a.value === value);
    if (sudah !== -1) {
      alert("pokja tidak boleh sama");
      return false;
    }
    if (idx === -1) {
      pokja.push({ id: name, value: value, jabatan: "A" });
    } else {
      pokja[idx] = { id: name, value: value, jabatan: "A" };
    }
    this.setState({
      pokja: pokja,
    });
  }

  tombolKurang() {
    if (this.state.jumlah > 3) {
      return (
        <button type="button" className="btn w-20 btn-dark mr-2" onClick={() => this.kurangPilihan()}>
          Kurang
        </button>
      );
    }
  }

  render() {
    return (
      <Modal>
        <div className="modal" id="pilih_pokja">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="flex items-center px-5 py-5 sm:py-3 border-b border-gray-200">
                <h2 className="font-medium text-base mr-auto">Pilih Pokja</h2>
              </div>
              <div className="p-5">{this.renderPilihan()}</div>
              <div className="px-5 py-3 text-right border-gray-200">
                <button type="button" className="btn w-20 bg-gray-200 mr-2" data-dismiss="modal">
                  Cancel
                </button>
                {this.tombolKurang()}
                <button
                  type="button"
                  className={`btn w-20 ${this.state.proses ? "bg-gray-200" : "bg-theme-11 text-white"} mr-2 tambah_pilihan`}
                  disabled={this.state.proses}
                  onClick={() => this.tambahPilihan()}
                >
                  Tambah
                </button>
                <button
                  type="button"
                  className={`btn w-20 ${this.state.proses ? "bg-gray-200" : "bg-theme-1 text-white"} simpan_pokja`}
                  disabled={this.state.proses}
                  onClick={() => this.simpanPokja()}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

export default ModalPilihPokja;
