import React, { Component } from "react";
import cash from "cash-dom";
import "@left4code/tw-starter/dist/js/dropdown";
import Cookies from "js-cookie";
import feather from "feather-icons";
import { Link } from "react-router-dom";
class Topbar extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    feather.replace({
      "stroke-width": 1.5,
    });
    this.topbar();
    // $(".top-bar, .top-bar-boxed")
    //   .find(".search")
    //   .find("input")
    //   .each(function () {
    //     $(this).on("focus", function () {
    //       $(".top-bar, .top-bar-boxed").find(".search-result").addClass("show");
    //     });

    //     $(this).on("focusout", function () {
    //       $(".top-bar, .top-bar-boxed").find(".search-result").removeClass("show");
    //     });
    //   });
  }

  topbar() {
    cash(".top-bar, .top-bar-boxed")
      .find(".search")
      .find("input")
      .each(function () {
        cash(this).on("focus", function () {
          cash(".top-bar, .top-bar-boxed").find(".search-result").addClass("show");
        });

        cash(this).on("focusout", function () {
          cash(".top-bar, .top-bar-boxed").find(".search-result").removeClass("show");
        });
      });
  }

  render() {
    return (
      <div className="top-bar">
        <div className="-intro-x breadcrumb mr-auto hidden sm:flex">
          <a href="">Application</a>
          <i data-feather="chevron-right" className="breadcrumb__icon"></i>
          <a href="" className="breadcrumb--active">
            Dashboard
          </a>
        </div>
        <div className="intro-x relative mr-3 sm:mr-6">
          <div className="search hidden sm:block">
            <input type="text" className="search__input form-control border-transparent placeholder-theme-13" placeholder="Search..." />
            <i data-feather="search" className="search__icon dark:text-gray-300"></i>
          </div>
          <a className="notification sm:hidden" href="">
            <i data-feather="search" className="notification__icon"></i>
          </a>
          <div className="search-result">
            <div className="search-result__content">
              <div className="search-result__content__title">Pages</div>
              <div className="mb-5">
                <a href="" className="flex items-center">
                  <div className="w-8 h-8 bg-theme-18 text-theme-9 flex items-center justify-center rounded-full">
                    <i className="w-4 h-4" data-feather="inbox"></i>
                  </div>
                  <div className="ml-3">Mail Settings</div>
                </a>
                <a href="" className="flex items-center mt-2">
                  <div className="w-8 h-8 bg-theme-17 text-theme-11 flex items-center justify-center rounded-full">
                    <i className="w-4 h-4" data-feather="users"></i>
                  </div>
                  <div className="ml-3">Users & Permissions</div>
                </a>
                <a href="" className="flex items-center mt-2">
                  <div className="w-8 h-8 bg-theme-14 text-theme-10 flex items-center justify-center rounded-full">
                    <i className="w-4 h-4" data-feather="credit-card"></i>
                  </div>
                  <div className="ml-3">Transactions Report</div>
                </a>
              </div>
              <div className="search-result__content__title">Users</div>
              <div className="mb-5">
                <a href="" className="flex items-center mt-2">
                  <div className="w-8 h-8 image-fit">
                    <img alt="Midone Tailwind HTML Admin Template" className="rounded-full" src="dist/images/profile-13.jpg" />
                  </div>
                  <div className="ml-3">Angelina Jolie</div>
                  <div className="ml-auto w-48 truncate text-gray-600 text-xs text-right">angelinajolie@left4code.com</div>
                </a>
                <a href="" className="flex items-center mt-2">
                  <div className="w-8 h-8 image-fit">
                    <img alt="Midone Tailwind HTML Admin Template" className="rounded-full" src="dist/images/profile-2.jpg" />
                  </div>
                  <div className="ml-3">Johnny Depp</div>
                  <div className="ml-auto w-48 truncate text-gray-600 text-xs text-right">johnnydepp@left4code.com</div>
                </a>
                <a href="" className="flex items-center mt-2">
                  <div className="w-8 h-8 image-fit">
                    <img alt="Midone Tailwind HTML Admin Template" className="rounded-full" src="dist/images/profile-14.jpg" />
                  </div>
                  <div className="ml-3">Russell Crowe</div>
                  <div className="ml-auto w-48 truncate text-gray-600 text-xs text-right">russellcrowe@left4code.com</div>
                </a>
                <a href="" className="flex items-center mt-2">
                  <div className="w-8 h-8 image-fit">
                    <img alt="Midone Tailwind HTML Admin Template" className="rounded-full" src="dist/images/profile-6.jpg" />
                  </div>
                  <div className="ml-3">Al Pacino</div>
                  <div className="ml-auto w-48 truncate text-gray-600 text-xs text-right">alpacino@left4code.com</div>
                </a>
              </div>
              <div className="search-result__content__title">Products</div>
              <a href="" className="flex items-center mt-2">
                <div className="w-8 h-8 image-fit">
                  <img alt="Midone Tailwind HTML Admin Template" className="rounded-full" src="dist/images/preview-9.jpg" />
                </div>
                <div className="ml-3">Samsung Galaxy S20 Ultra</div>
                <div className="ml-auto w-48 truncate text-gray-600 text-xs text-right">Smartphone &amp; Tablet</div>
              </a>
              <a href="" className="flex items-center mt-2">
                <div className="w-8 h-8 image-fit">
                  <img alt="Midone Tailwind HTML Admin Template" className="rounded-full" src="dist/images/preview-12.jpg" />
                </div>
                <div className="ml-3">Nike Tanjun</div>
                <div className="ml-auto w-48 truncate text-gray-600 text-xs text-right">Sport &amp; Outdoor</div>
              </a>
              <a href="" className="flex items-center mt-2">
                <div className="w-8 h-8 image-fit">
                  <img alt="Midone Tailwind HTML Admin Template" className="rounded-full" src="dist/images/preview-10.jpg" />
                </div>
                <div className="ml-3">Sony Master Series A9G</div>
                <div className="ml-auto w-48 truncate text-gray-600 text-xs text-right">Electronic</div>
              </a>
              <a href="" className="flex items-center mt-2">
                <div className="w-8 h-8 image-fit">
                  <img alt="Midone Tailwind HTML Admin Template" className="rounded-full" src="dist/images/preview-7.jpg" />
                </div>
                <div className="ml-3">Samsung Galaxy S20 Ultra</div>
                <div className="ml-auto w-48 truncate text-gray-600 text-xs text-right">Smartphone &amp; Tablet</div>
              </a>
            </div>
          </div>
        </div>
        <div className="intro-x dropdown mr-auto sm:mr-6">
          <div className="dropdown-toggle notification notification--bullet cursor-pointer" role="button" aria-expanded="false">
            <i data-feather="bell" className="notification__icon dark:text-gray-300"></i>
          </div>
          <div className="notification-content pt-2 dropdown-menu">
            <div className="notification-content__box dropdown-menu__content box dark:bg-dark-6">
              <div className="notification-content__title">Notifications</div>
              <div className="cursor-pointer relative flex items-center ">
                <div className="w-12 h-12 flex-none image-fit mr-1">
                  <img alt="Midone Tailwind HTML Admin Template" className="rounded-full" src="dist/images/profile-13.jpg" />
                  <div className="w-3 h-3 bg-theme-9 absolute right-0 bottom-0 rounded-full border-2 border-white"></div>
                </div>
                <div className="ml-2 overflow-hidden">
                  <div className="flex items-center">
                    <a href="#" className="font-medium truncate mr-5">
                      Under Construction
                    </a>
                    <div className="text-xs text-gray-500 ml-auto whitespace-nowrap">05:09 AM</div>
                  </div>
                  <div className="w-full truncate text-gray-600 mt-0.5">masih sedang dalam proses pengembangan</div>
                </div>
              </div>
              <div className="cursor-pointer relative flex items-center mt-5">
                <div className="w-12 h-12 flex-none image-fit mr-1">
                  <img alt="Midone Tailwind HTML Admin Template" className="rounded-full" src="dist/images/profile-2.jpg" />
                  <div className="w-3 h-3 bg-theme-9 absolute right-0 bottom-0 rounded-full border-2 border-white"></div>
                </div>
                <div className="ml-2 overflow-hidden">
                  <div className="flex items-center">
                    <a href="#" className="font-medium truncate mr-5">
                      Johnny Depp
                    </a>
                    <div className="text-xs text-gray-500 ml-auto whitespace-nowrap">05:09 AM</div>
                  </div>
                  <div className="w-full truncate text-gray-600">
                    It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="intro-x dropdown w-8 h-8">
          <div className="dropdown-toggle w-8 h-8 rounded-full overflow-hidden shadow-lg image-fit zoom-in" role="button" aria-expanded="false">
            <img alt={Cookies.get("nama")} src={`${process.env.REACT_APP_APIURL}uploads/${Cookies.get("foto")}`} />
          </div>
          <div className="dropdown-menu w-56">
            <div className="dropdown-menu__content box bg-theme-26 dark:bg-dark-6 text-white">
              <div className="p-4 border-b border-theme-27 dark:border-dark-3">
                <div className="font-medium">{Cookies.get("nama")}</div>
                <div className="text-xs text-theme-41">{Cookies.get("auth-token") ? Cookies.get("level_name") : "GUEST"}</div>
              </div>
              <div className="p-2">
                <Link to="/profile" className="flex items-center block p-2 transition duration-300 ease-in-out hover:bg-theme-1 dark:hover:bg-dark-3 rounded-md">
                  <i data-feather="user" className="w-4 h-4 mr-2"></i> Profile
                </Link>
                <a href="" className="flex items-center block p-2 transition duration-300 ease-in-out hover:bg-theme-1 dark:hover:bg-dark-3 rounded-md">
                  <i data-feather="help-circle" className="w-4 h-4 mr-2"></i> Help
                </a>
              </div>
              <div className="p-2 border-t border-theme-27 dark:border-dark-3">
                <Link to="/logout" className="flex items-center block p-2 transition duration-300 ease-in-out hover:bg-theme-1 dark:hover:bg-dark-3 rounded-md">
                  <i data-feather="toggle-right" className="w-4 h-4 mr-2"></i>
                  Logout
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Topbar;
