import React, { Component } from "react";
import chart from "chart.js";
import cash from "cash-dom";
import { warna_grafik, warna_label } from "../../Definisi";

class Perkecamatan extends Component {
  constructor(props) {
    super(props);
    this.state = { results: [], total: 0 };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.kecamatan !== this.props.kecamatan) {
      this.renderChart();
      // console.log(this.props.jumlah_paket);
    }
  }

  componentDidMount() {
    this.renderChart();
  }

  renderChart() {
    let lokasi = [];
    var result = [];
    let kec = {};
    this.props.paket.reduce((res, value) => {
      lokasi = value.paket_lokasi.split(",");
      if (lokasi.length > 1) {
        lokasi.map((e) => {
          kec = this.props.kecamatan.find((x) => x.kecamatan_id == e);
          if (!res[kec.kecamatan_nama]) {
            res[kec.kecamatan_nama] = { id: kec.kecamatan_nama, total: 0 };
            result.push(res[kec.kecamatan_nama]);
          }
          res[kec.kecamatan_nama].total += Math.round(value.paket_hps / lokasi.length);
        });
      } else {
        kec = this.props.kecamatan.find((x) => x.kecamatan_id == value.paket_lokasi);
        if (!res[kec.kecamatan_nama]) {
          res[kec.kecamatan_nama] = { id: kec.kecamatan_nama, total: 0 };
          result.push(res[kec.kecamatan_nama]);
        }
        res[kec.kecamatan_nama].total += value.paket_hps;
      }

      return res;
    }, {});

    result.sort((a, b) => b.total - a.total);

    // result.sort((a, b) => a.total - b.total);
    // console.log(result);
    let total = result.map((e) => {
      return e.total;
    });
    // console.log(total);
    this.setState({
      results: result,
      total: total,
    });

    this.props.kecamatan.map((e) => {
      return e.kecamatan_nama;
    });

    if (cash("#report-pie-chart").length) {
      let ctx = cash("#report-pie-chart")[0].getContext("2d");
      new chart(ctx, {
        type: "pie",
        data: {
          labels: result.map((a) => a.id),
          datasets: [
            {
              data: result.map((a) => a.total),
              backgroundColor: warna_grafik,
              hoverBackgroundColor: warna_grafik,
              borderWidth: 5,
              borderColor: cash("html").hasClass("dark") ? "#303953" : "#fff",
            },
          ],
        },
        options: {
          legend: {
            display: false,
          },
          tooltips: {
            callbacks: {
              label: function (tooltipItem, data) {
                var dataset = data.datasets[tooltipItem.datasetIndex];
                var meta = dataset._meta[Object.keys(dataset._meta)[0]];
                var total = meta.total;
                var currentValue = dataset.data[tooltipItem.index];
                var percentage = parseFloat(((currentValue / total) * 100).toFixed(1));
                return percentage + "%";
              },
              title: function (tooltipItem, data) {
                return data.labels[tooltipItem[0].index];
              },
            },
          },
        },
      });
    }
  }

  render() {
    return (
      <div className="col-span-12 sm:col-span-6 lg:col-span-3 mt-8">
        <div className="intro-y flex items-center h-10">
          <h2 className="text-lg font-medium truncate mr-5">Dana Per {process.env.REACT_APP_DAERAH}</h2>
          <a href="#" className="ml-auto text-theme-1 truncate">
            See all
          </a>
        </div>
        <div className="intro-y box p-5 mt-5">
          <canvas className="mt-3" id="report-pie-chart" height="280"></canvas>
          <div className="mt-8">
            {this.state.results.slice(0, 3).map((e, index) => {
              return (
                <div className="flex items-center mt-4" key={index}>
                  <div className={`w-2 h-2 ${warna_label[index]} rounded-full mr-3`}></div>
                  <span className="truncate">{e.id}</span>
                  <div className="h-px flex-1 border border-r border-dashed border-gray-300 mx-3 xl:hidden"></div>
                  <span className="font-medium xl:ml-auto">{((e.total / this.state.total.reduce((a, b) => a + b)) * 100).toFixed(1)}%</span>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}

export default Perkecamatan;
