import React, { Component } from "react";
import chart from "chart.js";
import cash from "cash-dom";
import { warna_grafik, warna_label } from "../../Definisi";

class Kategori2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      jenis_pekerjaan: [],
    };
  }

  renderChart(dari) {
    let jenis_pekerjaan = this.props.jenis_pekerjaan.map((e) => {
      return e.name;
    });
    // console.log(dari, this.props.jumlah_paket);

    if (cash("#report-donut-chart").length) {
      let ctx = cash("#report-donut-chart")[0].getContext("2d");
      new chart(ctx, {
        type: "doughnut",
        data: {
          labels: jenis_pekerjaan,
          datasets: [
            {
              data: this.props.jumlah_paket,
              backgroundColor: warna_grafik,
              hoverBackgroundColor: warna_grafik,
              borderWidth: 5,
              borderColor: cash("html").hasClass("dark") ? "#303953" : "#fff",
            },
          ],
        },
        options: {
          legend: {
            display: false,
          },
          cutoutPercentage: 80,
        },
      });
      // c.destroy();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.jenis_pekerjaan !== this.state.jenis_pekerjaan) {
      this.renderChart("update ");
      // console.log(this.props.jumlah_paket);
    }
  }

  componentDidMount() {
    this.renderChart("mount ");
  }

  render() {
    return (
      <div className="col-span-12 sm:col-span-6 lg:col-span-3 mt-8">
        <div className="intro-y flex items-center h-10">
          <h2 className="text-lg font-medium truncate mr-5">Kategori</h2>
          <a href="#" className="ml-auto text-theme-1 truncate">
            See all
          </a>
        </div>
        <div className="intro-y box p-5 mt-5">
          <canvas className="mt-3" id="report-donut-chart" height="280"></canvas>
          <div className="mt-8">
            {this.props.jenis_pekerjaan.slice(0, 3).map((e, index) => {
              return (
                <div className="flex items-center mt-4" key={index}>
                  <div className={`w-2 h-2 ${warna_label[index]} rounded-full mr-3`}></div>
                  <span className="truncate">{e.name}</span>
                  <div className="h-px flex-1 border border-r border-dashed border-gray-300 mx-3 xl:hidden"></div>
                  <span className="font-medium xl:ml-auto">{Math.round((this.props.jumlah_paket[index] / this.props.total) * 100)}%</span>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}

export default Kategori2;
