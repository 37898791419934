import React, { Component } from "react";
import Cookies from "js-cookie";
import axios from "axios";

import Loading from "../master/Loading";
import feather from "feather-icons";

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      peralatan: [],
      page: 0,
      limit: Cookies.get("perpage"),
      search: "",
      halaman: "",
      loading: false,
    };
  }

  onChange(e) {
    const name = e.target.name;
    const value = e.target.value;

    if (e.target.type === "select-one") {
      Cookies.set("perpage", value);
      this.tableRender(1, value);
    }

    this.setState({
      [name]: value,
    });
  }

  paging() {
    let halaman = [],
      showPage = 5;
    let totalPage = Math.ceil(this.state.peralatan.filter((name) => name.alt_jenis.toLowerCase().includes(this.state.search)).length / Cookies.get("perpage"));
    let endPage = this.state.page % showPage === 0 ? this.state.page + Math.floor(showPage / 2) : Math.ceil(this.state.page / showPage) * showPage;
    let startPage = this.state.page % showPage === 0 ? this.state.page - Math.floor(showPage / 2) : endPage - showPage + 1;

    halaman.push(
      <li key="first">
        <button className="pagination__link" onClick={() => this.tableRender(1, Cookies.get("perpage") * 1)}>
          &lt;&lt;
        </button>
      </li>
    );

    if (this.state.page !== 1) {
      halaman.push(
        <li key="prev">
          <button className="pagination__link" onClick={() => this.tableRender(this.state.page - 1, Cookies.get("perpage") * (this.state.page - 1))}>
            &lt;
          </button>
        </li>
      );
    }

    for (let i = startPage; i <= endPage; i++) {
      if (i > totalPage) break;

      halaman.push(
        <li key={i}>
          <button className={`pagination__link ${this.state.page === i ? "pagination__link--active" : ""}`} onClick={() => this.tableRender(i, Cookies.get("perpage") * i)}>
            {i}
          </button>
        </li>
      );
    }

    if (endPage < totalPage) {
      halaman.push(
        <li key="nextpage">
          <button className="pagination__link">...</button>
        </li>
      );
      halaman.push(
        <li key="akhir">
          <button
            className={`pagination__link ${this.state.page === totalPage ? "pagination__link--active" : ""}`}
            onClick={() => this.tableRender(totalPage, Cookies.get("perpage") * totalPage)}
          >
            {totalPage}
          </button>
        </li>
      );
    }

    if (this.state.page !== totalPage) {
      halaman.push(
        <li key="next">
          <button className="pagination__link" onClick={() => this.tableRender(this.state.page + 1, Cookies.get("perpage") * (this.state.page + 1))}>
            &gt;
          </button>
        </li>
      );
    }
    halaman.push(
      <li key="last">
        <button className="pagination__link" onClick={() => this.tableRender(totalPage, Cookies.get("perpage") * totalPage)}>
          &gt;&gt;
        </button>
      </li>
    );

    halaman.push(
      <li key="hal">
        <div className="pagination__link">
          Halaman {this.state.page} dari {totalPage}
        </div>
      </li>
    );
    return halaman;
  }

  tableRender(hal, limit) {
    this.setState({
      page: hal,
      limit: limit,
    });
  }

  componentDidMount() {
    let url = process.env.REACT_APP_APIURL + "peralatan/" + Cookies.get("tahun");

    axios
      .get(`${url}`, {
        headers: { Authorization: "Bearer " + Cookies.get("auth-token") },
      })
      .then((response) => {
        this.setState(
          {
            peralatan: response.data.peralatan,
            loading: true,
          },
          () => {
            this.tableRender(1, Cookies.get("perpage"));
          }
        );
      });

    feather.replace({
      "stroke-width": 1.5,
    });
  }

  render() {
    let label = {},
      skpd = "";
    return this.state.loading ? (
      <div className="col-span-12">
        <h2 className="intro-y text-lg font-medium mt-10">Data Peralatan</h2>
        <div className="grid grid-cols-12 gap-6 mt-5">
          <div className="intro-y col-span-12 flex flex-wrap sm:flex-no-wrap items-center mt-2">
            <div className="hidden md:block mx-auto text-gray-600">
              Showing {this.state.page * Cookies.get("perpage") - Cookies.get("perpage") + 1} to{" "}
              {this.state.page * Cookies.get("perpage") > this.state.peralatan.length ? this.state.peralatan.length : this.state.page * Cookies.get("perpage")} of{" "}
              {this.state.peralatan.filter((name) => name.alt_jenis.toLowerCase().includes(this.state.search)).length} entries
              {this.state.search.length > 0 ? ` (filtered from ${this.state.peralatan.length} total entries)` : ""}
            </div>
            <div className="w-full sm:w-auto mt-3 sm:mt-0 sm:ml-auto md:ml-0">
              <div className="w-56 relative text-gray-700">
                <input type="text" name="search" className="form-control" placeholder="Search..." onChange={(e) => this.onChange(e)} />
                <i className="w-4 h-4 absolute my-auto inset-y-0 mr-3 right-0" data-feather="search"></i>
              </div>
            </div>
          </div>
          <div className="intro-y col-span-12 overflow-auto lg:overflow-visible">
            <table className="table table-report -mt-2">
              <thead>
                <tr>
                  <th className="">NO</th>
                  <th className="">JENIS</th>
                  <th className="">MERK/TIPE</th>
                  <th className="">KAPASITAS</th>
                  <th className="">JUMLAH</th>
                  <th className="">THN PEMBUATAN</th>
                  <th className="">LOKASI</th>
                  <th className="">KEPEMILIKAN</th>
                  <th className="">VENDOR</th>
                  <th className="">PAKET</th>
                </tr>
              </thead>
              <tbody>
                {this.state.peralatan
                  .filter((ta) => ta.alt_jenis.toLowerCase().includes(this.state.search))
                  .slice(this.state.page * Cookies.get("perpage") - Cookies.get("perpage"), this.state.limit)
                  .map((e, index) => {
                    return (
                      <tr className="intro-x" key={index}>
                        <td className="w-10">{index + 1}</td>
                        <td style={{ width: "15%" }}>{e.alt_jenis}</td>
                        <td style={{ width: "10%" }}>{e.alt_merktipe}</td>
                        <td>{e.alt_kapasitas}</td>
                        <td className="">{e.alt_jumlah}</td>
                        <td className="">{e.alt_thpembuatan}</td>
                        <td className="">{e.alt_lokasi}</td>
                        <td className="">{e.alt_kepemilikan}</td>
                        <td className="truncate">{e.rkn_nama}</td>
                        <td>{e.pkt_nama}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
          <div className="intro-y col-span-12 flex flex-wrap sm:flex-row sm:flex-no-wrap items-center">
            <ul className="pagination">{this.paging()}</ul>
            <select className="w-20 form-select box mt-3 sm:mt-0" onChange={(e) => this.onChange(e)} value={Cookies.get("perpage")}>
              <option value="5">5</option>
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
            </select>
          </div>
        </div>
      </div>
    ) : (
      <Loading />
    );
  }
}

export default Index;
