import React, { Component } from "react";
import ReactDOM from "react-dom";
// import dt from "datatables.net";
// import dtResponsive from "datatables.net-responsive-dt";
import feather from "feather-icons";
// import { Tabulator, reactFormatter } from "tabulator-tables";
import { ReactTabulator, reactFormatter } from "react-tabulator";
// import $ from "jquery";
import Cookies from "js-cookie";
import axios from "axios";
import cash from "cash-dom";
import TomSelect from "tom-select";
import NumberFormat from "react-number-format";
import { withRouter } from "react-router-dom";
import Loading from "../master/Loading";
// import { withRouter } from "react-router-dom";

import { metode_pengadaan_sirup } from "../../Definisi";

function Angka(props) {
  const rowData = props.cell._cell.row.data;
  return <NumberFormat value={rowData.pagu} displayType={"text"} thousandSeparator={true} decimalScale={0} />;
}

function Action(props) {
  const rowData = props.cell._cell.row.data;
  return (
    <button className="btn btn-sm btn-primary w-24 mr-1 mb-2" onClick={() => window.RupComponent.pilihRup(rowData.id)}>
      Pilih RUP
    </button>
  );
}
class Index extends Component {
  constructor(props) {
    super(props);
    window.RupComponent = this;
    this.state = {
      stk_id: 0,
      satker_list: [],
      sumberdana: [],
      sirup: [],
      satker: Cookies.get("satker"),
      proses: false,
      loading: true,
      columns: [],
    };
  }

  onChange(e) {
    const name = e.target.name;
    const value = e.target.value;

    this.setState(
      {
        [name]: value,
      },
      () => {
        if (name == "satker") {
          this.renderAjaxScrollExample();
          Cookies.set("satker", value);
        }
      }
    );
  }

  componentDidMount() {
    // console.log(this.props.location.state);
    // this.tableRender();
    this.getData();
    feather.replace({
      "stroke-width": 1.5,
    });
    // Redraw table onresize
  }

  renderAjaxScrollExample() {
    let _this = this;
    const options = {
      // movableRows: true,
      responsiveLayout: true,
      ajaxFiltering: true,
      // ajaxProgressiveLoad: "scroll",
      // ajaxProgressiveLoadDelay: 200,
      // ajaxProgressiveLoadScrollMargin: 100,
      ajaxURL: `${process.env.REACT_APP_APIURL}sirup/pilih/${Cookies.get("tahun")}/${this.state.satker}`,
      ajaxConfig: {
        headers: {
          Authorization: "Bearer " + Cookies.get("auth-token"),
        },
      },
      paginationDataSent: {
        page: "page",
        size: "per_page", // change 'size' param to 'per_page'
      },
      paginationDataReceived: {
        last_page: "total_pages",
      },
      current_page: 1,
      paginationSize: 10,
      paginationSizeSelector: [5, 10, 50, 100],
      pagination: "remote",
      ajaxResponse: function (url, params, response) {
        return response; //return the response data to tabulator
      },
      ajaxError: function (error) {
        console.log("ajaxError", error);
      },
    };
    const columns = [
      { title: "Kode RUP", field: "id", minWidth: 100, width: 150, headerFilter: "input" },
      { title: "Nama Paket", field: "nama", responsive: 0, headerFilter: "input", minWidth: 180 },
      {
        title: "Sumber Dana",
        field: "paket_anggaran",
        width: 150,
        responsive: 1,
        formatter(cell, formatterParams) {
          try {
            return _this.state.sumberdana.find((e) => e.sd_id == cell.getValue()[0].sumber_dana).sd_nama;
          } catch (err) {
            return "";
          }
        },
      },
      {
        title: "Metode Pemilihan",
        field: "metode_pengadaan",
        width: 200,
        responsive: 2,
        formatter(cell, formatterParams) {
          return metode_pengadaan_sirup.find((e) => e.id == cell.getValue()).name;
        },
      },
      {
        title: "Pagu",
        field: "pagu",
        width: 200,
        hozAlign: "right",
        formatter: reactFormatter(<Angka />),
      },
      {
        title: "Action",
        field: "id",
        responsive: 0,
        width: 150,
        formatter: reactFormatter(
          <Action
            onClick={(name) => {
              this.setState({ selectedName: name });
              alert("name");
            }}
          />
        ),
      },
    ];
    return <ReactTabulator ref={(ref) => (this.ref = ref)} columns={columns} data={[]} options={options} />;
  }

  getData() {
    this.setState({ loading: false });

    axios
      .get(`${process.env.REACT_APP_APIURL}sirup/satker`, {
        headers: { Authorization: "Bearer " + Cookies.get("auth-token") },
      })
      .then((response) => {
        this.setState(
          {
            satker_list: response.data.satker,
            sumberdana: response.data.sumberdana,
            loading: true,
          },
          () => {
            this.tomselect();
            this.renderAjaxScrollExample();
            // this.tableRender();
          }
        );
      });
  }

  pilihRup = (e) => {
    // console.log(e);
    try {
      if (this.props.location.state.prevPath == "edit") this.props.history.push({ pathname: "/paket/edit/" + this.props.location.state.paket_id, state: { rup_id: e } });
      else this.props.history.push("/paket/pengajuan/" + e);
    } catch (err) {
      this.props.history.push("/paket/pengajuan/" + e);
    }
  };

  reloadSirup() {
    let url = `${process.env.REACT_APP_APIURL}sirup/reload/${this.state.satker}/${Cookies.get("tahun")}`;
    this.setState({ proses: true, sirup: [], loading: false });
    axios
      .get(`${url}`, {
        headers: { Authorization: "Bearer " + Cookies.get("auth-token") },
      })
      .then((response) => {
        this.setState(
          {
            sirup: response.data.sirup,
            proses: false,
            loading: true,
          },
          () => {
            this.renderAjaxScrollExample();
            this.tomselect();
          }
        );
      });
  }

  tomselect() {
    cash(".tom-select").each(function () {
      let options = {
        plugins: {
          dropdown_input: {},
        },
      };

      if (cash(this).data("placeholder")) {
        options.placeholder = cash(this).data("placeholder");
      }

      if (cash(this).attr("multiple") !== undefined) {
        options = {
          ...options,
          plugins: {
            ...options.plugins,
            remove_button: {
              title: "Remove this item",
            },
          },
          closeAfterSelect: true,
          persist: false,
          create: true,
        };
      }

      if (cash(this).data("header")) {
        options = {
          ...options,
          plugins: {
            ...options.plugins,
            dropdown_header: {
              title: cash(this).data("header"),
            },
          },
        };
      }

      new TomSelect(this, options);
    });
  }

  render() {
    return this.state.loading ? (
      <div>
        <div className="intro-y flex flex-col sm:flex-row items-center mt-8">
          <h2 className="text-lg font-medium mr-auto">Pilih RUP</h2>
        </div>
        <div className="intro-y flex box p-5 mt-5">
          <select className="tom-select w-full" name="satker" value={this.state.satker} onChange={(e) => this.onChange(e)} data-placeholder="Pilih Satker">
            {this.state.satker_list.map((e, idx) => {
              return (
                <option key={idx} value={e.rup_stk_id}>
                  {e.stk_nama}
                </option>
              );
            })}
          </select>
          <button
            className={`btn w-32 ml-2 mr-2 flex items-center justify-center ml-2 mb-2 ${this.state.proses ? "btn-secondary" : "btn-primary"}`}
            onClick={() => this.reloadSirup()}
            disabled={this.state.proses}
          >
            Tarik Sirup
          </button>
        </div>
        <div className="intro-y box p-5 mt-5">
          <div className="overflow-x-auto scrollbar-hidden">
            <div id="data_sirup" className="mt-5 table-report table-report--tabulator"></div>
            {this.renderAjaxScrollExample()}
          </div>
        </div>
      </div>
    ) : (
      <Loading />
    );
  }
}

export default withRouter(Index);
