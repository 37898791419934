import React, { Component } from "react";
import feather from "feather-icons";
import Cookies from "js-cookie";
import axios from "axios";
import { Link } from "react-router-dom";
import { pembulatan } from "../../Definisi";
import Loading from "../master/Loading";

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ppk: [],
      loading: false,
    };
  }

  componentDidMount() {
    axios
      .get(`${process.env.REACT_APP_APIURL}users/ppk/${Cookies.get("tahun")}`, {
        headers: { Authorization: "Bearer " + Cookies.get("auth-token") },
      })
      .then((response) => {
        this.setState(
          {
            ppk: response.data.ppk,
            loading: true,
          },
          () => {
            feather.replace({
              "stroke-width": 1.5,
            });
          }
        );
      });
  }

  render() {
    return this.state.loading ? (
      <>
        <h2 className="intro-y text-lg font-medium mt-10">Data Seluruh PPK Tahun {Cookies.get("tahun")}</h2>
        <div className="grid grid-cols-12 gap-6 mt-5">
          {this.state.ppk.map((e, idx) => {
            return (
              <div className="intro-y col-span-12 md:col-span-6 lg:col-span-4" key={idx}>
                <div className="box">
                  <div className="flex items-start px-5 pt-5">
                    <div className="w-full flex flex-col lg:flex-row items-center">
                      <div className="w-16 h-16 image-fit">
                        <img alt={e.user_nama} className="rounded-md" src={`${process.env.REACT_APP_APIURL}uploads/${e.user_image}`} />
                      </div>
                      <div className="lg:ml-4 text-center lg:text-left mt-3 lg:mt-0">
                        {e.user_nama}
                        <div className="text-gray-600 text-xs">{e.user_jabatan}</div>
                      </div>
                    </div>
                  </div>
                  <div className="text-center lg:text-left p-5">
                    <div>
                      Jumlah Total Paket: {e.banyak} paket / {pembulatan(e.total_pagu)}
                    </div>
                    <div className="flex items-center justify-center lg:justify-start text-gray-600 mt-5">
                      <i data-feather="mail" className="w-3 h-3 mr-2"></i> {e.user_email}
                    </div>
                    <div className="flex items-center justify-center lg:justify-start text-gray-600 mt-1">
                      <i data-feather="phone" className="w-3 h-3 mr-2"></i> {e.user_nohp}
                    </div>
                  </div>
                  <div className="text-center lg:text-right p-5 border-t border-gray-200">
                    <Link to={`/paket/ppk/${e.user_spse}`} className="btn btn-primary mr-2">
                      Lihat Paket
                    </Link>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </>
    ) : (
      <Loading />
    );
  }
}

export default Index;
