import React, { Component } from "react";
import { RingLoader } from "react-spinners";

import "./Loading.css";

const override = {
  display: "block",
  margin: "0 auto",
};

class Loading extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  componentDidMount() {
    this.setState({
      loading: false,
    });
  }

  render() {
    return (
      <div className="col-span-12 intro-y ipl-progress-indicator" id="ipl-progress-indicator">
        <div className="ipl-progress-indicator-head">
          <div className="first-indicator"></div>
          <div className="second-indicator"></div>
        </div>
        <div className="insp-logo-frame text-center">
          <RingLoader color={"#71b6f9"} css={override} />
          Loading ...
        </div>
      </div>
    );
  }
}

export default Loading;
