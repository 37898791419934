import React, { Component } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import jwt from "jsonwebtoken";
import { withRouter, Link } from "react-router-dom";
import feather from "feather-icons";
import cash from "cash-dom";
import Velocity from "velocity-animate";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tahun: [],
      tahun_now: 0,
      loading: false,
      username: "",
      password: "",
      error: false,
      message: "",
    };
  }

  onSubmit() {
    if (Cookies.get("failed_login") < 5 || Cookies.get("failed_login") === undefined) {
      let formData = {
        username: this.state.username,
        password: this.state.password,
        tahun: this.state.tahun_now,
      };
      axios
        .post(`${process.env.REACT_APP_APIURL}auth/login`, formData)
        .then((response) => {
          Cookies.set("auth-token", response.headers.authorization);
          let res = jwt.decode(response.headers.authorization);
          Cookies.set("username", res.username.toUpperCase());
          Cookies.set("user_spse", response.data.user_spse);
          Cookies.set("nama", res.nama);
          Cookies.set("nip", response.data.nip);
          Cookies.set("jabatan", response.data.jabatan);
          Cookies.set("pangkat", response.data.pangkat);
          Cookies.set("level", res.level);
          Cookies.set("level_name", response.data.level[res.level].name);
          Cookies.set("tahun", res.tahun);
          Cookies.set("foto", response.data.foto);
          if (!Cookies.get("perpage")) Cookies.set("perpage", 5);
          document.title = "Dashboard | " + process.env.REACT_APP_NAME;
          this.props.history.push("/dashboard");
        })
        .catch((err) => {
          console.log(err);
          var in30Minutes = 1 / 48;
          let failed_login = parseInt(Cookies.get("failed_login"));
          !failed_login
            ? Cookies.set("failed_login", 1, {
                expires: in30Minutes,
              })
            : Cookies.set("failed_login", failed_login + 1, {
                expires: in30Minutes,
              });
          this.setState({
            error: true,
            password: "",
            message: err.response.data.message,
          });
          this.inputRef.focus();
          Velocity(cash("#error_message"), "slideDown", {
            duration: 300,
            complete: function (el) {
              setTimeout(() => {
                Velocity(cash(el), "slideUp");
              }, 5000);
            },
          });
        });
    } else {
      this.setState({
        error: true,
        password: "",
        message: "Anda tidak dapat login karena sudah 5x salah!",
      });
      this.inputRef.focus();
      Velocity(cash("#error_message"), "slideDown", {
        duration: 300,
        complete: function (el) {
          setTimeout(() => {
            Velocity(cash(el), "slideUp");
          }, 5000);
        },
      });
    }
  }

  onChange(e) {
    const name = e.target.name;
    const value = e.target.value;
    if (name === "tahun") {
      this.setState({
        tahun_now: value,
      });
    } else {
      this.setState({
        [name]: value,
      });
    }
  }

  componentDidMount() {
    feather.replace({
      "stroke-width": 1.5,
    });

    axios.get(`${process.env.REACT_APP_APIURL}auth/`).then((response) => {
      let token = Cookies.get("auth-token");
      if (token) {
        token = jwt.decode(token);
        if (token.exp > response.data.now) {
          this.props.history.push("/dashboard");
        } else {
          Object.keys(Cookies.get()).forEach(function (cookieName) {
            Cookies.remove(cookieName);
          });
        }
      }

      this.setState({
        tahun: response.data.tahun,
        tahun_now: response.data.tahun_now,
        loading: true,
      });
    });
  }

  render() {
    return (
      <form
        onSubmit={(e) => {
          e.preventDefault();
          this.onSubmit(e);
        }}
      >
        <h2 className="intro-x font-bold text-2xl xl:text-3xl text-center xl:text-left">Sign In</h2>
        <div id="error_message" className={`rounded-md flex items-center px-5 py-4 mb-2 bg-theme-31 text-theme-6 ${this.state.error ? "" : "hidden"}`}>
          <i data-feather="alert-octagon" className="w-6 h-6 mr-2"></i>
          {this.state.message}
        </div>
        <div className="intro-x mt-2 text-gray-500 xl:hidden text-center">A few more clicks to sign in to your account. Manage all your packets in one place</div>
        <div className="intro-x mt-8">
          <input
            type="text"
            className="intro-x login__input form-control py-3 px-4 border-gray-300 block"
            placeholder="Username"
            name="username"
            onChange={(e) => this.onChange(e)}
          />
          <input
            type="password"
            className="intro-x login__input form-control py-3 px-4 border-gray-300 block mt-4"
            placeholder="Password"
            name="password"
            ref={(input) => {
              this.inputRef = input;
            }}
            onChange={(e) => this.onChange(e)}
          />
          <select
            className="intro-x login__input form-select py-3 px-4 border-gray-300 block mt-4"
            value={this.state.tahun_now}
            onChange={(e) => this.onChange(e)}
            name="tahun"
          >
            {this.state.tahun.map((e, index) => {
              return (
                <option value={e} key={index}>
                  Tahun {e}
                </option>
              );
            })}
          </select>
        </div>
        <div className="intro-x flex text-gray-700 text-xs sm:text-sm mt-4">
          <div className="flex items-center mr-auto">
            <input type="checkbox" className="form-check-input border mr-2" id="remember-me" />
            <label className="cursor-pointer select-none" htmlFor="remember-me">
              Remember me
            </label>
          </div>
          <a href="/forgot">Forgot Password?</a>
        </div>
        <div className="intro-x flex mt-5 xl:mt-8 text-center xl:text-left">
          <button
            type="submit"
            className={`btn btn-primary py-3 px-4 w-full xl:w-32 align-top ${
              this.state.username.length > 3 && this.state.password.length > 3 ? "btn-primary" : "btn-secondary"
            } xl:mr-3`}
            disabled={this.state.username.length > 3 && this.state.password.length > 3 ? false : true}
          >
            Login
          </button>
          <Link to="/register" className="btn btn-outline-secondary py-3 px-4 w-full xl:w-32 align-top ml-2">
            Sign Up
          </Link>
        </div>
        <div className="intro-x mt-10 xl:mt-24 text-gray-700 dark:text-gray-600 text-center xl:text-left">
          By signin up, you agree to our
          <br />
          <a className="text-theme-1 dark:text-theme-10" href="/term">
            Terms and Conditions{" "}
          </a>
          &{" "}
          <a className="text-theme-1 dark:text-theme-10" href="/privacy">
            Privacy Policy
          </a>
        </div>
      </form>
    );
  }
}

export default withRouter(Login);
