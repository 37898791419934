import React, { Component } from "react";
import feather from "feather-icons";
import Cookies from "js-cookie";
import axios from "axios";

import Detail from "./_detail";
import Penawaran from "./_penawaran";
import Pokja from "./_pokja";
import Sanggahan from "./_sanggahan";
import Loading from "../master/Loading";

import { withRouter } from "react-router-dom";

class Evaluasi extends Component {
  constructor(props) {
    super(props);
    this.state = {
      paket: {},
      jenis_pekerjaan: [],
      kualifikasi: [],
      kecamatan: [],
      sumberdana: [],
      jenis_kontrak: [],
      sifat_pengadaan: [],
      peserta: [],
      penawaran: [],
      pokja: [],
      sanggahan: [],
      lls_id: 0,
      dok_penawaran: false,
      loading: false,
    };
  }

  componentDidMount() {
    axios
      .get(`${process.env.REACT_APP_APIURL}evaluasi/${this.props.match.params.paket_id}`, {
        headers: { Authorization: "Bearer " + Cookies.get("auth-token") },
      })
      .then((response) => {
        let peserta = response.data.peserta;
        if (response.status != 200) {
          // peserta = [];
        }

        this.setState({
          paket: response.data.paket,
          jenis_pekerjaan: response.data.jenis_pekerjaan,
          kualifikasi: response.data.kualifikasi,
          kecamatan: response.data.kecamatan,
          sumberdana: response.data.sumberdana,
          jenis_kontrak: response.data.jenis_kontrak,
          sifat_pengadaan: response.data.sifat_pengadaan,
          peserta: response.data.peserta,
          pokja: response.data.pokja,
          sanggahan: response.data.sanggahan,
          lls_id: response.data.lls_id,
          penawaran: peserta.filter((e) => e.psr_harga > 0),
          dok_penawaran: response.data.dokpenawaran,
          loading: true,
        });
      });

    feather.replace({
      "stroke-width": 1.5,
    });
  }

  render() {
    return this.state.loading ? (
      <>
        <div className="intro-y flex items-center mt-8">
          <h2 className="text-lg font-medium mr-auto">Evaluasi Paket #{this.state.lls_id}</h2>
        </div>
        <div className="intro-y box px-5 pt-5 mt-5">
          <div className="flex flex-col lg:flex-row border-b border-gray-200 pb-5 -mx-5">
            <div className="flex flex-1 px-5 items-center justify-center lg:justify-start">
              <div className="w-20 h-20 sm:w-24 sm:h-24 flex-none lg:w-32 lg:h-32 image-fit relative">
                <img alt="Foto PPK" className="rounded-full" src={process.env.REACT_APP_APIURL + "uploads/" + this.state.paket.user_image} />
                <div className="absolute mb-1 mr-1 flex items-center justify-center bottom-0 right-0 bg-theme-1 rounded-full p-2">
                  <i className="w-4 h-4 text-white" data-feather="camera"></i>
                </div>
              </div>
              <div className="ml-5">
                <div className="w-24 sm:w-40 truncate sm:whitespace-normal font-medium text-lg">{this.state.paket.user_nama}</div>
                <div className="text-gray-600">{this.state.paket.user_jabatan}</div>
              </div>
            </div>
            <div className="flex mt-6 lg:mt-0 items-center lg:items-start flex-1 flex-col justify-center text-gray-600 px-5 border-l border-r border-gray-200 border-t lg:border-t-0 pt-5 lg:pt-0">
              <div className="truncate sm:whitespace-normal flex items-center">
                <i data-feather="mail" className="w-4 h-4 mr-2"></i> {this.state.paket.user_email}
              </div>
              <div className="truncate sm:whitespace-normal flex items-center mt-3">
                <i data-feather="phone" className="w-4 h-4 mr-2"></i> {this.state.paket.user_nohp}
              </div>
            </div>
            <div className="mt-6 lg:mt-0 flex-1 flex items-center justify-center px-5 border-t lg:border-0 border-gray-200 pt-5 lg:pt-0">
              <div className="text-center rounded-md w-20 py-3">
                <div className="font-semibold text-theme-1 text-lg">{this.state.peserta.length}</div>
                <div className="text-gray-600">Peserta</div>
              </div>
              <div className="text-center rounded-md w-20 py-3">
                <div className="font-semibold text-theme-1 text-lg">{this.state.penawaran.length}</div>
                <div className="text-gray-600">Penawaran</div>
              </div>
              <div className="text-center rounded-md w-20 py-3">
                <div className="font-semibold text-theme-1 text-lg">...</div>
                <div className="text-gray-600">Lulus</div>
              </div>
            </div>
          </div>
          <div className="nav-tabs flex flex-col sm:flex-row justify-center lg:justify-start">
            <a data-toggle="tab" data-target="#dashboard" href="javascript:;" className="py-4 sm:mr-8 active">
              Dashboard
            </a>
            <a data-toggle="tab" data-target="#account-and-profile" href="javascript:;" className="py-4 sm:mr-8">
              Info Lainnya
            </a>
          </div>
        </div>
        <div className="intro-y tab-content mt-5">
          <div className="tab-content__pane active" id="dashboard">
            <div className="grid grid-cols-12 gap-6">
              <Detail
                paket={this.state.paket}
                jenis_pekerjaan={this.state.jenis_pekerjaan}
                kualifikasi={this.state.kualifikasi}
                kecamatan={this.state.kecamatan}
                sumberdana={this.state.sumberdana}
                jenis_kontrak={this.state.jenis_kontrak}
                sifat_pengadaan={this.state.sifat_pengadaan}
              />
              <Penawaran paket={this.state.paket} penawaran={this.state.penawaran} lls_id={this.state.lls_id} dokpenawaran={this.state.dok_penawaran} />
              <Pokja pokja={this.state.pokja} paket={this.state.paket} />
              <Sanggahan sanggahan={this.state.sanggahan} />
            </div>
          </div>
        </div>
      </>
    ) : (
      <Loading />
    );
  }
}

export default withRouter(Evaluasi);
