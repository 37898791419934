import React, { Component } from "react";
import feather from "feather-icons";
import moment from "moment";
import download from "js-file-download";
import cash from "cash-dom";
import "@left4code/tw-starter/dist/js/modal";
import "@left4code/tw-starter/dist/js/tab";
import Cookies from "js-cookie";
import axios from "axios";

import Detail from "./_detail";
import Chat from "./_chat";
import ModalPokja from "./_modalPokja";
import ModalProses from "./_modalProses";
import ModalUpload from "./_modalUpload";
import ModalEditStatus from "./_modalEditStatus";
import ModalPilihPokja from "./_modalPilihPokja";
import ModalPilihPP from "./_modalPilihPP";
import ModalPersetujuanAuditor from "./_ModalPersetujuanAuditor";
import ModalTidakSepakat from "./_ModalTidakSepakat";
import Files from "./_Files";
import History from "./_History";

import Loading from "../master/Loading";
import SuccessNotif from "../master/SuccessNotif";

import { tombol_action, tombol_status } from "../../Definisi";

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      paket: [],
      chat: [],
      dokumen: [],
      history: [],
      jenis_pekerjaan: [],
      metode_pemilihan: [],
      kualifikasi: [],
      jenis_dokumen: [],
      jenis: "",
      jenis_kontrak: [],
      sifat_pengadaan: [],
      satker: [],
      sumberdana: [],
      panitia: [],
      kecamatan: "",
      file: "",
      new_status: -2,
      pokja: [],
      pp: [],
      modePilih: "",
      sirup: [],
      proses: false,
      loading: false,
      pesan: "",
      audit: false,
    };
  }

  onPrevent(e) {
    e.preventDefault();
  }

  onChange(e) {
    const name = e.target.name;
    const tipe = e.target.type;
    let value = e.target.value;

    if (tipe === "file") {
      value = e.target.files[0];
    }

    this.setState({
      [name]: value,
    });
  }

  componentDidMount() {
    // console.log("ID: ", this.props.match.params.id);
    axios
      .get(`${process.env.REACT_APP_APIURL}paket/detail/${this.props.match.params.id}`, {
        headers: { Authorization: "Bearer " + Cookies.get("auth-token") },
      })
      .then((response) => {
        this.setState({
          paket: response.data.paket,
          chat: response.data.chat,
          dokumen: response.data.dokumen,
          history: response.data.history,
          satker: response.data.satker,
          jenis_pekerjaan: response.data.jenis_pekerjaan,
          kualifikasi: response.data.kualifikasi,
          jenis_kontrak: response.data.jenis_kontrak,
          sifat_pengadaan: response.data.sifat_pengadaan,
          kecamatan: response.data.kecamatan,
          sumberdana: response.data.sumberdana,
          metode_pemilihan: response.data.metode_pemilihan,
          loading: true,
        });
        if (Cookies.get("level") == 3) {
          if (response.data.paket.audit_visited == null) {
            cash("#persetujuanauditor").modal("show");
            let _this = this;
            cash("#persetujuanauditor").modal("on.hide", function (e) {
              if (!_this.state.audit) _this.props.history.push("/paket");
            });
          }
        }
      });

    feather.replace({
      "stroke-width": 1.5,
    });
  }

  showModal(o, element) {
    let url;
    if (typeof o !== "string") o.preventDefault();
    if (element === "#upload_file") {
      axios
        .get(`${process.env.REACT_APP_APIURL}dokumen/jenis/${this.state.paket.paket_jenispekerjaan}`, {
          headers: { Authorization: "Bearer " + Cookies.get("auth-token") },
        })
        .then((response) => {
          this.setState({
            jenis_dokumen: response.data.dokumen,
          });
          cash(element).modal("show");
        });
    } else if (element === "#modal_pokja") {
      if (this.state.paket.paket_metode == 8) {
        url = `${process.env.REACT_APP_APIURL}pp/paketpp/${this.state.paket.paket_id}`;
      } else {
        url = `${process.env.REACT_APP_APIURL}panitia/paket/${this.state.paket.paket_id}`;
      }
      axios
        .get(url, {
          headers: { Authorization: "Bearer " + Cookies.get("auth-token") },
        })
        .then((response) => {
          this.setState({
            panitia: response.data.panitia,
          });
          cash(element).modal("show");
        });
    } else if (element === "#edit_status") {
      cash(element).modal("show");
    } else if (element === "#pilih_pokja") {
      //5  = sk dibuat
      let sudah = this.state.history.findIndex((e) => e.history_status == 5);
      if (sudah !== -1) {
        axios
          .get(`${process.env.REACT_APP_APIURL}panitia/paket/${this.state.paket.paket_id}`, {
            headers: { Authorization: "Bearer " + Cookies.get("auth-token") },
          })
          .then((response) => {
            this.setState({
              panitia: response.data.panitia,
            });
          });
      }
      cash(element).modal("show");
    } else if (element === "#modal_proses") {
      this.setState({
        loading: false,
      });
      if (this.state.paket.paket_metode == 8) {
        //untuk PL
        url = `${process.env.REACT_APP_APIURL}sirup/pilihbypaketpl/${this.state.paket.paket_rup}`;
      } else {
        url = `${process.env.REACT_APP_APIURL}sirup/pilihbypaket/${this.state.paket.paket_rup}`;
      }
      axios
        .get(url, {
          headers: { Authorization: "Bearer " + Cookies.get("auth-token") },
        })
        .then((response) => {
          this.setState(
            {
              sirup: response.data.sirup,
              loading: true,
            },
            () => {
              cash(element).modal("show");
            }
          );
        });
    }
  }

  downloadSK(e) {
    e.preventDefault();
    this.setState({ loading: false });
    let url = `${process.env.REACT_APP_APIURL}sk/download/${this.state.paket.paket_id}/${Cookies.get("username")}`;
    if (this.state.paket.paket_metode == 8) {
      url = `${process.env.REACT_APP_APIURL}sk/download_pl/${this.state.paket.paket_id}/${Cookies.get("username")}`;
    }
    axios
      .get(url, {
        headers: { Authorization: "Bearer " + Cookies.get("auth-token") },
        responseType: "blob",
      })
      .then((response) => {
        let filename = response.headers["content-disposition"].split("filename=")[1].replaceAll('"', "");
        download(response.data, filename);
        this.setState({ loading: true });
      });
  }

  buttonAction(act) {
    let url,
      formData,
      paket = this.state.paket,
      status,
      history = this.state.history;
    switch (act) {
      case "upload":
        this.showModal(act, "#upload_file");
        break;
      case "pengajuan":
        this.props.history.push("/paket/pengajuan/" + this.state.paket.paket_rup + "/" + this.state.paket.paket_id);
        break;
      case "pengajuan_ulang":
        status = 1;
        url = process.env.REACT_APP_APIURL + "history/add";
        formData = {
          paket_id: this.state.paket.paket_id,
          status: status,
          user: Cookies.get("username"),
          panitia: Cookies.get("level"),
        };

        axios
          .post(`${url}`, formData, {
            headers: { Authorization: "Bearer " + Cookies.get("auth-token") },
          })
          .then((response) => {
            paket.paket_status = status;
            history.unshift({ history_status: status, history_user: Cookies.get("username"), history_date: moment(new Date()).format("YYYY-MM-DD HH:mm:ss") });
            this.setState({
              paket: paket,
              history: history,
            });
          });
        break;
      case "batal":
        status = -1;
        url = process.env.REACT_APP_APIURL + "history/add";
        formData = {
          paket_id: this.state.paket.paket_id,
          status: status,
          user: Cookies.get("username"),
          panitia: Cookies.get("level"),
        };

        axios
          .post(`${url}`, formData, {
            headers: { Authorization: "Bearer " + Cookies.get("auth-token") },
          })
          .then((response) => {
            paket.paket_status = status;
            history.unshift({ history_status: status, history_user: Cookies.get("username"), history_date: moment(new Date()).format("YYYY-MM-DD HH:mm:ss") });
            this.setState({
              paket: paket,
              history: history,
            });
          });
        break;
      case "lengkap":
        status = 3;
        url = process.env.REACT_APP_APIURL + "history/add";
        formData = {
          paket_id: this.state.paket.paket_id,
          status: status,
          user: Cookies.get("username"),
          panitia: Cookies.get("level"),
        };

        axios
          .post(`${url}`, formData, {
            headers: { Authorization: "Bearer " + Cookies.get("auth-token") },
          })
          .then((response) => {
            paket.paket_status = status;
            history.unshift({ history_status: status, history_user: Cookies.get("username"), history_date: moment(new Date()).format("YYYY-MM-DD HH:mm:ss") });
            this.setState({
              paket: paket,
              history: history,
            });
          });
        break;
      case "tdk_lengkap":
        status = 2;
        url = process.env.REACT_APP_APIURL + "history/add";
        formData = {
          paket_id: this.state.paket.paket_id,
          status: status,
          user: Cookies.get("username"),
          panitia: Cookies.get("level"),
        };

        axios
          .post(`${url}`, formData, {
            headers: { Authorization: "Bearer " + Cookies.get("auth-token") },
          })
          .then((response) => {
            paket.paket_status = status;
            history.unshift({ history_status: status, history_user: Cookies.get("username"), history_date: moment(new Date()).format("YYYY-MM-DD HH:mm:ss") });
            this.setState({
              paket: paket,
              history: history,
            });
          });
        break;
      case "tunjuk_pokja":
        this.setState({
          loading: false,
        });
        axios
          .get(`${process.env.REACT_APP_APIURL}panitia/list_pokja/${Cookies.get("tahun")}`, {
            headers: { Authorization: "Bearer " + Cookies.get("auth-token") },
          })
          .then((response) => {
            this.setState({
              pokja: response.data.pokja,
              loading: true,
            });
            this.showModal(act, "#pilih_pokja");
          });
        break;
      case "tunjuk_pp":
        this.setState({
          loading: false,
        });
        axios
          .get(`${process.env.REACT_APP_APIURL}pp`, {
            headers: { Authorization: "Bearer " + Cookies.get("auth-token") },
          })
          .then((response) => {
            // console.log(response);
            this.setState({
              pp: response.data.pp,
              loading: true,
            });
            cash("#pilih_pp").modal("show");
          });
        break;
      case "edit_pp":
        this.setState({
          loading: false,
        });
        axios
          .get(`${process.env.REACT_APP_APIURL}pp`, {
            headers: { Authorization: "Bearer " + Cookies.get("auth-token") },
          })
          .then((response) => {
            this.setState({
              pp: response.data.pp,
              loading: true,
            });
            cash("#pilih_pp").modal("show");
          });
        break;
      case "edit_pokja":
        this.setState({
          loading: false,
        });
        axios
          .get(`${process.env.REACT_APP_APIURL}panitia/list_pokja/${Cookies.get("tahun")}/${this.state.paket.paket_id}`, {
            headers: { Authorization: "Bearer " + Cookies.get("auth-token") },
          })
          .then((response) => {
            // console.log(response);
            this.setState({
              pokja: response.data.pokja,
              panitia: response.data.panitia,
              loading: true,
            });
            this.showModal(act, "#pilih_pokja");
          });
        break;
      case "reviu":
        status = 6;
        url = process.env.REACT_APP_APIURL + "history/add";
        formData = {
          paket_id: this.state.paket.paket_id,
          status: status,
          user: Cookies.get("username"),
          panitia: Cookies.get("level"),
        };

        axios
          .post(`${url}`, formData, {
            headers: { Authorization: "Bearer " + Cookies.get("auth-token") },
          })
          .then((response) => {
            paket.paket_status = status;
            history.unshift({ history_status: status, history_user: Cookies.get("username"), history_date: moment(new Date()).format("YYYY-MM-DD HH:mm:ss") });
            this.setState({
              paket: paket,
              history: history,
            });
          });
        break;
      case "ppk_sepakat":
        status = 7;
        url = process.env.REACT_APP_APIURL + "history/add";
        formData = {
          paket_id: this.state.paket.paket_id,
          status: status,
          user: Cookies.get("username"),
          panitia: Cookies.get("level"),
        };

        axios
          .post(`${url}`, formData, {
            headers: { Authorization: "Bearer " + Cookies.get("auth-token") },
          })
          .then((response) => {
            paket.paket_status = status;
            history.unshift({ history_status: status, history_user: Cookies.get("username"), history_date: moment(new Date()).format("YYYY-MM-DD HH:mm:ss") });
            this.setState({
              paket: paket,
              history: history,
            });
          });
        break;
      case "pokja_sepakat":
        status = 8;
        url = process.env.REACT_APP_APIURL + "history/add";
        formData = {
          paket_id: this.state.paket.paket_id,
          status: status,
          user: Cookies.get("username"),
          panitia: Cookies.get("level"),
        };

        axios
          .post(`${url}`, formData, {
            headers: { Authorization: "Bearer " + Cookies.get("auth-token") },
          })
          .then((response) => {
            paket.paket_status = status;
            history.unshift({ history_status: status, history_user: Cookies.get("username"), history_date: moment(new Date()).format("YYYY-MM-DD HH:mm:ss") });
            this.setState({
              paket: paket,
              history: history,
            });
          });
        break;
      case "tidak_sepakat":
        cash("#tidak_sepakat").modal("show");
        // status = 0;
        // url = process.env.REACT_APP_APIURL + "history/add";
        // formData = {
        //   paket_id: this.state.paket.paket_id,
        //   status: status,
        //   user: Cookies.get("username"),
        //   panitia: Cookies.get("level"),
        // };

        // axios
        //   .post(`${url}`, formData, {
        //     headers: { Authorization: "Bearer " + Cookies.get("auth-token") },
        //   })
        //   .then((response) => {
        //     paket.paket_status = status;
        //     history.unshift({ history_status: status, history_user: Cookies.get("username"), history_date: moment(new Date()).format("YYYY-MM-DD HH:mm:ss") });
        //     this.setState({
        //       paket: paket,
        //       history: history,
        //     });
        //   });
        break;
      case "proses":
        this.showModal(act, "#modal_proses");
        break;
      case "evaluasi":
        this.props.history.push("/evaluasi/" + this.state.paket.paket_id);
        break;
      case "tender_ulang":
        status = 13;
        url = process.env.REACT_APP_APIURL + "history/add";
        formData = {
          paket_id: this.state.paket.paket_id,
          status: status,
          user: Cookies.get("username"),
          panitia: Cookies.get("level"),
        };

        axios
          .post(`${url}`, formData, {
            headers: { Authorization: "Bearer " + Cookies.get("auth-token") },
          })
          .then((response) => {
            paket.paket_status = status;
            history.unshift({ history_status: status, history_user: Cookies.get("username"), history_date: moment(new Date()).format("YYYY-MM-DD HH:mm:ss") });
            this.setState({
              paket: paket,
              history: history,
            });
          });
        break;
      default:
        break;
    }
  }

  tombolUpdateStatus() {
    if (Cookies.get("level") == 0) {
      return (
        <a
          href="/#editstats"
          onClick={(o) => this.showModal(o, "#edit_status")}
          className="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
        >
          <i data-feather="edit-3" className="w-4 h-4 mr-2"></i> Edit Status
        </a>
      );
    }
  }

  tombol() {
    let status = tombol_status.find((a) => a.id === this.state.paket.paket_status) ? tombol_status.find((a) => a.id === this.state.paket.paket_status).tombol : [];
    let btn = status.map((e, idx) => {
      if (tombol_action[e].user_level.includes(Cookies.get("level"))) {
        if (this.state.paket.paket_status == 3 && (e == 4 || e == 17)) {
          //tahap dok lengkap
          if (this.state.paket.paket_metode == 8 && e == 17) {
            return (
              <button
                key={idx}
                className={`btn mb-2 flex items-center justify-center ${tombol_action[e].color} mr-2`}
                onClick={() => this.buttonAction(tombol_action[e].action)}
              >
                <i className="w-4 h-4 mr-2" data-feather={tombol_action[e].icon}></i> {tombol_action[e].label}
              </button>
            );
          } else if (this.state.paket.paket_metode != 8 && e == 4) {
            return (
              <button
                key={idx}
                className={`btn mb-2 flex items-center justify-center ${tombol_action[e].color} mr-2`}
                onClick={() => this.buttonAction(tombol_action[e].action)}
              >
                <i className="w-4 h-4 mr-2" data-feather={tombol_action[e].icon}></i> {tombol_action[e].label}
              </button>
            );
          }
        } else if (this.state.paket.paket_status == 5 && (e == 5 || e == 18)) {
          //tahap sk dibuat
          if (this.state.paket.paket_metode == 8 && e == 18) {
            return (
              <button
                key={idx}
                className={`btn mb-2 flex items-center justify-center ${tombol_action[e].color} mr-2`}
                onClick={() => this.buttonAction(tombol_action[e].action)}
              >
                <i className="w-4 h-4 mr-2" data-feather={tombol_action[e].icon}></i> {tombol_action[e].label}
              </button>
            );
          } else if (this.state.paket.paket_metode != 8 && e == 5) {
            return (
              <button
                key={idx}
                className={`btn mb-2 flex items-center justify-center ${tombol_action[e].color} mr-2`}
                onClick={() => this.buttonAction(tombol_action[e].action)}
              >
                <i className="w-4 h-4 mr-2" data-feather={tombol_action[e].icon}></i> {tombol_action[e].label}
              </button>
            );
          }
        } else if (this.state.paket.paket_status == 7 && (e == 8 || e == 19)) {
          //tahap sk dibuat
          if (this.state.paket.paket_metode == 8 && e == 19) {
            return (
              <button
                key={idx}
                className={`btn mb-2 flex items-center justify-center ${tombol_action[e].color} mr-2`}
                onClick={() => this.buttonAction(tombol_action[e].action)}
              >
                <i className="w-4 h-4 mr-2" data-feather={tombol_action[e].icon}></i> {tombol_action[e].label}
              </button>
            );
          } else if (this.state.paket.paket_metode != 8 && e == 8) {
            return (
              <button
                key={idx}
                className={`btn mb-2 flex items-center justify-center ${tombol_action[e].color} mr-2`}
                onClick={() => this.buttonAction(tombol_action[e].action)}
              >
                <i className="w-4 h-4 mr-2" data-feather={tombol_action[e].icon}></i> {tombol_action[e].label}
              </button>
            );
          }
        } else {
          return (
            <button
              key={idx}
              className={`btn mb-2 flex items-center justify-center ${tombol_action[e].color} mr-2`}
              onClick={() => this.buttonAction(tombol_action[e].action)}
            >
              <i className="w-4 h-4 mr-2" data-feather={tombol_action[e].icon}></i> {tombol_action[e].label}
            </button>
          );
        }
      }
    });
    return btn;
  }

  pilihPokja = (e) => {
    if (e == "add") {
      let url,
        formData,
        paket = this.state.paket,
        status,
        history = this.state.history;

      status = 5;
      url = process.env.REACT_APP_APIURL + "history/add";
      formData = {
        paket_id: this.state.paket.paket_id,
        status: status,
        user: Cookies.get("username"),
        panitia: Cookies.get("level"),
      };

      axios
        .post(`${url}`, formData, {
          headers: { Authorization: "Bearer " + Cookies.get("auth-token") },
        })
        .then((response) => {
          paket.paket_status = status;
          history.unshift({ history_status: status, history_user: Cookies.get("username"), history_date: moment(new Date()).format("YYYY-MM-DD HH:mm:ss") });
          this.setState({
            paket: paket,
            history: history,
          });
        });
    }
    cash("#pilih_pokja").modal("hide");
  };

  pilihPP = (e) => {
    if (e == "add") {
      let url,
        formData,
        paket = this.state.paket,
        status,
        history = this.state.history;

      status = 5;
      url = process.env.REACT_APP_APIURL + "history/add";
      formData = {
        paket_id: this.state.paket.paket_id,
        status: status,
        user: Cookies.get("username"),
        panitia: Cookies.get("level"),
      };

      axios
        .post(`${url}`, formData, {
          headers: { Authorization: "Bearer " + Cookies.get("auth-token") },
        })
        .then((response) => {
          paket.paket_status = status;
          history.unshift({ history_status: status, history_user: Cookies.get("username"), history_date: moment(new Date()).format("YYYY-MM-DD HH:mm:ss") });
          this.setState({
            paket: paket,
            history: history,
          });
        });
    }
    cash("#pilih_pp").modal("hide");
  };

  prosesTender = (e) => {
    if (e == "sukses") {
      let url,
        formData,
        paket = this.state.paket,
        status,
        history = this.state.history;

      status = 11;
      url = process.env.REACT_APP_APIURL + "history/add";
      formData = {
        paket_id: this.state.paket.paket_id,
        status: status,
        user: Cookies.get("username"),
        panitia: Cookies.get("level"),
      };

      axios
        .post(`${url}`, formData, {
          headers: { Authorization: "Bearer " + Cookies.get("auth-token") },
        })
        .then((response) => {
          paket.paket_status = status;
          history.unshift({ history_status: status, history_user: Cookies.get("username"), history_date: moment(new Date()).format("YYYY-MM-DD HH:mm:ss") });
          this.setState({
            paket: paket,
            history: history,
          });
        });
    }
    cash("#modal_proses").modal("hide");
  };

  setujuAudit = () => {
    this.setState({ audit: true });
  };

  updateStatus = (e) => {
    let url,
      formData,
      paket = this.state.paket,
      status,
      history = this.state.history;

    status = e;
    url = process.env.REACT_APP_APIURL + "history/add";
    formData = {
      paket_id: this.state.paket.paket_id,
      status: status,
      user: Cookies.get("username"),
      panitia: Cookies.get("level"),
    };

    axios
      .post(`${url}`, formData, {
        headers: { Authorization: "Bearer " + Cookies.get("auth-token") },
      })
      .then((response) => {
        paket.paket_status = status;
        history.unshift({ history_status: status, history_user: Cookies.get("username"), history_date: moment(new Date()).format("YYYY-MM-DD HH:mm:ss") });
        this.setState({
          paket: paket,
          history: history,
        });
        cash("#edit_status").modal("hide");
      });
  };

  updateDokumen = (e) => {
    this.setState({
      dokumen: e,
      pesan: "File berhasil ditambahkan!",
    });
  };

  loadingPage = (e) => {
    this.setState({
      loading: e,
    });
  };

  tombolSKPokja() {
    if (Cookies.get("level") != 4) {
      return (
        <div className="dropdown">
          <button className="dropdown-toggle btn px-2 box text-gray-700 dark:text-gray-300">
            <span className="w-5 h-5 flex items-center justify-center">
              <i className="w-4 h-4" data-feather="more-horizontal"></i>
            </span>
          </button>
          <div className="dropdown-menu w-40">
            <div className="dropdown-menu__content box dark:bg-dark-1 p-2">
              <a
                href="#"
                onClick={(o) => this.showModal(o, "#modal_pokja")}
                className="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
              >
                <i data-feather="users" className="w-4 h-4 mr-2"></i> {this.state.paket.paket_metode == 8 ? "Pej. Pengadaan" : "Pokja"}
              </a>
              <a
                href="#"
                onClick={(e) => this.downloadSK(e)}
                className="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
              >
                <i data-feather="download" className="w-4 h-4 mr-2"></i> Download SK
              </a>
              {this.tombolUpdateStatus()}
            </div>
          </div>
        </div>
      );
    }
  }

  render() {
    return this.state.loading ? (
      <div>
        <div className="intro-y flex flex-col sm:flex-row items-center mt-8">
          <h2 className="text-lg font-medium mr-auto">
            Detail Paket {this.state.paket.audit_user ? <span className="text-xs px-2 py-1 rounded-full bg-theme-6 text-white">Audited</span> : ""}
          </h2>
          <div className="w-full sm:w-auto flex mt-4 sm:mt-0">
            {this.tombol()}
            {this.tombolSKPokja()}
          </div>
        </div>
        <div className="intro-y chat grid grid-cols-12 gap-5 mt-5">
          <div className="col-span-12 lg:col-span-4 2xl:col-span-3" id="kiri">
            <div className="intro-y pr-1" id="kiri_isi">
              <div className="box p-2">
                <div className="chat__tabs nav nav-tabs justify-center" role="tablist">
                  <a
                    onClick={(e) => this.onPrevent(e)}
                    data-toggle="tab"
                    data-target="#detail"
                    href="/"
                    className="flex-1 py-2 rounded-md text-center active"
                    aria-controls="detail"
                    aria-selected="true"
                  >
                    Details
                  </a>
                  <a
                    onClick={(e) => this.onPrevent(e)}
                    data-toggle="tab"
                    data-target="#files"
                    href="/"
                    className="flex-1 py-2 rounded-md text-center"
                    aria-controls="files"
                    aria-selected="false"
                  >
                    Files
                  </a>
                  <a
                    onClick={(e) => this.onPrevent(e)}
                    data-toggle="tab"
                    data-target="#history"
                    href="/"
                    className="flex-1 py-2 rounded-md text-center"
                    aria-controls="history"
                    aria-selected="false"
                  >
                    History
                  </a>
                </div>
              </div>
            </div>
            <div className="tab-content">
              <Detail
                paket={this.state.paket}
                jenis_pekerjaan={this.state.jenis_pekerjaan}
                kecamatan={this.state.kecamatan}
                sumberdana={this.state.sumberdana}
                jenis_kontrak={this.state.jenis_kontrak}
                sifat_pengadaan={this.state.sifat_pengadaan}
                kualifikasi={this.state.kualifikasi}
                metode_pemilihan={this.state.metode_pemilihan}
                key={this.state.paket}
              />
              <Files dokumen={this.state.dokumen} loadingPage={this.loadingPage} />
              <History history={this.state.history} key={this.state.history} />
            </div>
          </div>
          <Chat paket={this.state.paket} chat={this.state.chat} />
          <ModalPokja panitia={this.state.panitia} paket={this.state.paket} />
          <ModalUpload
            paket={this.state.paket}
            jenis_dokumen={this.state.jenis_dokumen}
            jenis={this.state.jenis}
            dokumen={this.state.dokumen}
            updateDokumen={this.updateDokumen}
          />
          <ModalEditStatus paket={this.state.paket} updateStatus={this.updateStatus} key="modal_status" />
          <ModalPilihPokja pokja={this.state.pokja} paket={this.state.paket} pilihPokja={this.pilihPokja} panitia={this.state.panitia} />
          <ModalPilihPP pp={this.state.pp} paket={this.state.paket} pilihPP={this.pilihPP} />
          <ModalProses sirup={this.state.sirup} paket={this.state.paket} prosesTender={this.prosesTender} />
          <ModalPersetujuanAuditor paket_id={this.state.paket.paket_id} setujuAudit={this.setujuAudit} />
          <ModalTidakSepakat updateStatus={this.updateStatus} />
        </div>
        <SuccessNotif pesan={this.state.pesan} />
      </div>
    ) : (
      <Loading />
    );
  }
}

export default Index;
