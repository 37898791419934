import React, { Component } from "react";
// import validate from "jquery-validation";
import Cookies from "js-cookie";
import axios from "axios";
import cash from "cash-dom";
import Pristine from "pristinejs";
import Toastify from "toastify-js";
import feather from "feather-icons";

class Step2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dokumen: [],
      doklel: [],
      progress: [],
      sudah: [],
      error_message: "",
      dok_error: "",
      proses: false,
      progress: [],
    };
  }

  onChange(e) {
    const nama = e.target.name;
    const tipe = e.target.type;
    let value = e.target.value;

    if (tipe === "file") {
      value = e.target.files[0];

      let doklel = this.state.doklel;
      let idx = doklel.findIndex((i) => i.name === nama);

      if (idx === -1) {
        doklel.push({ name: nama, val: value });
      } else {
        doklel[idx] = { name: nama, val: value };
      }
      this.setState({
        doklel: doklel,
      });
    } else {
      this.setState({
        [nama]: value,
      });
    }
  }

  componentDidMount() {
    axios
      .get(`${process.env.REACT_APP_APIURL}dokumen/jenis/${this.props.jenispekerjaan}`, {
        headers: { Authorization: "Bearer " + Cookies.get("auth-token") },
      })
      .then((response) => {
        this.setState({
          dokumen: response.data.dokumen,
        });
      });
  }

  async onSubmit() {
    let pris;
    cash(".validate-form").each(function () {
      pris = new Pristine(this, {
        classTo: "input-form",
        errorClass: "has-error",
        errorTextParent: "input-form",
        errorTextClass: "text-primary-3 mt-2",
      });
    });
    if (pris.validate()) {
      this.setState({
        proses: true,
      });
      let url = process.env.REACT_APP_APIURL + "dokumen/kirim_pengajuan";
      const formData = new FormData();
      formData.append("tahun", Cookies.get("tahun"));
      formData.append("paket_id", this.props.paket_id);
      formData.append("user", Cookies.get("username"));

      let sudah = this.state.sudah;
      let result;
      // console.log(this.state.doklel);
      for await (let e of this.state.doklel) {
        if (!sudah.includes(e.name)) {
          formData.append(e.name, e.val);
          formData.append("jenis", e.name);
          let progress = this.state.progress;
          let idx = progress.findIndex((i) => i.name === e.name);

          try {
            result = await axios.post(`${url}`, formData, {
              headers: { Authorization: "Bearer " + Cookies.get("auth-token") },
              onUploadProgress: (data) => {
                let prog_angka = Math.round((100 * data.loaded) / data.total);

                if (idx === -1) {
                  progress.push({ name: e.name, val: prog_angka });
                } else {
                  progress[idx] = { name: e.name, val: prog_angka };
                }

                this.setState({
                  progress: progress,
                });
              },
            });
            // console.log(result);
            formData.delete(e.name);
            formData.delete("jenis");
            sudah.push(e.name);
            this.setState({
              sudah: sudah,
            });
          } catch (error) {
            result = false;
            idx = progress.findIndex((i) => i.name === e.name);
            progress[idx] = { name: e.name, val: 0 };
            let dok_error = this.state.dokumen.find((a) => a.dok_kode === e.name);
            this.setState(
              {
                dok_error: dok_error.dok_nama,
                error_message: error.response.data.message,
                progress: progress,
              },
              () => {
                feather.replace({
                  "stroke-width": 1.5,
                });
                Toastify({
                  node: cash("#error-notification-content").clone().removeClass("hidden")[0],
                  duration: 5000,
                  newWindow: true,
                  close: true,
                  gravity: "top",
                  position: "right",
                  stopOnFocus: true,
                }).showToast();
              }
            );
            break;
          }
        }
      }
      if (result) {
        url = process.env.REACT_APP_APIURL + "history/add";
        let formData2 = {
          paket_id: this.props.paket_id,
          status: 1,
          user: Cookies.get("username"),
          panitia: "0",
        };

        axios
          .post(`${url}`, formData2, {
            headers: { Authorization: "Bearer " + Cookies.get("auth-token") },
          })
          .then((response) => {
            console.log(response);
            this.setState({
              proses: false,
            });
            this.props.updateStep(3);
          });
      }
    }
  }

  progressClass(val) {
    if (val == 0) return "w-0";
    else if (val < 8) return "w-1/12";
    else if (val < 16) return "w-1/6";
    else if (val < 21) return "w-1/5";
    else if (val < 26) return "w-1/4";
    else if (val < 33) return "w-1/3";
    else if (val < 41) return "w-2/5";
    else if (val < 42) return "w-5/12";
    else if (val < 51) return "w-1/2";
    else if (val < 59) return "w-7/12";
    else if (val < 60) return "w-3/5";
    else if (val < 67) return "w-2/3";
    else if (val < 75) return "w-3/4";
    else if (val < 80) return "w-4/5";
    else if (val < 84) return "w-5/6";
    else if (val < 92) return "w-11/12";
    else if (val >= 100) return "w-full";
    else return "w-0";
  }

  render() {
    return (
      <div className="px-5 sm:px-20 mt-10 pt-10 border-t border-gray-200">
        <form name="pengajuan" className="validate-form" method="post">
          <div className="font-medium text-base">File Pendukung</div>
          <div className="flex grid grid-cols-12 gap-4 row-gap-5 items-center">
            {this.state.dokumen.map((e, idx) => {
              return (
                <div className="mt-3 col-span-12 sm:col-span-6" key={idx}>
                  <label>
                    {e.dok_nama} {e.dok_wajib == 1 ? "*" : ""}
                  </label>
                  <div className="relative mt-2 input-form">
                    <input
                      type="file"
                      className="input pr-16 w-full border col-span-4"
                      placeholder="belum pilih"
                      name={e.dok_kode}
                      required={e.dok_wajib == 1 ? true : false}
                      onChange={(e) => this.onChange(e)}
                    />
                    <div className="w-full h-4 bg-gray-400 rounded mt-1">
                      <div
                        className={`${this.progressClass(
                          this.state.progress.find((i) => i.name === e.dok_kode) ? this.state.progress.find((i) => i.name === e.dok_kode).val : 0
                        )} progress-bar h-full bg-theme-1 rounded text-center text-xs text-white`}
                        role="progressbar"
                        aria-valuenow="0"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      >
                        {this.state.progress.find((i) => i.name === e.dok_kode) ? this.state.progress.find((i) => i.name === e.dok_kode).val : "0"}%
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </form>
        <div id="error-notification" className="p-5">
          <div className="preview">
            <div className="text-center">
              <div id="error-notification-content" className="toastify-content hidden flex">
                <i className="text-theme-6" data-feather="x"></i>
                <div className="ml-4 mr-4">
                  <div className="font-medium">Info!</div>
                  <div className="text-gray-600 mt-1">
                    {this.state.dok_error}
                    <br />
                    {this.state.error_message}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="intro-y col-span-12 flex items-center justify-center sm:justify-end mt-5">
          <button className="btn w-24 justify-center block btn-secondary text-gray-600" onClick={() => this.props.updateStep(1)}>
            Previous
          </button>
          <button
            type="button"
            onClick={() => this.onSubmit()}
            className={`btn w-24 justify-center block ${this.state.proses ? "btn-secondary" : "btn-primary"} ml-2`}
            disabled={this.state.proses}
          >
            Next
          </button>
        </div>
        <div id="failed-notification-content" className="toastify-content hidden flex">
          <i className="text-theme-6" data-feather="x-circle"></i>
          <div className="ml-4 mr-4">
            <div className="font-medium">Data Belum Lengkap!</div>
            <div className="text-gray-600 mt-1"> Please check the fileld form. </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Step2;
