import React, { Component } from "react";
import Cookies from "js-cookie";
import axios from "axios";
import { Link } from "react-router-dom";
// import $ from "jquery";
import feather from "feather-icons";
import cash from "cash-dom";
import Modal from "../master/Modal";

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dokumen: [],
      jenis_pekerjaan: [],
      dok_id: "",
      page: 0,
      limit: Cookies.get("perpage"),
      search: "",
      halaman: "",
      hapus_notif: "",
    };
  }

  onChange(e) {
    const name = e.target.name;
    const value = e.target.value;

    if (e.target.type === "select-one") {
      Cookies.set("perpage", value);
      this.tableRender(1, value);
    }

    this.setState({
      [name]: value,
    });
  }

  paging() {
    let halaman = [],
      showPage = 5;
    let totalPage = Math.ceil(this.state.dokumen.filter((name) => name.dok_nama.toLowerCase().includes(this.state.search.toLowerCase())).length / Cookies.get("perpage"));
    let endPage = this.state.page % showPage === 0 ? this.state.page + Math.floor(showPage / 2) : Math.ceil(this.state.page / showPage) * showPage;
    let startPage = this.state.page % showPage === 0 ? this.state.page - Math.floor(showPage / 2) : endPage - showPage + 1;

    halaman.push(
      <li key="first">
        <button className="pagination__link" onClick={() => this.tableRender(1, Cookies.get("perpage") * 1)}>
          &lt;&lt;
        </button>
      </li>
    );

    if (this.state.page !== 1) {
      halaman.push(
        <li key="prev">
          <button className="pagination__link" onClick={() => this.tableRender(this.state.page - 1, Cookies.get("perpage") * (this.state.page - 1))}>
            &lt;
          </button>
        </li>
      );
    }

    for (let i = startPage; i <= endPage; i++) {
      if (i > totalPage) break;

      halaman.push(
        <li key={i}>
          <button className={`pagination__link ${this.state.page === i ? "pagination__link--active" : ""}`} onClick={() => this.tableRender(i, Cookies.get("perpage") * i)}>
            {i}
          </button>
        </li>
      );
    }

    if (endPage < totalPage) {
      halaman.push(
        <li key="nextpage">
          <button className="pagination__link">...</button>
        </li>
      );
      halaman.push(
        <li key="akhir">
          <button
            className={`pagination__link ${this.state.page === totalPage ? "pagination__link--active" : ""}`}
            onClick={() => this.tableRender(totalPage, Cookies.get("perpage") * totalPage)}
          >
            {totalPage}
          </button>
        </li>
      );
    }

    if (this.state.page !== totalPage) {
      halaman.push(
        <li key="next">
          <button className="pagination__link" onClick={() => this.tableRender(this.state.page + 1, Cookies.get("perpage") * (this.state.page + 1))}>
            &gt;
          </button>
        </li>
      );
    }
    halaman.push(
      <li key="last">
        <button className="pagination__link" onClick={() => this.tableRender(totalPage, Cookies.get("perpage") * totalPage)}>
          &gt;&gt;
        </button>
      </li>
    );

    halaman.push(
      <li key="hal">
        <div className="pagination__link">
          Halaman {this.state.page} dari {totalPage}
        </div>
      </li>
    );
    return halaman;
  }

  tableRender(hal, limit) {
    this.setState({
      page: hal,
      limit: limit,
    });
  }

  getData() {
    let url = process.env.REACT_APP_APIURL + "dokumen";

    axios
      .get(`${url}`, {
        headers: { Authorization: "Bearer " + Cookies.get("auth-token") },
      })
      .then((response) => {
        this.setState(
          {
            jenis_pekerjaan: response.data.jenis_pekerjaan,
            dokumen: response.data.dokumen,
          },
          () => {
            this.tableRender(1, Cookies.get("perpage"));
          }
        );
      });
  }

  componentDidMount() {
    this.getData();
    feather.replace({
      "stroke-width": 1.5,
    });
  }

  confirmDelete(o, e, element) {
    o.preventDefault();
    this.setState({
      dok_id: e.dok_id,
    });

    cash(element).modal("show");
  }

  delete() {
    let url = process.env.REACT_APP_APIURL + "dokumen/" + this.state.dok_id;

    axios
      .delete(`${url}`, {
        headers: { Authorization: "Bearer " + Cookies.get("auth-token") },
      })
      .then(() => {
        cash("#delete").modal("hide");
        this.setState({
          dokumen: this.state.dokumen.filter((item) => item.dok_id !== this.state.dok_id),
          hapus_notif: "Data berhasil dihapus",
        });
      });
  }

  render() {
    return (
      <div className="col-span-12">
        <h2 className="intro-y text-lg font-medium mt-10">Data Dokumen</h2>
        <div className="grid grid-cols-12 gap-6 mt-5">
          <div className="intro-y col-span-12 flex flex-wrap sm:flex-no-wrap items-center mt-2">
            <Link to="/dokumen/add" className="btn btn-primary shadow-md mr-2">
              Add Dokumen
            </Link>
            <div className="hidden md:block mx-auto text-gray-600">
              Showing {this.state.page * Cookies.get("perpage") - Cookies.get("perpage") + 1} to{" "}
              {this.state.page * Cookies.get("perpage") > this.state.dokumen.length ? this.state.dokumen.length : this.state.page * Cookies.get("perpage")} of{" "}
              {this.state.dokumen.filter((name) => name.dok_nama.toLowerCase().includes(this.state.search)).length} entries
              {this.state.search.length > 0 ? ` (filtered from ${this.state.dokumen.length} total entries)` : ""}
            </div>
            <div className="w-full sm:w-auto mt-3 sm:mt-0 sm:ml-auto md:ml-0">
              <div className="w-56 relative text-gray-700">
                <input type="text" name="search" className="form-control" placeholder="Search..." onChange={(e) => this.onChange(e)} />
                <i className="w-4 h-4 absolute my-auto inset-y-0 mr-3 right-0" data-feather="search"></i>
              </div>
            </div>
          </div>
          <div className={`col-span-12 ${this.props.location.changedRows !== undefined ? "" : "hidden"}`}>
            <div className={`rounded-md flex items-center px-5 py-4 mb-2 ${this.props.location.changedRows == 0 ? "bg-theme-31 text-theme-6" : "bg-theme-14 text-theme-10"}`}>
              <i data-feather="alert-octagon" className="w-6 h-6 mr-2"></i>
              {this.props.location.changedRows == 0 ? "Gagal Update Data!" : "Sukses Update Data!"}
            </div>
          </div>
          <div className="intro-y col-span-12 overflow-auto lg:overflow-visible">
            <table className="table table-report -mt-2">
              <thead>
                <tr>
                  <th>NO</th>
                  <th>KODE</th>
                  <th>NAMA DOKUMEN</th>
                  <th>JENIS PEKERJAAN</th>
                  <th>WAJIB</th>
                  <th>ACTION</th>
                </tr>
              </thead>
              <tbody>
                {this.state.dokumen
                  .filter((ta) => ta.dok_nama.toLowerCase().includes(this.state.search.toLocaleLowerCase()))
                  .slice(this.state.page * Cookies.get("perpage") - Cookies.get("perpage"), this.state.limit)
                  .map((e, index) => {
                    let jenis = this.state.jenis_pekerjaan.find((i) => i.id == e.dok_jenispekerjaan);
                    return (
                      <tr className="intro-x" key={index}>
                        <td className="w-10">{index + 1}</td>
                        <td style={{ width: "10%" }}>{e.dok_kode}</td>
                        <td style={{ width: "25%" }}>{e.dok_nama}</td>
                        <td>{jenis == undefined ? "Semua" : jenis.name}</td>
                        <td>{e.dok_wajib == 1 ? "Wajib" : "Tidak"}</td>
                        <td className="table-report__action w-32">
                          <div className="flex justify-center items-center">
                            <Link className="flex items-center mr-3" to={`/dokumen/edit/${e.dok_id}`}>
                              <i data-feather="check-square" className="w-4 h-4 mr-1"></i>
                              Edit
                            </Link>
                            <a href="#" className="flex items-center text-theme-6" onClick={(o) => this.confirmDelete(o, e, "#delete")}>
                              <i data-feather="trash-2" className="w-4 h-4 mr-1"></i>
                              Delete
                            </a>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
          <div className="intro-y col-span-12 flex flex-wrap sm:flex-row sm:flex-no-wrap items-center">
            <ul className="pagination">{this.paging()}</ul>
            <select className="w-20 form-select box mt-3 sm:mt-0" onChange={(e) => this.onChange(e)} value={Cookies.get("perpage")}>
              <option value="5">5</option>
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
            </select>
          </div>
          <Modal>
            <div className="modal" id="delete">
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="p-5 text-center">
                    <i data-feather="help-circle" className="w-16 h-16 text-theme-6 mx-auto mt-3"></i>
                    <div className="text-3xl mt-5">Are you sure?</div>
                    <div className="text-gray-600 mt-2">Apakah anda yakin untuk menghapus Data ini ?</div>
                  </div>
                  <div className="px-5 pb-8 text-center">
                    <button type="button" data-dismiss="modal" className="btn w-24 btn-secondary mr-1">
                      Cancel
                    </button>
                    <button className="btn w-24 btn-danger" id="ok_hapus" onClick={() => this.delete()}>
                      OK
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
        </div>
      </div>
    );
  }
}

export default Index;
