import React, { Component } from "react";
import feather from "feather-icons";
import moment from "moment";
import axios from "axios";
import Cookies from "js-cookie";
import download from "js-file-download";
import cash from "cash-dom";
import Modal from "../master/Modal";
import Loading from "../master/Loading";

class Files extends Component {
  constructor(props) {
    super(props);
    this.state = {
      files: [],
      dokumen: [],
      loading_download: false,
      tinggi: 707,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    return {
      dokumen: nextProps.dokumen,
    };
  }

  componentDidMount() {
    this.setState({
      dokumen: this.props.dokumen,
    });
    feather.replace({
      "stroke-width": 1.5,
    });
    let kiri = document.querySelector("#kiri");
    let kiri_isi = document.querySelector("#kiri_isi");
    this.setState({ tinggi: kiri.offsetHeight - kiri_isi.offsetHeight - 20 });
  }

  componentDidUpdate(prevProps, prevState) {
    feather.replace({
      "stroke-width": 1.5,
    });
  }

  downloadFile(id, x = undefined) {
    this.setState({ loading_download: true });
    if (x !== undefined) x.preventDefault();
    axios
      .get(`${process.env.REACT_APP_APIURL}dokumen/download_file/${id}`, {
        headers: { Authorization: "Bearer " + Cookies.get("auth-token") },
        responseType: "blob",
      })
      .then((response) => {
        let filename = response.headers["content-disposition"].split("filename=")[1].replaceAll('"', "");
        download(response.data, filename);
        this.setState({ loading_download: false });
        console.log("File downloading successfully!");
      });
  }

  onPrevent(e) {
    e.preventDefault();
  }

  showModal(e, o, element = "") {
    o.preventDefault();
    this.setState({ loading_download: true });
    axios
      .get(`${process.env.REACT_APP_APIURL}dokumen/paket_jenis/${e.dl_paket}/${e.dl_jenis}`, {
        headers: { Authorization: "Bearer " + Cookies.get("auth-token") },
      })
      .then((response) => {
        // console.log(response);
        this.setState({
          files: response.data.dokumen,
          loading_download: false,
        });
        cash(element).modal("show");
      });
  }

  renderModal() {
    return this.state.loading_download ? (
      <Loading />
    ) : (
      <div className="modal-content">
        <div className="flex items-center px-5 py-5 sm:py-3 border-b border-gray-200">
          <h2 className="font-medium text-base mr-auto">File History</h2>
        </div>
        <div className="p-5">
          <table className="table">
            <thead>
              <tr>
                <th className="border-b-2 whitespace-no-wrap">#</th>
                <th className="border-b-2 whitespace-no-wrap">Jenis Dok</th>
                <th className="border-b-2 whitespace-no-wrap">Size</th>
                <th className="border-b-2 whitespace-no-wrap">User</th>
                <th className="border-b-2 whitespace-no-wrap">Tgl Upload</th>
                <th className="border-b-2 whitespace-no-wrap">Action</th>
              </tr>
            </thead>
            <tbody>
              {this.state.files.map((e, idx) => {
                return (
                  <tr key={e.dl_id}>
                    <td className="border-b">{idx + 1}</td>
                    <td className="border-b">{e.dok_nama}</td>
                    <td className="border-b">{e.dl_size} Kb</td>
                    <td className="border-b">{e.dl_user}</td>
                    <td className="border-b">{moment(e.dl_created).format("D MMM Y HH:mm")}</td>
                    <td className="border-b">
                      <button onClick={(o) => this.downloadFile(e.dl_id, o)}>
                        <i data-feather="save" className="w-5 h-5 text-theme-1"></i>
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div className="px-5 py-3 text-right border-gray-200">
          <button type="button" className="btn w-20 btn-primary" data-dismiss="modal">
            OK
          </button>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="tab-pane" id="files" role="tabpanel" aria-labelledby="chats-tab">
        <div className="pr-1">
          <div className="overflow-x-auto mt-5" style={{ height: this.state.tinggi + "px" }}>
            {this.state.dokumen.map((e, index) => {
              return (
                <div className={`flex items-center p-5 box ${index > 0 ? "mt-5" : ""}`} key={index}>
                  <div className="file">
                    <a href="#" onClick={(x) => this.downloadFile(e.dl_id, x)} className="w-12 file__icon file__icon--file">
                      <div className="file__icon__file-name text-xs">{e.dl_type}</div>
                    </a>
                  </div>
                  <div className="ml-4">
                    <a className="font-medium" href="#" onClick={(o) => this.showModal(e, o, "#file_history")}>
                      {e.dok_nama} {e.revisi > 1 ? "(rev " + (e.revisi - 1) + ")" : ""}
                    </a>
                    <div className="text-gray-600 text-xs">
                      {e.dl_size} KB / {moment(e.dl_created).format("D MMM Y HH:mm")}
                    </div>
                  </div>
                  <div className="dropdown ml-auto">
                    <a onClick={(x) => this.onPrevent(x)} className="dropdown-toggle w-5 h-5 block" href="/">
                      <i data-feather="more-horizontal" className="w-5 h-5 text-gray-600 dark:text-gray-300"></i>
                    </a>
                    <div className="dropdown-menu w-40">
                      <div className="dropdown-menu__content box dark:bg-dark-1 p-2">
                        <a
                          href="/"
                          onClick={(x) => this.onPrevent(x)}
                          className="flex items-center block p-2 transition duration-300 ease-in-out bg-white hover:bg-gray-200 rounded-md"
                        >
                          <i data-feather="user" className="w-4 h-4 mr-2"></i> {e.dl_user}
                        </a>
                        <a
                          href="#"
                          onClick={(o) => this.downloadFile(e.dl_id, o)}
                          className="flex items-center block p-2 transition duration-300 ease-in-out bg-white hover:bg-gray-200 rounded-md"
                        >
                          <i data-feather="download" className="w-4 h-4 mr-2"></i> Download
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          <Modal>
            <div className="modal" id="file_history" tabIndex="-1" aria-hidden="true">
              <div className="modal-dialog modal-xl">{this.renderModal()}</div>
            </div>
          </Modal>
        </div>
      </div>
    );
  }
}

export default Files;
