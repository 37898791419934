import React, { Component } from "react";
import Modal from "../master/Modal";
import cash from "cash-dom";

class ModalPersetujuanAuditor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      proses: false,
    };
  }

  setuju() {
    this.props.updateStatus(0);
    cash("#tidak_sepakat").modal("hide");
  }

  render() {
    return (
      <Modal>
        <div className="modal" id="tidak_sepakat">
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="p-5 text-center">
                <i data-feather="help-circle" className="w-16 h-16 text-theme-6 mx-auto mt-3"></i>
                <div className="text-3xl mt-5">Are you sure?</div>
                <div className="text-gray-600 mt-2">
                  Apakah anda tidak sepakat dengan hasil reviu ini?
                  <br />
                  Jika anda tidak sepakat, maka paket ini akan dikembalikan kepada PPK OPD.
                </div>
              </div>
              <div className="px-5 pb-8 text-center">
                <button type="button" data-dismiss="modal" className="btn w-24 btn-secondary mr-1">
                  Tidak
                </button>
                <button className="btn w-24 bg-theme-6 text-white" onClick={() => this.setuju()}>
                  Ya
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

export default ModalPersetujuanAuditor;
