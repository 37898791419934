import React, { Component } from "react";
import Cookies from "js-cookie";
import axios from "axios";
import { Link, withRouter } from "react-router-dom";
import Pristine from "pristinejs";
import Toastify from "toastify-js";
import cash from "cash-dom";
import feather from "feather-icons";
import Failed from "../master/Failed";

class Edit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      val: "",
      desc: "",
      proses: false,
    };
  }

  onChange(e) {
    const name = e.target.name;
    let value = e.target.value;

    this.setState({
      [name]: value,
    });
  }

  componentDidMount() {
    let url = process.env.REACT_APP_APIURL + "setting/detail/" + this.props.match.params.id;

    axios
      .get(`${url}`, {
        headers: { Authorization: "Bearer " + Cookies.get("auth-token") },
      })
      .then((response) => {
        this.setState({
          id: response.data.setting.setting_name,
          val: response.data.setting.setting_value,
          desc: response.data.setting.setting_desc,
        });
      });
  }

  onSubmit() {
    let pris;
    cash(".validate-form").each(function () {
      pris = new Pristine(this, {
        classTo: "input-form",
        errorClass: "has-error",
        errorTextParent: "input-form",
        errorTextClass: "text-primary-3 mt-2",
      });
    });
    if (pris.validate()) {
      this.setState({ proses: true });
      let formData = {
        id: this.state.id,
        val: this.state.val,
      };
      let url = process.env.REACT_APP_APIURL + "setting/update";

      axios
        .put(`${url}`, formData, {
          headers: { Authorization: "Bearer " + Cookies.get("auth-token") },
        })
        .then((response) => {
          this.setState({ proses: false });
          this.props.history.push({
            pathname: "/setting/system",
            changedRows: response.data.setting.changedRows,
          });
        });
    } else {
      Toastify({
        node: cash("#failed-notification-content").clone().removeClass("hidden")[0],
        duration: 3000,
        newWindow: true,
        close: true,
        gravity: "top",
        position: "right",
        stopOnFocus: true,
      }).showToast();
      feather.replace({
        "stroke-width": 1.5,
      });
    }
  }

  render() {
    return (
      <div>
        <div className="intro-y flex flex-col sm:flex-row items-center mt-8">
          <h2 className="text-lg font-medium mr-auto">Edit Setting</h2>
        </div>
        <div className="grid grid-cols-12 gap-6 mt-5">
          <div className="intro-y col-span-12 lg:col-span-6">
            <form method="post" name="dasar" className="validate-form">
              <div className="intro-y box">
                <div className="p-5">
                  <div className="">
                    <div>
                      <label>Setting Name</label>
                      <input
                        type="text"
                        className="form-control mt-2"
                        placeholder="Setting Name"
                        name="id"
                        value={this.state.id}
                        onChange={(e) => this.onChange(e)}
                        required
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="mt-3">
                    <div>
                      <label>Value</label>
                      <input
                        type="text"
                        className="form-control mt-2"
                        placeholder="Isi Setting"
                        name="val"
                        value={this.state.val}
                        onChange={(e) => this.onChange(e)}
                        required
                      />
                    </div>
                  </div>
                  <div className="mt-3">
                    <div>
                      <label>Deskripsi</label>
                      <input
                        type="text"
                        className="form-control mt-2"
                        placeholder="Deskripsi"
                        name="desc"
                        value={this.state.desc}
                        onChange={(e) => this.onChange(e)}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="text-right mt-5">
                    <Link to="/setting/system" className="btn w-24 border text-gray-700 mr-1">
                      Cancel
                    </Link>
                    <button
                      type="button"
                      className={`btn w-24 ${!this.state.proses ? "btn-primary" : "bg-gray-200 text-gray-600"}`}
                      onClick={() => this.onSubmit()}
                      disabled={this.state.proses}
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        <Failed />
      </div>
    );
  }
}

export default withRouter(Edit);
