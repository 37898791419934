import React, { Component } from "react";
import NumberFormat from "react-number-format";
import feather from "feather-icons";
import Cookies from "js-cookie";
import axios from "axios";
import { withRouter } from "react-router-dom";
import ModalPemenang from "./_modalPemenang";
import ModalGagal from "./_modalGagal";
import download from "js-file-download";
import cash from "cash-dom";
import "@left4code/tw-starter/dist/js/modal";

class Penawaran extends Component {
  constructor(props) {
    super(props);
    this.state = {
      calon_pemenang: {},
    };
  }

  componentDidMount() {
    feather.replace({
      "stroke-width": 1.5,
    });
  }

  componentDidUpdate(prevProps) {
    feather.replace({
      "stroke-width": 1.5,
    });
  }

  downloadDokPenawaran(o, e) {
    o.preventDefault();
    axios
      .get(`${process.env.REACT_APP_APIURL}evaluasi/downloadpenawaran/${e.psr_id}/${Cookies.get("username")}`, {
        headers: { Authorization: "Bearer " + Cookies.get("auth-token") },
        responseType: "blob",
      })
      .then((response) => {
        let filename = response.headers["content-disposition"].split("filename=")[1].replaceAll('"', "");
        download(response.data, filename);
      });
  }

  showModal(o, element, e = undefined) {
    if (typeof o !== "string") o.preventDefault();
    if (element === "#modal_pemenang") {
      axios
        .get(`${process.env.REACT_APP_APIURL}evaluasi/calon_pemenang/${e.lls_id}/${e.psr_id}/${this.props.paket.paket_metode}`, {
          headers: { Authorization: "Bearer " + Cookies.get("auth-token") },
        })
        .then((response) => {
          this.setState({
            calon_pemenang: response.data.calon_pemenang,
          });
          cash(element).modal("show");
        });
    } else if (element === "#modal_gagal") {
      cash(element).modal("show");
    }
  }

  tenderGagal = (e) => {
    let status = 12;
    let url = process.env.REACT_APP_APIURL + "history/add";
    let formData = {
      paket_id: this.props.paket.paket_id,
      status: status,
      user: Cookies.get("username"),
      panitia: Cookies.get("level"),
    };

    axios
      .post(`${url}`, formData, {
        headers: { Authorization: "Bearer " + Cookies.get("auth-token") },
      })
      .then((response) => {
        cash("#modal_gagal").modal("hide");
        this.props.history.push("/paket/detail/" + this.props.paket.paket_id);
      });
  };

  oke = (stat) => {
    let status = 14;
    let url = process.env.REACT_APP_APIURL + "history/add";
    let formData = {
      paket_id: this.props.paket.paket_id,
      status: status,
      user: Cookies.get("username"),
      panitia: Cookies.get("level"),
    };

    this.setState({ calon_pemenang: {} });

    axios
      .post(`${url}`, formData, {
        headers: { Authorization: "Bearer " + Cookies.get("auth-token") },
      })
      .then((response) => {
        cash("#modal_pemenang").modal("hide");
        this.props.history.push("/paket/detail/" + this.props.paket.paket_id);
      });
  };

  render() {
    let persen = 0;
    return (
      <div className="intro-y box col-span-12 lg:col-span-6">
        <div className="flex items-center p-5 border-b border-gray-200">
          <h2 className="font-medium text-base mr-auto">Penawaran</h2>
          <div className="dropdown ml-auto sm:hidden">
            <a className="dropdown-toggle w-5 h-5 block" href="javascript:;" aria-expanded="false">
              <i data-feather="more-horizontal" className="w-5 h-5 text-gray-700"></i>
            </a>
            <div className="dropdown-menu w-40">
              <div className="dropdown-menu__content box dark:bg-dark-1 p-2">
                <a
                  href="javascript:;"
                  className="flex items-center p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                  onClick={(o) => this.showModal(o, "#modal_gagal")}
                >
                  <i data-feather="x" className="w-4 h-4 mr-2"></i> Tender Gagal
                </a>
              </div>
            </div>
          </div>
          <button className="btn btn-danger hidden sm:flex" onClick={(o) => this.showModal(o, "#modal_gagal")}>
            <i data-feather="x" className="w-4 h-4 mr-2"></i> Tender Gagal
          </button>
        </div>
        <div className="p-5">
          {this.props.penawaran.map((e, idx) => {
            persen = (e.psr_harga / this.props.paket.paket_hps) * 100;
            return (
              <div className={`flex flex-col sm:flex-row ${idx == 0 ? "" : "mt-5"}`} key={idx}>
                <div className="mr-auto">
                  <div className="flex">
                    <a href="#" className="font-medium" onClick={(o) => this.showModal(o, "#modal_pemenang", e)}>
                      {e.rkn_nama}
                    </a>
                    {e.is_pemenang == 1 || e.is_pemenang_verif == 1 ? <i data-feather="star" className="w-4 h-4 ml-2 text-theme-12"></i> : ""}
                    {this.props.dokpenawaran == "boleh" ? (
                      <a href="#" className="font-medium" onClick={(o) => this.downloadDokPenawaran(o, e)}>
                        <i data-feather="download" className="w-4 h-4 ml-2 text-theme-1"></i>
                      </a>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="text-gray-600 mt-1">NPWP: {e.rkn_npwp}</div>
                </div>
                <div className="flex">
                  <div className="text-center">
                    <div className="font-medium">
                      <NumberFormat value={e.psr_harga} displayType={"text"} thousandSeparator={true} decimalScale={0} prefix={"Rp "} />
                    </div>
                    <div className={`${persen > 80 ? "bg-theme-18 text-theme-9" : "bg-theme-6 text-white"} rounded px-2 mt-1`}>
                      <NumberFormat value={persen} displayType={"text"} thousandSeparator={true} decimalScale={2} suffix={" %"} />
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <ModalPemenang calon_pemenang={this.state.calon_pemenang} paket={this.props.paket} oke={this.oke} />
        <ModalGagal paket={this.props.paket} lls_id={this.props.lls_id} tenderGagal={this.tenderGagal} />
      </div>
    );
  }
}

export default withRouter(Penawaran);
