import React, { Component } from "react";
import Modal from "../master/Modal";

class ModalPokja extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Modal>
        <div className="modal" id="modal_pokja">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="flex items-center px-5 py-5 sm:py-3 border-b border-gray-200">
                <h2 className="font-medium text-base mr-auto">Data {this.props.paket.paket_metode == 8 ? "Pejabat Pengadaan" : "Pokja"}</h2>
              </div>
              <div className="p-5">
                <table className="table">
                  <thead>
                    <tr className="bg-gray-700 text-white">
                      <th className="whitespace-no-wrap">#</th>
                      <th className="whitespace-no-wrap">Nama</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.props.panitia.map((e, idx) => {
                      return (
                        <tr key={idx}>
                          <td className="border-b">{idx + 1}</td>
                          <td className="border-b">{e.user_nama}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
              <div className="px-5 py-3 text-right border-gray-200">
                <button type="button" className="btn btn-primary w-20 mr-2" data-dismiss="modal">
                  OK
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

export default ModalPokja;
