import React, { Component } from "react";
import { Link } from "react-router-dom";

class Step3 extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  render() {
    return (
      <div className="px-5 sm:px-20 mt-10 pt-10 border-t border-gray-200">
        <h3 className="text-3xl text-center items-center">Selesai</h3>
        <div className="intro-y col-span-12 flex items-center justify-center sm:justify-end mt-5">
          <Link to="/paket" className="btn btn-primary w-24 justify-center ml-2">
            Selesai
          </Link>
        </div>
      </div>
    );
  }
}

export default Step3;
